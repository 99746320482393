import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LatLngExpression, PointTuple } from 'leaflet';
import {
  addErrorMessage,
  addSuccessMessage,
} from '../../../app/errors/errorSlice';
import {
  resetCounterHistoryItem,
  resetHistorySelectedItem,
  revertHistorySelectedItem,
  setCounterHistoryItem,
  setHistorySelectedItem,
} from '../../../app/historyItemSlice';
import { AppThunk, RootState } from '../../../app/store';
import i18n from '../../../config/i18n';
import { proportionalResize } from '../../../utils/imgUtils';
import { copyObject } from '../../../utils/objUtils';
import { convertListPinPositionFromArrayToObj } from '../common/utils/convertPosition';
import { ICounter } from '../data/itemHistory';
import { deleteFile, getFiles } from '../services/upload.service';
import { listTabNamePin } from './adminPinSidebar/PinData';
import { initialPositionMap, initialZoomLevelMap } from './map/mapData';
import { ICenterMapCoords, IMapBoundaryCoords } from './models/Map';
import { IPinMap } from './models/Pin';
import { listPinAvailableMap } from './pinData';
import {
  createBookmarkList,
  deleteBookmarkList,
  deletePinInMap,
  editPinInMap,
  getBookmarkList,
  getPinInArea,
  modifyBookmarkList,
  savePinInMap,
} from './services/map.service';

export interface MapSliceModel {
  listPinMap: IPinMap[];
  listPinAvailableMap: IPinMap[];
  selectedPinMap?: IPinMap;
  centerMapCoords: ICenterMapCoords;
  zoomLevel: number;
  mapBoundaryCoords?: IMapBoundaryCoords;
  bookmarkListMap: IPinMap[];
  isContextmenu: boolean;
  isSumbitForm: boolean;
  isEditImgPin: boolean;
  trackPoints: LatLngExpression[][];
  polylinePoint: any;
  /* polylinePointList: LatLngExpression[][]; */
  isGetPointOnMapMode: boolean;
  tabKeyPin: string;
}
const initialState: MapSliceModel = {
  listPinMap: [],
  listPinAvailableMap: listPinAvailableMap, //TODO sostituire listPinAvailableMap con [] (deve arrivare dal be)
  selectedPinMap: undefined,
  centerMapCoords: initialPositionMap,
  zoomLevel: initialZoomLevelMap,
  mapBoundaryCoords: undefined,
  bookmarkListMap: [],
  isContextmenu: false,
  isSumbitForm: false,
  isEditImgPin: false,
  trackPoints: [[], []],
  polylinePoint: undefined,
  /*polylinePointList: [],*/
  isGetPointOnMapMode: false,
  tabKeyPin: listTabNamePin.pin,
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setListPinMap: (state, action: PayloadAction<IPinMap[]>) => {
      state.listPinMap = action.payload;
    },
    setKeyTabPin: (state, action: PayloadAction<string>) => {
      state.tabKeyPin = action.payload;
    },
    // editListPinMap: (state, action: PayloadAction<IPinMap>) => {
    //   state.listPinMap = state.listPinMap.map((el) =>
    //     el._id === action.payload._id ? { ...el, ...action.payload } : el
    //   );
    // },
    editListPinMap: (state, action: PayloadAction<IPinMap>) => {
      state.listPinMap = [
        ...state.listPinMap.filter(pin => pin._id !== action.payload._id),
        action.payload,
      ];
    },
    toggleBookmarksPinMap: (state, action: PayloadAction<string>) => {
      /* state.bookmarkListMap =
				state.bookmarkListMap.filter((el) => el === action.payload).length === 0
					? (state.bookmarkListMap = [...state.bookmarkListMap, action.payload])
					: state.bookmarkListMap.filter((el) => el !== action.payload); */
    },
    setBookmarksPinMap: (state, action: PayloadAction<IPinMap[]>) => {
      state.bookmarkListMap = action.payload;
    },
    removePinInListMap: (state, action: PayloadAction<IPinMap>) => {
      state.listPinMap = state.listPinMap.filter(
        pin => pin._id !== action.payload._id
      );
    },
    setListPinAvailableMap: (state, action: PayloadAction<IPinMap[]>) => {
      state.listPinAvailableMap = action.payload;
    },
    addAvailablePinListMap: (state, action: PayloadAction<IPinMap>) => {
      state.listPinAvailableMap = [
        ...state.listPinAvailableMap.filter(
          ele => ele.image.iconUrl !== action.payload.image.iconUrl
        ),
        action.payload,
      ];
    },
    removePinInListAvailableMap: (state, action: PayloadAction<IPinMap>) => {
      state.listPinAvailableMap = state.listPinAvailableMap.filter(
        pin => pin.image.iconUrl !== action.payload.image.iconUrl
      );
    },
    setSelectedPinMapById: (state, action: PayloadAction<string>) => {
      state.selectedPinMap = state.listPinMap.find(
        ele => ele._id === action.payload
      );
    },
    editSelectedPinMap: (state, action: PayloadAction<IPinMap>) => {
      state.selectedPinMap = action.payload;
    },
    resetSelectedPinMap: state => {
      state.selectedPinMap = undefined;
    },
    setCenterMapCoords: (state, action: PayloadAction<ICenterMapCoords>) => {
      state.centerMapCoords = action.payload;
    },
    setZoomLevel: (state, action: PayloadAction<number>) => {
      state.zoomLevel = action.payload;
    },
    setMapBoundaryCoords: (
      state,
      action: PayloadAction<IMapBoundaryCoords>
    ) => {
      state.mapBoundaryCoords = action.payload;
    },
    setIsContextmenu: (state, action: PayloadAction<boolean>) => {
      state.isContextmenu = action.payload;
    },
    setIsSumbitForm: (state, action: PayloadAction<boolean>) => {
      state.isSumbitForm = action.payload;
    },
    setIsEditImgPin: (state, action: PayloadAction<boolean>) => {
      state.isEditImgPin = action.payload;
    },
    setTrackPoints: (state, action: PayloadAction<LatLngExpression[][]>) => {
      state.trackPoints = action.payload;
    },
    setPolylinePoint: (state, action: PayloadAction<any>) => {
      state.polylinePoint = action.payload;
    },
    resetPolylinePoint: state => {
      state.polylinePoint = undefined;
    },
    setIsGetPointOnMapMode: (state, action: PayloadAction<boolean>) => {
      state.isGetPointOnMapMode = action.payload;
    },
  },
});
export const {
  setListPinMap,
  setListPinAvailableMap,
  addAvailablePinListMap,
  removePinInListAvailableMap,
  removePinInListMap,
  setSelectedPinMapById,
  editSelectedPinMap,
  resetSelectedPinMap,
  editListPinMap,
  setCenterMapCoords,
  setZoomLevel,
  setMapBoundaryCoords,
  toggleBookmarksPinMap,
  setIsContextmenu,
  setIsSumbitForm,
  setIsEditImgPin,
  setBookmarksPinMap,
  setTrackPoints,
  setPolylinePoint,
  resetPolylinePoint,
  setIsGetPointOnMapMode,
  setKeyTabPin,
} = mapSlice.actions;

export const setPositionUserOnMap = (): AppThunk => (dispatch, getState) => {
  const position = getState().positionUser.positionUser;
  //const actualPositionCenter = getState().map.centerMapCoords;
  //const actualZoom = getState().map.zoomLevel;
  if (position && position.x && position.y && position.z) {
    dispatch(
      setCenterMapCoords({
        lat: position.x,
        lng: position.y,
      })
    );
    dispatch(setZoomLevel(position.z));
  } /* else
		dispatch(
			createPositionUser({
				position: {
					name: "map",
					x: actualPositionCenter.lat,
					y: actualPositionCenter.lng,
					z: actualZoom,
				},
			})
		); */
};

export const getListPinInBoundaryCoords =
  (coords: IMapBoundaryCoords): AppThunk =>
  dispatch => {
    getPinInArea(coords).then(
      data => {
        const dataEdit: IPinMap[] = data.map(ele => ({
          ...ele,
          position: { lat: ele.position[0], lng: ele.position[1] },
        }));
        dispatch(setListPinMap(dataEdit));
      },
      err =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.LOADINGPINSMAP'),
          })
        )
    );
  };

export const deletePinAvailable =
  (pin: IPinMap): AppThunk =>
  dispatch => {
    const documentName = 'pins/' + pin.image.iconUrl.split('/').pop();
    documentName &&
      deleteFile(documentName).then(
        data => {
          dispatch(removePinInListAvailableMap(pin));
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETEPINAVAILABLE'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETEPINAVAILABLE'),
            })
          )
      );
  };

export const getListPinAvailable = (): AppThunk => dispatch => {
  getFiles({ pathName: 'pins' }).then(
    data => {
      // eslint-disable-next-line array-callback-return
      data.map(pin => {
        const img = {
          _id: '',
          image: {
            iconUrl: pin.url || '',
            iconSize: [50, 50],
          },
          type: pin.mimetype,
          name: pin.originalfilename,
          position: {
            lat: 45.3568045656609,
            lng: 10.954052540764678,
          },
          action: '',
          maxWidth: 200,
          maxHeight: 200,
        } as IPinMap;
        dispatch(addAvailablePinListMap(img));
      });
    },
    err =>
      dispatch(
        addErrorMessage({
          title: i18n.t('ERRORSMSG.LOADINGPINSSAVAILABLE'),
        })
      )
  );
};

export const getPinBookmarkMap = (): AppThunk => (dispatch, getState) => {
  getBookmarkList().then(
    res => {
      if (res.bookmarksUser && res.bookmarksUser.length) {
        dispatch(
          setBookmarksPinMap([
            ...convertListPinPositionFromArrayToObj(res.bookmarksGlobal),
            ...convertListPinPositionFromArrayToObj(res.bookmarksUser),
          ])
        );
      }
    },
    err => {
      createBookmarkList([]).then(res => {
        dispatch(
          setBookmarksPinMap(convertListPinPositionFromArrayToObj(res.pins))
        );
        dispatch(
          addSuccessMessage({
            title: i18n.t('Pin aggiunto alla lista dei preferiti'),
          })
        );
      });
    }
  );
};

export const addPinToBookmarkMap =
  (pinToBook: IPinMap): AppThunk =>
  (dispatch, getState) => {
    const bookListId = getState().map.bookmarkListMap.map(book => book._id);
    if (bookListId) {
      const checkBookToogle =
        bookListId.filter(ele => ele === pinToBook._id).length === 0
          ? [...bookListId, pinToBook._id]
          : bookListId.filter(ele => ele !== pinToBook._id);

      if (checkBookToogle.length > 0) {
        modifyBookmarkList(checkBookToogle).then(res => {
          dispatch(
            setBookmarksPinMap(convertListPinPositionFromArrayToObj(res.pins))
          );

          dispatch(
            addSuccessMessage({
              title: i18n.t('Lista preferiti modificata con successo'),
            })
          );
        });
      } else {
        deleteBookmarkList().then(res => {
          dispatch(setBookmarksPinMap([]));
          dispatch(
            addSuccessMessage({
              title: i18n.t('Lista preferiti vuota'),
            })
          );
        });
      }
    }
  };

export const getHistoryPinMap =
  (azione: ICounter): AppThunk =>
  (dispatch, getState) => {
    const historyPin = getState().historyItem.historySelectedItem as IPinMap[];
    const counter = getState().historyItem.counterHistoryItem;
    dispatch(setCounterHistoryItem(azione));
    dispatch(
      editSelectedPinMap(
        historyPin[azione === 'next' ? counter + 1 : counter - 1]
      )
    );
  };

export const revertHistoryFirstSelectedPinMap =
  (): AppThunk => (dispatch, getState) => {
    dispatch(revertHistorySelectedItem(1));
    dispatch(resetCounterHistoryItem(1));
    dispatch(
      editSelectedPinMap(
        getState().historyItem.historySelectedItem[0] as IPinMap
      )
    );
  };

export const editMapSelectedPin =
  (pin: IPinMap): AppThunk =>
  (dispatch, getState) => {
    const historyPin = getState().historyItem.historySelectedItem;
    const counter = getState().historyItem.counterHistoryItem;
    if (historyPin.length - 1 > counter) {
      dispatch(revertHistorySelectedItem(counter + 1));
    }
    dispatch(editSelectedPinMap(pin));
    dispatch(changeHistoryAndCounterMap(pin, ICounter.NEXT));
  };

export const changeHistoryAndCounterMap =
  (pin?: IPinMap, azione?: ICounter): AppThunk =>
  dispatch => {
    pin
      ? dispatch(setHistorySelectedItem(pin))
      : dispatch(resetHistorySelectedItem());
    azione
      ? dispatch(setCounterHistoryItem(azione))
      : dispatch(resetCounterHistoryItem());
  };

export const changeSelectedPinMap =
  (id: string): AppThunk =>
  (dispatch, getState) => {
    dispatch(setSelectedPinMapById(id));
    const selectedPin = getState().map.selectedPinMap;
    const history = getState().historyItem.historySelectedItem as IPinMap[];
    if (selectedPin) {
      history.length &&
        selectedPin._id !== history[0]._id &&
        dispatch(changeHistoryAndCounterMap());
      dispatch(changeHistoryAndCounterMap(selectedPin, ICounter.NEXT));
      dispatch(setIsEditImgPin(true));
    }
  };

export const addPinInMap =
  (pin: IPinMap): AppThunk =>
  dispatch => {
    const pinConId = { ...pin, id_temp: (Math.random() * 10).toString() };

    dispatch(editMapSelectedPin(pinConId));
    dispatch(setIsContextmenu(false));
  };

export const savePinMap =
  (pin: IPinMap): AppThunk =>
  (dispatch, getState) => {
    const list = getState().map.listPinMap;
    const copyPin = copyObject<IPinMap>(pin);
    const positionConverted = [copyPin.position.lat, copyPin.position.lng];
    const polyline =
      copyPin.polyline && copyPin.polyline?.length === 0
        ? copyPin?.coords
        : copyPin?.polyline;

    if (copyPin.id_temp) {
      delete copyPin.id_temp;
      savePinInMap({
        ...copyPin,
        position: positionConverted,
        polyline: polyline,
      }).then(
        res => {
          copyPin._id = res._id;
          dispatch(resetSelectedPinMap());
          dispatch(changeHistoryAndCounterMap());
          dispatch(addAvailablePinListMap(copyPin));
          dispatch(setListPinMap([...list, copyPin]));
          dispatch(setIsEditImgPin(false));
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.SAVEPIN'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.SAVEPIN'),
            })
          )
      );
      return;
    }
    editPinInMap({
      ...copyPin,
      position: positionConverted,
      polyline: polyline,
    }).then(
      data => {
        dispatch(resetSelectedPinMap());
        dispatch(editListPinMap(copyPin));
        dispatch(changeHistoryAndCounterMap());
        dispatch(setIsEditImgPin(false));
        dispatch(
          addSuccessMessage({
            title: i18n.t('SUCCESSMSG.EDITPIN'),
          })
        );
      },
      err =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.EDITPIN'),
          })
        )
    );
  };

export const deletePinMap =
  (pin: IPinMap): AppThunk =>
  dispatch => {
    const cleanSelected = () => {
      dispatch(changeHistoryAndCounterMap());
      dispatch(resetSelectedPinMap());
      dispatch(setIsEditImgPin(false));
    };
    pin._id
      ? deletePinInMap(pin).then(
          res => {
            dispatch(removePinInListMap(pin));
            cleanSelected();
            dispatch(
              addSuccessMessage({
                title: i18n.t('SUCCESSMSG.DELETEPIN'),
              })
            );
          },
          err =>
            dispatch(
              addErrorMessage({
                title: i18n.t('ERRORSMSG.DELETEPIN'),
              })
            )
        )
      : cleanSelected();
  };

export const searchMapPinById =
  (id: string): AppThunk =>
  (dispatch, getState) => {
    const pinFound = getState().map.bookmarkListMap.find(ele => ele._id === id);
    if (pinFound) {
      dispatch(setCenterMapCoords(pinFound?.position));
      dispatch(setZoomLevel(initialZoomLevelMap));
    }
  };

export const resetPositionAndZoomMap = (): AppThunk => (dispatch, getState) => {
  dispatch(setCenterMapCoords(initialPositionMap));
  dispatch(setZoomLevel(initialZoomLevelMap));
};
export const createTrackPoints = (): AppThunk => (dispatch, getState) => {
  const trackPoints = getState()
    .map.listPinMap.filter(ele => ele.polyline?.length)
    .map(ele => ele.polyline);

  dispatch(setTrackPoints(trackPoints as LatLngExpression[][]));
};

export const selectListPinMap = (state: RootState) => state.map.listPinMap;

export const selectListPinAvailableMap = (state: RootState) =>
  state.map.listPinAvailableMap;
export const selectSelectedPinMap = (state: RootState) =>
  state.map.selectedPinMap;

export const selectCenterMapCoords = (state: RootState) =>
  state.map.centerMapCoords;
export const selectZoomLevel = (state: RootState) => state.map.zoomLevel;

export const selectMapBoundaryCoords = (state: RootState) =>
  state.map.mapBoundaryCoords;

export const selectBookmarkListMap = (state: RootState) =>
  state.map.bookmarkListMap;
export const selectIsContextmenu = (state: RootState) =>
  state.map.isContextmenu;
export const selectIsSumbitForm = (state: RootState) => state.map.isSumbitForm;

export const selectIsEditImgPin = (state: RootState) => state.map.isEditImgPin;
export const selectTrackPoints = (state: RootState) => state.map.trackPoints;

export const selectPolylinePoint = (state: RootState) =>
  state.map.polylinePoint;
/* export const selectPolylinePointList = (state: RootState) =>
	state.map.polylinePointList; */
export const selectIsGetPointOnMapMode = (state: RootState) =>
  state.map.isGetPointOnMapMode;
export const selectKeyTabPin = (state: RootState) => state.map.tabKeyPin;

export const selectListPinMapNew = createSelector(
  selectListPinMap,
  selectSelectedPinMap,
  (listPin, selectedPin) => {
    let newListPin: IPinMap[] = [];
    if (selectedPin && selectedPin.id_temp) {
      newListPin = [...listPin, selectedPin];
      return newListPin;
    }
    return listPin.map((pin: IPinMap) =>
      pin._id === selectedPin?._id ? { ...pin, ...selectedPin } : pin
    );
  }
);
export const selectListPinMapResize = createSelector(
  selectListPinMapNew,
  selectZoomLevel,
  (listPin, zoom) => {
    return listPin.map((pin: IPinMap) => {
      const newWidth = (pin.image.iconSize[0] * zoom) / 18;
      const proportion = pin.image.iconSize[1] / pin.image.iconSize[0];
      const newHeight = proportionalResize(newWidth, proportion);
      const newIconSize: PointTuple = [newWidth, newHeight];
      const newimage = { ...pin.image, iconSize: newIconSize };
      return { ...pin, image: newimage };
    });
  }
);
export const selectListpolylinePoint = createSelector(
  selectPolylinePoint,
  selectSelectedPinMap,
  (polylinePoint, pin) => {
    if (polylinePoint && pin) {
      return {
        ...pin,
        polyline: pin &&
          pin.polyline && [
            ...pin.polyline.map(ele => ele),
            [polylinePoint.lng, polylinePoint.lat],
          ],
      };
    }
  }
);

export default mapSlice.reducer;
