import React from 'react';
import Sidebar from '../../components/shared/custom/sidebar/Sidebar';
import ToolbarLeft from '../../components/shared/custom/sidebar/toolbarLeft/ToolbarRight';
import ToolbarRight from '../../components/shared/custom/sidebar/toolbarRight/ToolbarRight';
import ToolbarTop from '../../components/shared/custom/sidebar/toolbarTop/ToolbarTop';
export interface IContentPageLayout {
  contentLayout?: React.ReactNode;
  toolbarTopTemplates?: {
    left?: React.ReactNode;
    center?: React.ReactNode;
    right?: React.ReactNode;
  };
  toolbarRightTemplates?: {
    top?: React.ReactNode;
    center?: React.ReactNode;
    bottom?: React.ReactNode;
  };
  toolbarLeftTemplates?: {
    top?: React.ReactNode;
    center?: React.ReactNode;
    bottom?: React.ReactNode;
  };
  sidebarContentTemplate?: React.ReactNode;
  isEditMode: boolean;
}

const ContentPageLayout = (props: IContentPageLayout) => {
  const {
    contentLayout,
    toolbarTopTemplates,
    sidebarContentTemplate,
    isEditMode,
    toolbarRightTemplates,
    toolbarLeftTemplates,
  } = props;

  return (
    <div className="content-page">
      {toolbarTopTemplates && (
        <ToolbarTop
          isOpen={isEditMode}
          leftTemplate={toolbarTopTemplates.left}
          centerTemplate={toolbarTopTemplates.center}
          rightTemplate={toolbarTopTemplates.right}
        ></ToolbarTop>
      )}

      {contentLayout && (
        <div className={`center-page ${isEditMode ? 'isOpen' : ''}`}>
          {contentLayout}
        </div>
      )}
      {toolbarRightTemplates && (
        <ToolbarRight
          isOpen={isEditMode}
          topTemplate={toolbarRightTemplates.top}
          centerTemplate={toolbarRightTemplates.center}
          bottomTemplate={toolbarRightTemplates.bottom}
        ></ToolbarRight>
      )}
      {toolbarLeftTemplates && (
        <ToolbarLeft
          isOpen={isEditMode}
          topTemplate={toolbarLeftTemplates.top}
          centerTemplate={toolbarLeftTemplates.center}
          bottomTemplate={toolbarLeftTemplates.bottom}
        ></ToolbarLeft>
      )}
      {sidebarContentTemplate && (
        <Sidebar isOpen={isEditMode}>{sidebarContentTemplate}</Sidebar>
      )}
    </div>
  );
};

export default ContentPageLayout;
