import http from '../../../utils/http';
import { IPositionCoordsBe } from '../models/Position';

const BASE_PATH = `/coordinate`;

export const getPositionCoords = (catId: string): Promise<IPositionCoordsBe> =>
  http.get(`${BASE_PATH}/${catId}`);

export const addPositionCoords = (position: any): Promise<IPositionCoordsBe> =>
  http.put(`${BASE_PATH}`, { position });

export const patchPositionCoords = (
  position: any
): Promise<IPositionCoordsBe> => http.patch(`${BASE_PATH}`, { position });

export const createPositionCoords = (
  position: any
): Promise<IPositionCoordsBe> => http.post(`${BASE_PATH}`, { position });
