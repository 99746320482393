import {
  isNumber,
  required,
} from '../../../../utils/forms/utils/validationType';

export const validations = (data: any) => {
  /* function checkIdQuestion(): boolean {
    let isRequired = false;
    data?.conditions?.map((condition: any) => {
      if (condition.idQuestion) {
        isRequired = true;
      }
    });
    return isRequired;
  } */
  return [
    {
      name: 'categoryId',
      validations: [
        {
          name: required,
          params: { name: 'categoryId' },
        },
      ],
    },
    {
      name: 'goTo',
      validations: [
        {
          name: required,
          params: { name: 'goTo' },
        },
      ],
    },
    {
      name: 'progressive',
      validations: [
        {
          name: required,
          params: { name: 'progressive' },
        },
      ],
    },
    {
      name: 'goToCategoryId',
      validations: [
        {
          name: required,
          params: { name: 'goToCategoryId' },
        },
      ],
    },
    {
      trigger: {
        form: [
          {
            name: 'name',
            validations: [
              {
                name: required,
                params: { name: 'name' },
              },
            ],
          },
          {
            name: 'timeToRepeat',
            validations:
              typeof data?.trigger === 'object' &&
              (data?.trigger[0]?.repeat ||
                data?.trigger[0]?.name === 'afterMilliseconds')
                ? [
                    {
                      name: required,
                      params: { name: 'timeToRepeat' },
                    },
                    {
                      name: isNumber,
                      params: { name: 'timeToRepeat' },
                    },
                  ]
                : [],
          },

          {
            name: 'eventName',
            validations:
              typeof data?.trigger === 'object' &&
              data?.trigger[0]?.name === 'onEvent'
                ? [
                    {
                      name: required,
                      params: { name: 'eventName' },
                    },
                  ]
                : [],
          },
          {
            name: 'eventListenName',
            validations:
              typeof data?.trigger === 'object' &&
              data?.trigger[0]?.name === 'onEvent'
                ? [
                    {
                      name: required,
                      params: { name: 'eventListenName' },
                    },
                  ]
                : [],
          },
        ],
      },
    },
    {
      conditions: {
        form: [
          {
            name: 'categoryId',
            validations: [
              {
                name: required,
                params: { name: 'categoryId' },
              },
            ],
          },
          /* {
            name: 'answers',
            validations: checkIdQuestion()
              ? [
                  {
                    name: required,
                    params: { name: 'answers' },
                  },
                ]
              : [],
          }, */
          {
            skills: {
              form: [
                {
                  name: 'name',
                  validations: [
                    {
                      name: required,
                      params: { name: 'name' },
                    },
                  ],
                },
                {
                  name: 'pointFrom',
                  validations: [
                    {
                      name: required,
                      params: { name: 'pointFrom' },
                    },
                  ],
                },
                {
                  name: 'pointTo',
                  validations: [
                    {
                      name: required,
                      params: { name: 'pointTo' },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ];
};
