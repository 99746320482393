import React, { useState } from 'react';
import ContentBox from '../contentBox/ContentBox';

import { mergeClass } from '../functions/function';

interface AccordionProps {
  title: string;
  nElement?: number;
  show?: boolean;
  children?: React.ReactNode;
  className?: string;
  clickHandler?: () => void;
  labelBold?: string;
}

const Accordion = (props: AccordionProps) => {
  let [show, setShow] = useState<boolean>(props.show ? props.show : false);

  const isShowContent = () => {
    props.clickHandler && props.clickHandler();
    setShow(!show);
  };

  return (
    <>
      <ContentBox
        className={mergeClass('box', props.className)}
        bgColor="light"
      >
        <div className={mergeClass('shared-accordion', props.className)}>
          <button
            type="button"
            className={mergeClass('btn title', show ? 'show' : '')}
            data-toggle="collapse"
            data-target={'#collapse' + props.title}
            aria-controls={'#collapse' + props.title}
            onClick={() => isShowContent()}
          >
            <div className={props.labelBold ? props.labelBold : 'label-title'}>
              <span title={props.title}>{props.title}</span>
            </div>
            <div className="number">
              <span>{props.nElement}</span>
            </div>
          </button>
          <div
            id={'#collapse' + props.nElement}
            className={mergeClass('collapse', show ? 'show' : '')}
            aria-labelledby="headingOne"
            data-parent="#accordion"
          >
            {props.children}
          </div>
        </div>
      </ContentBox>
    </>
  );
};
export default Accordion;
