import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addErrorMessage,
  addSuccessMessage,
} from '../../../../../app/errors/errorSlice';
import { AppThunk, RootState } from '../../../../../app/store';
import i18n from '../../../../../config/i18n';
import { IMessageContent } from './model/config';
import {
  getMessages,
  updateStatusMessage,
} from './services/wallManage.service';

interface WallManageSlice {
  messageList: IMessageContent[];
  categoryMessageSelected: string | undefined;
}

const initialState: WallManageSlice = {
  messageList: [],
  categoryMessageSelected: undefined,
};

export const wallManageSlice = createSlice({
  name: 'wallManage',
  initialState,
  reducers: {
    setMessageList: (state, action: PayloadAction<IMessageContent[]>) => {
      state.messageList = action.payload;
    },
    setCategoryMessageSelected: (state, action: PayloadAction<string>) => {
      state.categoryMessageSelected = action.payload;
    },
    resetMessageList: state => {
      state.messageList = [];
    },
    resetCategoryMessageSelected: state => {
      state.categoryMessageSelected = undefined;
    },
    removeMessageFromList: (state, action: PayloadAction<IMessageContent>) => {
      state.messageList = state.messageList.filter(
        message => message.id !== action.payload.id
      );
    },
    addMessageInList: (state, action: PayloadAction<IMessageContent>) => {
      state.messageList = [
        ...state.messageList.filter(
          message => message.id !== action.payload.id
        ),
        action.payload,
      ];
    },
  },
});

export const {
  setMessageList,
  setCategoryMessageSelected,
  resetMessageList,
  resetCategoryMessageSelected,
  removeMessageFromList,
  addMessageInList,
} = wallManageSlice.actions;

export const getListMessages =
  ({
    id,
    accepted = false,
    obsoleted = false,
    page = 0,
  }: {
    id?: string;
    accepted?: boolean;
    obsoleted?: boolean;
    page?: number;
  }): AppThunk =>
  dispatch => {
    getMessages({ id, accepted, obsoleted, page }).then(
      res => {
        res.content && dispatch(setMessageList(res.content));
        dispatch(setCategoryMessageSelected(id ? id : '0'));
      },
      err =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.LOADMESSAGES'),
          })
        )
    );
  };

export const updateMessage =
  (editedMessage: IMessageContent, idCat?: string): AppThunk =>
  dispatch => {
    updateStatusMessage(editedMessage, idCat).then(
      res => {
        dispatch(addMessageInList(editedMessage));
        dispatch(
          addSuccessMessage({
            title: i18n.t('SUCCESSMSG.EDITMESSAGE'),
          })
        );
      },
      err =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.SAVEMESSAGE'),
          })
        )
    );
  };

export const selectMessageList = (state: RootState) =>
  state.wallManage.messageList;
export const selectCategoryMessageSelected = (state: RootState) =>
  state.wallManage.categoryMessageSelected;

export default wallManageSlice.reducer;
