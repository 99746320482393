import React, { FC } from 'react';
import Label from './../label/Label';
interface IBoxLabel {
  label?: string;
  className?: string;
  children: React.ReactNode;
}

const BoxLabel: FC<IBoxLabel> = ({ label, className, children }) => {
  return (
    <div className={`box-label ${className}`}>
      {label && <Label label={label} className="title-box" />}
      {children}
    </div>
  );
};

export default BoxLabel;
