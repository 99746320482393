import React from 'react';
import { Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IBtnList } from '../../../../app/auth/model/BtnList';
import TabsPaneV2 from '../../../../components/shared/design/tabs/TabsV2';
import { SkillsRadarConfig } from '../models/type';
import RadarForm from './RadarForm';

interface ISidebarContent {
  btnListLeft: IBtnList[];
  btnListRight: IBtnList[];
  radarActionToolbarBtn: <T>(action: string, data?: T) => void;
  skillRadarConfig?: SkillsRadarConfig;
  tabBackground: JSX.Element;
  categoryId: string;
  handleDataFormChange: (data: SkillsRadarConfig) => void;
}

function SidebarContent(props: ISidebarContent) {
  const {
    btnListLeft,
    btnListRight,
    radarActionToolbarBtn,
    skillRadarConfig,
    tabBackground,
    categoryId,
    handleDataFormChange,
  } = props;

  const { t } = useTranslation();

  return (
    <>
      <TabsPaneV2 defaultActiveKey="radar">
        <Tab eventKey="radar" title={t('APP.RADAR_TITLE')}>
          <RadarForm
            categoryId={categoryId}
            onToolbarAction={radarActionToolbarBtn}
            skillRadarConfig={skillRadarConfig}
            toolbarData={{ left: btnListLeft, right: btnListRight }}
            onChangeDataForm={handleDataFormChange}
          />
        </Tab>
        <Tab eventKey="background" title={t('APP.BACKGROUND')}>
          {tabBackground}
        </Tab>
      </TabsPaneV2>
    </>
  );
}

export default SidebarContent;
