import { IBtnList } from '../../../app/auth/model/BtnList';
import { fontStyle, fontWeight } from '../../../app/auth/model/FormTypes';
import { ITabs } from '../../../components/shared/design/tabs/models/tabsModels';
import { IBackgroundScene } from './models/BackgroundScene';
import { IPinScene } from './models/PinScene';

export const formatTextInit = {
  fontFamily: 'Helvetica',
  fontSize: '12',
  color: '#000000',
  hasBgColor: false,
  bgColor: undefined,
  opacity: '1',
  fontWeight: fontWeight.NORMAL,
  fontStyle: fontStyle.NORMAL,
  underline: false,
  charSpacing: '1',
};

export const listPinAvailableScene: IPinScene[] = [];

export const listBackgroundScene: IBackgroundScene[] = [];

export const listTabNameScene = {
  background: 'background',
  pinScene: 'pinScene',
  check: 'check',
};

export const listTabsScene: ITabs[] = [
  {
    keyTab: listTabNameScene.background,
    title: 'APP.BACKGROUND',
  },
  {
    keyTab: listTabNameScene.pinScene,
    title: 'APP.PIN_TITLE',
  },
  {
    keyTab: listTabNameScene.check,
    title: 'CHECK.TITLE',
  },
];

export const bgToolbarBtn: IBtnList[] = [
  {
    label: 'APP.EDIT',
    icon: 'add_circle',
    color: '#34a381',
    action: 'edit',
  },
];
