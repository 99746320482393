import { TFunction } from 'i18next';
import isEmpty from 'lodash.isempty';
import React, { ReactNode } from 'react';
import BoxLabel from '../../../../components/shared/design/form/box/BoxLabel';
import FileManager from '../../../../components/shared/fileManager/FileManager';
import Icon from '../../../../components/shared/icon/Icon';
import { FormElementModelNew } from '../../../../utils/forms/models/FormElementModel';
import { createStructureForm } from '../../../../utils/forms/useStructureForm';
import RowTemplateDelete from '../../game/gamePlatform/components/RowTemplateDelete';

export type Animate2PlayInfo = {
  uri: string;
  name: string;
  progressive: number;
  episodio: string;
};

export function showFileNameFromUri(uri: string) {
  const uriArray = uri.split('/');
  if (uriArray && uriArray.length > 1) {
    const lastIndex = uriArray.length - 2;
    return uriArray[lastIndex];
  } else {
    return uri;
  }
}

function useAnimationForm(
  t: TFunction,
  dataForm: any,
  namesList: any[],
  errors: { [key: string]: any },
  handleOnDropFile: (
    files: File[],
    nameDrop: string,
    formName: string
  ) => Promise<any>,
  handleDeleteFile: (fileName: string, formName: string) => void,
  handlePlayAnimate: (animate: Animate2PlayInfo) => void
) {
  const dataStructure: {
    [key in 'parent' | 'animates']: FormElementModelNew[];
  } = {
    parent: createStructureForm(
      [
        {
          typeElement: 'input',
          label: t('APP.FORM.ANIMATION_NAME'),
          name: 'name',
          dataElement: {
            placeholder: t('APP.FORM.ANIMATION_NAME'),
            type: 'text',
          },
          col: { xs: 12 },
        },
      ],
      dataForm
    ),
    animates: createStructureForm(
      [
        {
          name: 'animates',
          label: 'Animazioni',
          templateRow: (
            children: React.ReactNode,
            deleteElement?: () => void,
            values: any = {}
          ) => (
            <BoxLabel className="nested-accordion mb-3">
              <RowTemplateDelete
                deleteElement={() => deleteElement && deleteElement()}
              >
                {children}
              </RowTemplateDelete>
            </BoxLabel>
          ),
          form: [
            {
              typeElement: 'input',
              label: t('APP.FORM.ANIMATION_NAME'),
              name: 'name',
              dataElement: {
                placeholder: t('APP.FORM.ANIMATION_NAME'),
                type: 'text',
              },
              col: { xs: 9 },
            },
            {
              typeElement: 'input',
              name: 'progressive',
              label: t('GAMEMANAGEMENT.PROGRESSIVE'),
              dataElement: {
                type: 'text',
                disabled: true,
              },
              col: { xs: 3 },
            },
            {
              typeElement: 'select',
              label: t('CHECK.FINDACL'),
              name: 'acl',
              dataElement: {
                placeholder: t('CHECK.FINDACL'),
                type: 'text',
                disabled: false,
              },
              options: [
                {
                  value: '-1',
                  label: t('APP.FORM.CHOOSE'),
                },
                ...namesList.map(name => {
                  return {
                    value: name._id,
                    label: name.name,
                  };
                }),
              ],
              col: { xs: 12 },
            },

            {
              typeElement: 'input',
              label: t('APP.FORM.WIDTH'),
              name: 'width',
              dataElement: {
                placeholder: t('APP.FORM.WIDTH'),
                type: 'text',
              },
              col: { xs: 3 },
            },
            {
              typeElement: 'input',
              label: t('APP.FORM.HEIGHT'),
              name: 'height',
              dataElement: {
                placeholder: t('APP.FORM.HEIGHT'),
                type: 'text',
              },
              col: { xs: 3 },
            },
            {
              typeElement: 'switch',
              label: t('APP.FORM.AUTOSTART'),
              name: 'autostart',
              dataElement: {
                type: 'checkbox',
              },
              col: { xs: 3 },
            },
            {
              typeElement: 'switch',
              label: t('APP.FORM.WITHSOUND'),
              name: 'withSound',
              dataElement: {
                type: 'checkbox',
              },
              col: { xs: 3 },
            },
            {
              typeElement: 'input',
              label: t('APP.FORM.URI'),
              name: 'uri',
              dataElement: {
                className: 'hidden',
                type: 'text',
              },
              col: { xs: 8 },
              template: (children: ReactNode, formElement: any, data: any) => {
                function onDrop(files: File[], nameDrop: string) {
                  handleOnDropFile(files, nameDrop, formElement['name']);
                }

                return (
                  <div className="d-flex flex-column">
                    <FileManager
                      uploadedFileName={data[formElement.name]}
                      fileNameToShow={
                        data[formElement.name] &&
                        showFileNameFromUri(data[formElement.name])
                      }
                      label="Link"
                      onDrop={onDrop}
                      notValidFile={errors}
                      nameDrop="fileSource"
                      onDelete={() => {
                        handleDeleteFile(
                          data[formElement.name],
                          formElement.name
                        );
                      }}
                    />
                    {!isEmpty(data[formElement.name]) && (
                      <Icon
                        icon={'play_circle_outline'}
                        colorStyle={'primary'}
                        size="40px"
                        disabled={false}
                        title={'Play'}
                        clickAction={() => {
                          const base = (formElement.name as string).replace(
                            'uri',
                            ''
                          );
                          const animate: Animate2PlayInfo = {
                            uri: data[formElement.name] || '',
                            name: data[`${base}name`] || 'Nome TBD',
                            progressive: data[`${base}progressive`] || -1,
                            episodio: data['name'] || 'Episodio TBD',
                          };
                          handlePlayAnimate(animate);
                        }}
                      ></Icon>
                    )}
                  </div>
                );
              },
            },
          ],
        },
      ],
      dataForm
    ),
  };

  return { dataStructure };
}

export default useAnimationForm;
