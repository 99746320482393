import WeblinkLayout from '../features/auth/weblinks/WeblinkLayout';
import { SingleRoute } from '../utils/routes/models/routes';
import * as routeName from './routeNameAuthType';

const uri: string = '/categories';

const routeWeblinks: SingleRoute[] = [
  {
    path: `${uri}/:id`,
    component: WeblinkLayout,
    name: routeName.weblinks,
  },
];
export default routeWeblinks;
