import React from 'react';
import { mergeClass } from '../../../../functions/function';
import { ButtonProps as BtnProps } from 'react-bootstrap/Button';
import Button from '../button/Button';

interface ButtonProps extends BtnProps {}
const PrimaryButton = ({
  children,
  className,
  variant = 'primary',
  type = 'submit',
  ...rest
}: ButtonProps) => {
  return (
    <Button
      type={type}
      variant={variant}
      className={mergeClass('sh-primary', className)}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
