import http from '../../../../utils/http';
import { IThumb } from '../../fabricJs/models/Thumb';
import { getFiles } from '../../services/upload.service';
import { ICustomBackground } from './../models/types';
import omit from 'lodash.omit';

//const baseUrl = `/animates`;

export const getAllAvailableBackGround = (
  idScene: string,
  baseUrl: string
): Promise<ICustomBackground[]> =>
  http.get(`/${baseUrl}/${idScene}/backgrounds`);

export const getBackGround = (
  idScene: string,
  id: string,
  baseUrl: string
): Promise<ICustomBackground> =>
  http.get(`/${baseUrl}/${idScene}/backgrounds/${id}`);

export const createBackground = (
  idScene: string,
  bg: ICustomBackground,
  baseUrl: string
) => {
  const newBg = omit(bg, ['_id']);
  return http.post(`/${baseUrl}/${idScene}/backgrounds`, newBg);
};

export const updateBackground = (
  idScene: string,
  bg: ICustomBackground,
  baseUrl: string
) => {
  const newBg = omit(bg, ['_id']);
  return http.put(`/${baseUrl}/${idScene}/backgrounds/${bg._id}`, newBg);
};
export const removeBackground = (
  idScene: string,
  id: string,
  baseUrl: string
) => http.delete(`/${baseUrl}/${idScene}/backgrounds/${id}`);

export const getAvailableBackgroundFiles = (
  pathName: string
): Promise<IThumb[]> => {
  return getFiles({ pathName: pathName }).then(
    data => {
      return data.map(bg => ({
        _id: '',
        image: {
          iconUrl: bg.url,
          iconSize: {
            width: 1920,
            height: 1080,
          },
        },
        name: bg.originalfilename,
        position: {
          x: 0,
          y: 0,
        },
        type: bg.mimetype,
      }));
    },
    err => {
      console.log(err);
      return [];
    }
  );
};
