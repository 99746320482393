import Radar from '../features/auth/radar';
import { SingleRoute } from '../utils/routes/models/routes';
import * as routeName from './routeNameAuthType';

const uri: string = '';

const routeRadar: SingleRoute[] = [
  {
    path: `${uri}/:id`,
    component: Radar,
    name: routeName.radar,
  },
];
export default routeRadar;
