import { TFunction } from 'i18next';
import isEqual from 'lodash.isequal';
import { navigationActions } from '../features/auth/data/navigationData';

export const createSameOptions = (data: any[] = []) => {
  const arr = data.map(ele => ({
    label: ele,
    value: ele,
  }));
  return [
    {
      label: 'Seleziona...',
      value: -1,
    },
    ...arr,
  ];
};
export const createSameOptionsWithoutEmpty = (data: any[] = []) => {
  const arr = data.map(ele => ({
    label: ele,
    value: ele,
  }));
  return arr;
};

export const createSameOptionsWith18n = (data: any[] = [], t: TFunction) => {
  // eslint-disable-next-line array-callback-return
  const filtered = navigationActions(t).filter(opt => {
    const item = data.find(o => isEqual(o, opt.value));
    if (item) return item;
  });
  const options = filtered;
  return [
    {
      label: 'Seleziona...',
      value: -1,
    },
    ...options,
  ];
};
