import GameReport from '../features/auth/gamereport';
import { SingleRoute } from '../utils/routes/models/routes';
import * as routeName from './routeNameAuthType';

const uri: string = '';

const routeGameReport: SingleRoute[] = [
  {
    path: `${uri}`,
    component: GameReport,
    name: routeName.gamereport,
  },
];
export default routeGameReport;
