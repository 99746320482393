import {
  resetModal,
  setModal,
} from './../../../../../components/shared/redux/modal/modalSlice';
import {
  deleteUserPreference,
  editUserPreference,
  getUserPreference,
} from './services/userpreference.service';
import i18n from '../../../../../config/i18n';
import {
  addErrorMessage,
  addSuccessMessage,
} from './../../../../../app/errors/errorSlice';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../../app/store';
import { ICategory } from '../../../models/Categories';

export interface IUserManagementSlice {
  user: any | undefined;
  userToEdit: any | undefined;
  userId: string | undefined;
}
const initialState: IUserManagementSlice = {
  user: undefined,
  userToEdit: undefined,
  userId: undefined,
};

export const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<ICategory>) => {
      state.user = action.payload;
    },
    setUserToEdit: (state, action: PayloadAction<ICategory>) => {
      state.userToEdit = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    resetUser: state => {
      state.user = undefined;
    },
    resetUserToEdit: state => {
      state.userToEdit = undefined;
    },
    resetUserId: state => {
      state.userId = undefined;
    },
  },
});

export const {
  setUser,
  setUserToEdit,
  resetUser,
  resetUserToEdit,
  setUserId,
  resetUserId,
} = userManagementSlice.actions;

export const getUser =
  ({ username }: { username: string }): AppThunk =>
  dispatch => {
    getUserPreference(username).then(
      (res: any) => {
        dispatch(setUser(res));
        dispatch(setUserId(username));
      },
      (err: any) =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.SEARCHUSER'),
          })
        )
    );
  };
export const deleteUser =
  (userId: string): AppThunk =>
  dispatch => {
    const buttonList = [
      {
        label: i18n.t('MODAL.CANCELL'),
        action: 'cancelDelete',
        type: 'secondary',
        dataType: {},
      },
      {
        label: i18n.t('MODAL.CONFIRM'),
        action: 'confirmDelete',
        type: 'primary',
        dataType: {},
      },
    ];
    const confirmDelete = () => {
      deleteUserPreference(userId).then(
        (res: any) => {
          dispatch(setUser(res));
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETEUSER'),
            })
          );
        },
        (err: any) =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETEUSER'),
            })
          )
      );
      dispatch(resetModal());
    };
    const cancelDelete = () => {
      dispatch(resetModal());
    };
    dispatch(
      setModal(
        buttonList,
        {
          title: i18n.t('MESSAGES.DELETEUSER'),
          body: i18n.t('MESSAGES.CONFIRMDELETEUSER ' + userId),
        },
        {
          cancelDelete,
          confirmDelete,
        }
      )
    );
  };
export const editUser =
  (userpreference: any): AppThunk =>
  dispatch => {
    editUserPreference(userpreference).then(
      (res: any) => {
        dispatch(setUser(res));
        dispatch(
          addSuccessMessage({
            title: i18n.t('SUCCESSMSG.EDITUSER'),
          })
        );
      },
      (err: any) =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.EDITUSER'),
          })
        )
    );
  };

export const resetUserAll = (): AppThunk => dispatch => {
  dispatch(resetUser());
  dispatch(resetUserToEdit());
};

export const selectUser = (state: RootState) => state.userManagement.user;

export const selectUserToEdit = (state: RootState) =>
  state.userManagement.userToEdit;
export const selectUserId = (state: RootState) => state.userManagement.userId;

export default userManagementSlice.reducer;
