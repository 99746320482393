import { useFabricJSEditor } from 'fabricjs-react';
import isEqual from 'lodash.isequal';
import React, { useEffect, useState } from 'react';
import { IBtnList } from '../../../../app/auth/model/BtnList';
import ToolbarBtn from '../../../../components/shared/custom/sidebar/toolbarBtn/ToolbarBtn';
import { copyObject } from '../../../../utils/objUtils';
import { IFabricJSEditor } from '../../fabricJs/models/FabricModel';
import screenUtils from '../../fabricJs/screenUtils';
import { deleteFile } from '../../services/upload.service';
import { ICustomBackground } from '../models/types';
import {
  createBackground,
  getAllAvailableBackGround,
  getAvailableBackgroundFiles,
  removeBackground,
  updateBackground,
} from '../services/background.service';
import DisplayBgsAvailable from './components/DisplayBgsAvailable';
import {
  addErrorMessage,
  addSuccessMessage,
} from '../../../../app/errors/errorSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { IThumb } from '../../fabricJs/models/Thumb';

function useBackgroundForm(
  sceneId: string,
  toolbarData: {
    left: IBtnList[];
    right: IBtnList[];
  },
  baseUrl: string,
  pathName: string
) {
  const { t } = useTranslation();
  const [listAvailableBg, setListAvailableBg] = useState<ICustomBackground[]>(
    []
  );
  const [listAvailableFiles, setListAvailableFiles] = useState<
    ICustomBackground[]
  >([]);
  const [selectedBg, setSelectedBg] = useState<ICustomBackground>();
  const dispatch = useDispatch();

  const funcToolbarBtn: { [key: string]: (data?: any) => void } = {
    save: () => {
      if (listAvailableBg && listAvailableBg.length > 0 && listAvailableBg[0]) {
        //sovrascrivo quello già presente
        selectedBg && updateAnimationBackground(sceneId, selectedBg);
      } else {
        //post
        selectedBg && createNewBackGround(sceneId, selectedBg);
      }
    },
    delete: () => {
      const bg = listAvailableBg.find(item =>
        isEqual(item.image.iconUrl, selectedBg?.image.iconUrl)
      );
      if (bg && bg._id) {
        removeBackgroundFromAnimation(sceneId, bg._id);
      }
    },
  };

  const displayThumbs = (
    <>
      <ToolbarBtn
        btnListLeft={toolbarData.left}
        btnListRight={toolbarData.right}
        clickAction={(action: string) =>
          handlerBackgroundActionToolbarBtn(action)
        }
      />
      <DisplayBgsAvailable
        listThumb={listAvailableFiles}
        clickAddThumb={(thumb: IThumb) => addThumb(thumb)}
        clickRemoveThumb={(thumb: IThumb) => removeThumb(thumb)}
        setThumb={setListAvailableFiles}
        pathName={pathName}
      />
    </>
  );

  const { editor }: IFabricJSEditor = useFabricJSEditor();

  useEffect(() => {
    //files salvati nel folder animate-bg
    getAvailableBackgroundFiles(pathName).then(res =>
      setListAvailableFiles(res as ICustomBackground[])
    );
    //oggetti salvati a db
    getAllAvailableBg(sceneId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sceneId]);

  function getAllAvailableBg(sceneId: string) {
    getAllAvailableBackGround(sceneId, baseUrl)
      .then(res => {
        setListAvailableBg(res);
        setSelectedBg(res[0]);
      })
      .catch(err => console.log(err));
  }

  function addThumb(thumb: IThumb) {
    // aggiungo il bg alla scena
    if (listAvailableBg && listAvailableBg.length === 1 && listAvailableBg[0]) {
      //se ne ho già salvato uno modifico solo l'url della nuova immagine
      const bg = copyObject(listAvailableBg[0]);
      bg.image.iconUrl = thumb.image.iconUrl;
      bg.type = thumb.type || bg.type;
      bg.position = thumb.position || bg.position;

      setSelectedBg(bg);
      return;
    }
    const coordsScreenCenter = screenUtils(editor);
    const thumbBgWithPosition = {
      ...thumb,
      position: coordsScreenCenter,
    };
    setSelectedBg(thumbBgWithPosition as ICustomBackground);
  }

  function removeThumb(thumb: IThumb) {
    const documentName = `${pathName}/${thumb.image.iconUrl.split('/').pop()}`;
    documentName &&
      deleteFile(documentName).then(
        res => {
          setListAvailableFiles(
            listAvailableFiles.filter(item => !isEqual(item, thumb))
          );
          if (selectedBg) {
            if (isEqual(selectedBg._id, res._id)) {
              removeBackgroundFromAnimation(sceneId, selectedBg._id);
            }
          }
        },
        err => {
          console.log(err);
        }
      );
  }

  function createNewBackGround(sceneId: string, bg: ICustomBackground) {
    createBackground(sceneId, bg, baseUrl)
      .then(res => {
        setSelectedBg(bg);
        dispatch(
          addSuccessMessage({
            title: t('SUCCESSMSG.SAVEBG'),
          })
        );
        getAllAvailableBg(sceneId);
      })
      .catch(err =>
        dispatch(
          addErrorMessage({
            title: t('ERRORSMSG.EDITBG'),
          })
        )
      );
  }

  function updateAnimationBackground(sceneId: string, bg: ICustomBackground) {
    updateBackground(sceneId, bg, baseUrl)
      .then(res => {
        setSelectedBg(bg);
        dispatch(
          addSuccessMessage({
            title: t('SUCCESSMSG.SAVEBG'),
          })
        );
        getAllAvailableBg(sceneId);
      })
      .catch(err =>
        dispatch(
          addErrorMessage({
            title: t('ERRORSMSG.SAVEBG'),
          })
        )
      );
  }

  function removeBackgroundFromAnimation(sceneId: string, id: string) {
    removeBackground(sceneId, id, baseUrl)
      .then(res => {
        dispatch(
          addSuccessMessage({
            title: t('SUCCESSMSG.DELETEBG'),
          })
        );
        setSelectedBg(undefined);
        getAllAvailableBg(sceneId);
      })
      .catch(err =>
        dispatch(
          addErrorMessage({
            title: t('ERRORSMSG.DELETEBG'),
          })
        )
      );
  }

  function handlerBackgroundActionToolbarBtn<T>(action: string, data?: T) {
    funcToolbarBtn && funcToolbarBtn[action] && funcToolbarBtn[action](data);
  }

  return {
    displayThumbs,
    selectedBg,
  };
}

export default useBackgroundForm;
