import isEqual from 'lodash.isequal';
import React from 'react';
import {
  ActionMeta,
  InputActionMeta,
  OnChangeValue,
  SingleValue,
} from 'react-select';
import Creatable from 'react-select/creatable';
import {
  OptionSelect,
  selectType,
} from '../../../../../utils/forms/models/FormElementModel';

const CreatableSelect: React.FC<selectType> = ({
  error = false,
  name,
  value = '',
  options = [],
  changeSelect = (e: any) => {},
  disabled = false,
  selectedValue,
  clickAction = (value: any) => {},
  ...rest
}) => {
  const handleChange = (
    newValue: OnChangeValue<OptionSelect, false>,
    actionMeta: ActionMeta<OptionSelect>
  ) => {
    addOptionIfnotExists(newValue);
    changeSelect(newValue);
  };

  const handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    if (newValue && newValue !== '') {
      changeSelect(newValue);
    }
  };

  const addOptionIfnotExists = (value: SingleValue<OptionSelect>) => {
    if (value && !filterItems(value)) {
      options.push({ value: value.value, label: value.label });
    }

    return options;
  };

  const filterItems = (inputValue: SingleValue<OptionSelect>) => {
    return options.filter(i => isEqual(i, inputValue));
  };

  const customStyle = {
    control: (styles: any) => ({
      ...styles,
      height: '32px',
      minHeight: '32px',
      borderRadius: '8px',
      fontSize: '14px',
      fontFamily: 'Roboto-regular',
      border: 'none',
    }),
  };

  return (
    <>
      <Creatable
        name={name}
        isClearable
        onChange={handleChange}
        onInputChange={handleInputChange}
        options={addOptionIfnotExists({ value: value, label: value })}
        defaultValue={{ value: value, label: value }}
        styles={customStyle}
      />
    </>
  );
};

export default CreatableSelect;
