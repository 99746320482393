import isEqual from 'lodash.isequal';
import React, { useEffect, useState } from 'react';
import CheckboxTree, { OnCheckNode } from 'react-checkbox-tree';
import { ICategory } from '../../../models/Categories';

import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChartNode } from '../../types';

function ReportTreeView({
  name = '',
  values = [],
  clickAction,
  ...rest
}: {
  name: string;
  values: ICategory[];
  clickAction: (value: any) => void;
}) {
  const [data, setData] = useState<ChartNode[]>([]);
  const [state, setState] = useState<{ checked: string[]; expanded: string[] }>(
    { checked: [], expanded: [] }
  );
  const minDepth: number = values.reduce((a, b) =>
    a.depth <= b.depth ? a : b
  ).depth;

  const icons = {
    check: <FontAwesomeIcon icon={faCheckSquare} />,
    uncheck: <FontAwesomeIcon icon={faSquare} />,
    halfCheck: <></>,
    expandClose: <></>,
    expandOpen: <></>,
    expandAll: <></>,
    collapseAll: <></>,
    parentClose: <></>,
    parentOpen: <></>,
    leaf: <></>,
  };

  useEffect(() => {
    values
      .filter((item: ICategory) => item.depth === minDepth)
      // eslint-disable-next-line array-callback-return
      .map((cat: ICategory) => {
        if (!cat.children || cat.children?.length < 1) {
          addItemToData(cat);
        } else {
          const children = values.filter(item =>
            isEqual(item.father?.id, cat.id)
          );
          addItemToData(cat, children);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  function onClickAction(checkedNode: OnCheckNode) {
    setState(prev => ({ ...prev, checked: [checkedNode.value] }));
    clickAction(checkedNode.value);
  }

  function addItemToData(category: ICategory, children?: ICategory[]) {
    setData((prev: any) => [
      {
        ...prev,
        value: category.id,
        label: `${category.id} - ${category.name}`,
        children: addItemWithChildren(category, values, children),
      },
    ]);
  }

  function addItemWithChildren(
    father: ICategory,
    values: ICategory[],
    children?: ICategory[]
  ) {
    let dataChildren: any = [];

    if (children) {
      dataChildren = values
        .filter(item => isEqual(item.father?.id, father.id))
        .map(item => {
          return {
            label: `${item.id} - ${item.name}`,
            value: item.id,
            children: addItemWithChildren(item, values, item.children),
          };
        });
    }
    return dataChildren;
  }

  return (
    <div className="checkboxTree">
      <CheckboxTree
        nodes={data}
        expanded={state?.expanded}
        checked={state?.checked}
        icons={icons}
        checkModel="all"
        noCascade={true}
        expandOnClick
        onClick={checkedNode => onClickAction(checkedNode)}
        onExpand={expand => setState(prev => ({ ...prev, expanded: expand }))}
      />
    </div>
  );
}

export default ReportTreeView;
