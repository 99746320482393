import AnimationLayout from '../features/auth/animations/AnimationLayout';
import { SingleRoute } from '../utils/routes/models/routes';
import * as routeName from './routeNameAuthType';

const uri: string = '/categories';

const routeAnimations: SingleRoute[] = [
  {
    path: `${uri}/:id`,
    component: AnimationLayout,
    name: routeName.animations,
  },
  {
    path: `${uri}/:id/animate/:idAnimate`,
    component: AnimationLayout,
    name: routeName.animations,
  },
];
export default routeAnimations;
