import omit from 'lodash.omit';
import http from '../../../../../utils/http';
import { IIdResponse } from '../../../fabricJs/models/servicesResponse';
import { IBackgroundGame } from '../../gamePlatform/models/backgroundGame';

const urlLoadGame = `/microgames`;

export const getBackgroundMicroGame = (id: string): Promise<any[]> =>
  http.get(`${urlLoadGame}/${id}/backgrounds`);

export const saveBgForMicroGame = (
  bg: IBackgroundGame,
  idGame: string
): Promise<IIdResponse> => {
  const newBg = omit(bg, ['id', 'id_temp']);
  return http.post(`${urlLoadGame}/${idGame}/backgrounds`, newBg);
};

export const editBgForMicroGame = (
  bg: IBackgroundGame,
  idGame: string
): Promise<IIdResponse> => {
  const newBg = omit(bg, ['id', '_id', 'id_temp', 'gameId']);
  return http.put(`${urlLoadGame}/${idGame}/backgrounds/${bg._id}`, newBg);
};

export const deleteBgForMicroGame = (
  bg: IBackgroundGame,
  idGame: string
): Promise<IIdResponse> => {
  return http.delete(`${urlLoadGame}/${idGame}/backgrounds/${bg._id}`);
};
