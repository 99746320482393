import http from '../../../utils/http';
import { RsFileUploaded } from '../projectMap/models/File';

const BASE_PATH = `/gps3/files`;
export type UploadedFileType = {
  cdn: string;
  url: string;
  documentName: string;
  isPublic: boolean;
  mimetype: string;
  originalfilename: string;
};

export const uploadFile = (
  file: FormData,
  params?: {
    prefix?: string;
    filename?: string;
    isPublic?: string;
    isStaticName?: string;
    pathName?: string;
  }
): Promise<RsFileUploaded> => {
  return http.post(`${BASE_PATH}/_upload`, file, {
    params,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
export const uploadUnzip = (
  file: FormData,
  params?: {
    isPublic?: string;
    pathName?: string;
  }
): Promise<RsFileUploaded> =>
  http.post(`${BASE_PATH}/_upload_unzip`, file, {
    params,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const getFiles = (params?: {
  prefix?: string;
  pathName?: string;
}): Promise<UploadedFileType[]> => http.get(`${BASE_PATH}/_list`, { params });

export const deleteFile = (documentName: string): Promise<any> =>
  http.delete(`${BASE_PATH}/_remove`, {
    params: { documentName },
  });
