import isEqual from 'lodash.isequal';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IBtnList } from '../../../../../app/auth/model/BtnList';
import ToolbarBtn from '../../../../../components/shared/custom/sidebar/toolbarBtn/ToolbarBtn';
import useStateHistory from '../../../../../hooks/useStateHistory';
import FormElements from '../../../../../utils/forms/FormElements';
import { UseFormSubmitModel } from '../../../../../utils/forms/models/UseFormModel';
import { useForm } from '../../../../../utils/forms/useForm';
import {
  getObjForm,
  setObjForm,
} from '../../../../../utils/forms/useStructureForm';
import { WeblinkData } from '../../models/types';
import useWeblinkForm from '../useWeblinkForm';
import { validations } from './ValidationData';

type WeblinkFormProps = {
  weblinkData: WeblinkData | undefined;
  onToolbarAction: (action: string) => void;
  onChangeDataForm: (data: WeblinkData) => void;
  toolbarData: {
    left: IBtnList[];
    right: IBtnList[];
  };
};

function WeblinkForm(props: WeblinkFormProps) {
  const { weblinkData, onToolbarAction, onChangeDataForm, toolbarData } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [weblinkForm, setWeblinkForm] = useStateHistory(weblinkData);

  const submitHandler: UseFormSubmitModel = (event, dataSubmit: any) => {
    onToolbarAction('save');
  };

  const validationsData = validations();

  const form = useForm(submitHandler, validationsData);
  const {
    isSubmit,
    errors,
    submitAction,
    changeValue,
    dataForm,
    setData,
    deleteElementByArray,
    dataFormatted,
  } = form;

  const { dataStructure } = useWeblinkForm(t, dataForm);

  useEffect(() => {
    if (weblinkData) {
      const toForm = setObjForm(weblinkData);
      if (!isEqual(toForm, dataForm)) {
        setData(toForm);
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weblinkData]);

  useEffect(() => {
    if (!loading) {
      const toObj = getObjForm(dataForm);
      if (!isEqual(dataFormatted, weblinkForm)) {
        //setWeblinkForm(dataFormatted);
        onChangeDataForm(toObj as WeblinkData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm]);

  return (
    <>
      <ToolbarBtn
        btnListLeft={toolbarData.left}
        btnListRight={toolbarData.right}
        clickAction={(action: string) => {
          switch (action) {
            case 'save':
              submitAction();
              break;
            default:
              onToolbarAction(action);
          }
        }}
      />
      <Form onSubmit={submitAction}>
        <FormElements
          data={dataStructure}
          dataFormatted={dataFormatted}
          errors={errors}
          isSubmit={isSubmit}
          dataForm={dataForm}
          changeValue={changeValue}
          deleteElementByArray={deleteElementByArray}
        />
      </Form>
    </>
  );
}

export default WeblinkForm;
