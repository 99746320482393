import { omit } from 'lodash';
import http from '../../../../utils/http';
import { copyObject } from '../../../../utils/objUtils';
import { subCategory } from '../../resources/ResourceData';
import { IIdResponse } from '../models/servicesResponse';
import { IBackgroundScene } from './../models/BackgroundScene';
import { IPinScene } from './../models/PinScene';

const urlScene = `/scenes`;

// ###### PINS ######

export const getPinListBySceneId = (idScene: string): Promise<IPinScene[]> =>
  http.get(`${urlScene}/${idScene}/pins`);

export const savePinForSceneId = (
  idScene: string,
  pin: Omit<IPinScene, '_id'>
): Promise<IIdResponse> => {
  const copyPin = omit(copyObject(pin), [subCategory]);
  return http.post(`${urlScene}/${idScene}/pins`, copyPin);
};

export const editPinForSceneId = (
  idScene: string,
  pin: IPinScene
): Promise<IPinScene> => {
  const copyPin = omit(copyObject(pin), ['_id', 'sceneId', subCategory]);
  return http.put(`${urlScene}/${idScene}/pins/${pin._id}`, copyPin);
};

export const getPinForSceneId = (
  idScene: string,
  idPin: string
): Promise<IPinScene> => {
  return http.get(`${urlScene}/${idScene}/pins/${idPin}`);
};

export const deletePinForSceneId = (idScene: string, idPin: string) =>
  http.delete(`${urlScene}/${idScene}/pins/${idPin}`);

// ###### BACKGROUNDS ######

export const getBackgroundForSceneId = (
  idScene: string,
  idBg: string
): Promise<IBackgroundScene> =>
  http.get(`${urlScene}/${idScene}/backgrounds/${idBg}`);

export const getBackgroundListBySceneId = (
  idScene: string
): Promise<IBackgroundScene[]> =>
  http.get(`${urlScene}/${idScene}/backgrounds`);

export const saveBackgroundForSceneId = (
  idScene: string,
  bg: Omit<IBackgroundScene, '_id'>
): Promise<IIdResponse> => http.post(`${urlScene}/${idScene}/backgrounds`, bg);

export const editBackgroundForSceneId = (
  idScene: string,
  bg: IBackgroundScene
): Promise<IBackgroundScene> => {
  const copyBg = omit(copyObject(bg), ['_id', 'sceneId']);
  return http.put(`${urlScene}/${idScene}/backgrounds/${bg._id}`, copyBg);
};

export const deleteBackgroundForSceneId = (
  idScene: string,
  idBg: string
): Promise<void> => http.delete(`${urlScene}/${idScene}/backgrounds/${idBg}`);
