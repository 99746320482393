import React from 'react';
import { FormControl } from 'react-bootstrap';
import { mergeClass } from '../../../functions/function';
import { TextAreaType } from '../../../../../utils/forms/models/FormElementModel';

const TextArea: React.FC<TextAreaType> = ({
  children,
  className,
  error = false,
  value,
  ...rest
}) => {
  const classMerge: string = mergeClass(
    `${className ? ` ${className}` : 'shared-textArea'}`,
    error ? ' error' : ''
  );

  return (
    <FormControl
      className={classMerge}
      as="textarea"
      rows={3}
      value={value || ''}
      {...rest}
    />
  );
};

export default TextArea;
