import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../app/store';
import { AnimateData } from '../../animations/models/types';
import { getGameQuestionAnimates } from '../../animations/services/animation.service';
import { IAnswer, IQuestion, ISkill } from './models/gameQuestionConfiguration';
import {
  getAllAnswersByQuestionId,
  getAllQuestions,
  getAllSkills,
} from './services/gameConfiguration.service';

export interface GameNextOperationSliceModel {
  animationGames: AnimateData[];
  allQuestions: IQuestion[];

  allQuestionsForConditions: IQuestion[];
  allSkills: ISkill[];
  allAnswers: { questionId: string; answers: IAnswer[] }[];
}

const initialState: GameNextOperationSliceModel = {
  animationGames: [],
  allQuestions: [],
  allQuestionsForConditions: [],
  allSkills: [],
  allAnswers: [],
};

export const gameNextOperationSlice = createSlice({
  name: 'gameNextOperation',
  initialState,
  reducers: {
    setAnimationGames: (state, action: PayloadAction<AnimateData[]>) => {
      state.animationGames = action.payload;
    },
    setAllQuestions: (state, action: PayloadAction<IQuestion[]>) => {
      state.allQuestions = action.payload;
    },
    setAllQuestionForConditions: (
      state,
      action: PayloadAction<IQuestion[]>
    ) => {
      state.allQuestionsForConditions = action.payload;
    },
    setAllSkills: (state, action: PayloadAction<ISkill[]>) => {
      state.allSkills = action.payload;
    },
    setAllAnswers: (
      state,
      action: PayloadAction<{ questionId: string; answers: IAnswer[] }[]>
    ) => {
      state.allAnswers = action.payload;
    },
  },
});

export const {
  setAllQuestions,
  setAllQuestionForConditions,
  setAllSkills,
  setAnimationGames,
  setAllAnswers,
} = gameNextOperationSlice.actions;

export const getAnimationGames = (): AppThunk => dispatch => {
  getGameQuestionAnimates().then(res => {
    dispatch(setAnimationGames(res));
  });
};

export const allQuestions =
  (gameId?: string, categoryId?: number, subCategoryId?: number): AppThunk =>
  dispatch => {
    getAllQuestions(gameId, categoryId, subCategoryId).then(res => {
      dispatch(setAllQuestions(res));
    });
  };
export const allSkills =
  (
    languageId: string,
    gameId?: string,
    categoryId?: number,
    subCategoryId?: number
  ): AppThunk =>
  dispatch => {
    getAllSkills(languageId, gameId, categoryId, subCategoryId).then(res => {
      dispatch(setAllSkills(res));
    });
  };
export const allAnswers =
  (questionId: string, languageId: string): AppThunk =>
  dispatch => {
    getAllAnswersByQuestionId(questionId, languageId).then((res: IAnswer[]) => {
      dispatch(setAllAnswers([{ questionId: questionId, answers: res }]));
    });
  };
export const selectAnimationGames = (state: RootState) =>
  state.gameNextOperation.animationGames;

export const selectAllQuestions = (state: RootState) =>
  state.gameNextOperation.allQuestions;
export const selectAllQuestionsForConditions = (state: RootState) =>
  state.gameNextOperation.allQuestions;
export const selectAllSkills = (state: RootState) =>
  state.gameNextOperation.allSkills;
export const selectAllAnswers = (state: RootState) =>
  state.gameNextOperation.allAnswers;

export default gameNextOperationSlice.reducer;
