import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Container, Modal } from 'react-bootstrap';
import share from '../../assets/share.png';
import useLogo from '../../hooks/useLogo';

function InstallPWA() {
  const [show, setShow] = useState(false);

  const logo = useLogo();

  useEffect(() => {
    setShow(true);
  }, []);

  function handleClose() {
    localStorage.setItem('installPrompt', moment().toDate().toDateString());
    setShow(false);
  }

  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header className={`border-0`}>
        <Container className={`text-center`}>
          <img src={logo.url} alt="Add to homescreen" width={logo.width}
                height={logo.height} />
        </Container>
      </Modal.Header>
      <Modal.Body className={`border-0`}>
        <Container className={`text-center`}>
          <div className={`mb-4`}>
            Installa questa applicazione sulla schermata Home per migliorare la
            tua esperienza.
          </div>
          Clicca su
          <img
            src={share}
            style={{ margin: 'auto 4px 8px' }}
            alt="Add to homescreen"
            height="20"
            width="20"
          />
          poi "Aggiungi alla schermata Home"
        </Container>
      </Modal.Body>
      <Modal.Footer className={`border-0`}>
        <Container className={`text-center`}>
          <Button variant="link" onClick={handleClose}>
            CHIUDI
          </Button>
        </Container>
      </Modal.Footer>
    </Modal>
  );
}
export default InstallPWA;
