import { FabricJSEditor } from 'fabricjs-react';
import { Dispatch } from 'react';
import { IGenericSize } from '../../../app/auth/model/screenUtil';
import {
  proportionalResizeCanvas,
  resizeImageFromCanvasProportion,
  resizeImageToCanvasProportion,
} from '../../../utils/imgUtils';
import { eventiPin } from './fabricEvents';
import { IBackgroundScene } from './models/BackgroundScene';
import { IPinScene } from './models/PinScene';
import { NAVBAR_HEIGHT, SIDEBAR_WIDTH, TEXT_MARGIN_TOP } from './sizeUtils';
import { IPinFabric, positionText } from './useTextPin';

const screenUtils = (editor: FabricJSEditor | undefined) => {
  const screenCenter = editor?.canvas.getCenter();
  return {
    x: screenCenter?.left || 0,
    y: screenCenter?.top || 0,
  };
};

export default screenUtils;

export const onMovingPin = (
  obj: any,
  editor: FabricJSEditor,
  positionPopup: () => void
) => {
  positionText(editor, obj as IPinFabric);
  positionPopup();
};

export const afterScaledPin = (
  canvasWidth: number,
  obj: any,
  pinScene: IPinScene
) => {
  const sizeScaleImg = {
    width: resizeImageFromCanvasProportion(canvasWidth, obj.getScaledWidth()),
    height: resizeImageFromCanvasProportion(canvasWidth, obj.getScaledHeight()),
  };
  return {
    ...pinScene,
    image: { ...pinScene.image, iconSize: sizeScaleImg },
  };
};

export const afterMovedPin = (
  canvasWidth: number,
  obj: any,
  pinScene: IPinScene
) => {
  const newCenter = obj.getCenterPoint();
  const position = {
    x: newCenter.x,
    y: newCenter.y,
  };
  return {
    ...pinScene,
    position: {
      x: resizeImageFromCanvasProportion(canvasWidth, position.x),
      y: resizeImageFromCanvasProportion(canvasWidth, position.y),
    },
  };
};

export const setInitialOptionPin = (obj: any, pinScene: IPinScene) => {
  obj.set({
    originX: 'center',
    originY: 'center',
    id: pinScene.id_temp ? pinScene.id_temp : pinScene._id,
  });
  obj.objectCaching = false;
  obj.hoverCursor = 'pointer';
};

export const adaptPositionAndSize = (
  isEditMode: boolean,
  widthWindow: number,
  heightWindow: number,
  editor: FabricJSEditor,
  img: any,
  pinScene: IPinScene | IBackgroundScene
) => {
  const newDimensions = proportionalResizeCanvas({
    widthWindow: isEditMode ? widthWindow - SIDEBAR_WIDTH : widthWindow,
    heightWindow: heightWindow - NAVBAR_HEIGHT,
  });

  img.scaleToWidth(
    resizeImageToCanvasProportion(
      newDimensions.width,
      pinScene.image.iconSize.width
    )
  );
  img.setCoords();
  if (pinScene.position.x) {
    img.left = resizeImageToCanvasProportion(
      newDimensions.width,
      pinScene.position.x
    );
    img.top = resizeImageToCanvasProportion(
      newDimensions.width,
      pinScene.position.y
    );
    img.setCoords();
  } else {
    const centerCoords = editor.canvas.getCenter();
    img.left = centerCoords.left;
    img.top = centerCoords.top;
    img.setCoords();
  }
  editor.canvas.add(img);
};

export const resizeObjectInCanvas = (
  objects: any[],
  listPinScene: IPinScene[],
  newDimensions: IGenericSize,
  positionPopup: () => void,
  editor: FabricJSEditor,
  isEditMode: boolean,
  drag: boolean,
  dispatch: Dispatch<any>,
  navigateTo: (action: string, id: string, other: any) => void, //INavigate
  zoomLevel: any
) => {
  objects.forEach(obj => {
    const pin = listPinScene.find(ele => ele._id === obj.id);
    const pinTemp = listPinScene.find(ele => ele.id_temp === obj.id);
    obj.off('mousedown');
    obj.off('moved');
    obj.off('moving');
    obj.off('mousemove');
    obj.off('scaling');
    obj.off('scaled');
    obj.off('mouseup');
    const pinFound = pin || pinTemp;
    if (pinFound) {
      //se l'oggetto è un testo avrà valorizzata la proprietà name altrimenti se è un pin avrà un id
      if (obj.name) {
        const pinAssociatedToText = listPinScene.find(
          ele => ele._id === obj.name.split('objId_')[1]
        );
        if (pinAssociatedToText) {
          obj.left = resizeImageToCanvasProportion(
            newDimensions.width,
            pinAssociatedToText.position.x
          );
          obj.top =
            resizeImageToCanvasProportion(
              newDimensions.width,
              pinAssociatedToText.position.y +
                pinAssociatedToText.image.iconSize.height / 2
            ) + TEXT_MARGIN_TOP;
          obj.setCoords();
          positionPopup();
        }
      } else {
        obj.scaleToWidth(
          resizeImageToCanvasProportion(
            newDimensions.width * zoomLevel,
            pinFound.image.iconSize.width
          )
        );
        obj.left = resizeImageToCanvasProportion(
          newDimensions.width,
          pinFound.position.x
        );
        obj.top = resizeImageToCanvasProportion(
          newDimensions.width,
          pinFound.position.y
        );
        obj.setCoords();
        eventiPin(
          obj,
          pinFound,
          editor,
          isEditMode,
          positionPopup,
          drag,
          dispatch,
          navigateTo
        );
        positionPopup();
      }
    }
  });
};

export const setCoordinates = (editor?: FabricJSEditor) => {
  if (editor && editor.canvas) {
    editor.canvas.forEachObject(obj => obj.setCoords());
    let bgImg: any = editor?.canvas.backgroundImage;
    bgImg && bgImg.setCoords();
  }
};
