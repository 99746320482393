import { TFunction } from 'i18next';
import { FormElementModelNew } from '../../../../utils/forms/models/FormElementModel';
import { createStructureForm } from '../../../../utils/forms/useStructureForm';

function useWeblinkForm(t: TFunction, dataForm: any) {
  const dataStructure: FormElementModelNew[] = createStructureForm(
    [
      {
        typeElement: 'hidden',
        name: 'sceneId',
        dataElement: {
          type: 'text',
        },
      },
      {
        typeElement: 'input',
        label: t('APP.FORM.SITE_URL'),
        name: 'url',
        dataElement: {
          placeholder: t('APP.FORM.SITE_URL'),
          type: 'text',
        },
        col: { xs: 12 },
      },
      {
        typeElement: 'input',
        label: t('APP.FORM.WIDTH'),
        name: 'width',
        dataElement: {
          placeholder: t('APP.FORM.WIDTH'),
          type: 'text',
        },
        col: { xs: 4 },
      },
      {
        typeElement: 'input',
        label: t('APP.FORM.HEIGHT'),
        name: 'height',
        dataElement: {
          placeholder: t('APP.FORM.HEIGHT'),
          type: 'text',
        },
        col: { xs: 4 },
      },
    ],
    dataForm
  );
  return { dataStructure };
}

export default useWeblinkForm;
