import React from 'react';
interface IconProps extends React.HTMLAttributes<HTMLOrSVGElement> {
  icon: string;
  size?: string;
  color?: string;
  colorStyle?: string;
  disabled?: boolean;
  label?: string | React.ReactNode;
  clickAction?: () => void;
}

function getColor(color: string) {
  return color.startsWith('#') || color.startsWith('rgb')
    ? color
    : `var(--${color})`;
}

const Icon = ({
  icon,
  size = '',
  color = '',
  colorStyle = '',
  children,
  className = '',
  disabled = false,
  label = '',
  clickAction,
  ...rest
}: IconProps) => {
  const isDisabled = disabled ? 'disabled' : '';
  const styleColor = {
    '--color-icon-var': getColor(colorStyle),
  } as React.CSSProperties;
  const styleSize = { fontSize: size } as React.CSSProperties;

  return (
    <div
      onClick={() => !disabled && clickAction && clickAction()}
      className={`shared-icon ${className} ${
        clickAction && !disabled ? 'pointer' : ''
      }`}
      {...rest}
    >
      <span
        style={{
          ...styleColor,
          ...styleSize,
        }}
        className={`icon icon-${icon}${color} ${isDisabled}`}
      ></span>
      {label ? (
        <span className={`${isDisabled} iconLabel`} style={styleColor}>
          {label}
        </span>
      ) : null}
    </div>
  );
};

export default Icon;
