import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addErrorMessage,
  addSuccessMessage,
} from '../../../../../app/errors/errorSlice';
import { AppThunk, RootState } from '../../../../../app/store';
import i18n from '../../../../../config/i18n';
import { getAclByName } from '../aclManagement/service/acl.service';
import { ICheckItem } from './model/config';
import {
  addCheck,
  getAllChecks,
  getCheckByName,
  removeCheck,
  updateCheck,
} from './service/check.service';

export interface ICheckManagementSlice {
  listCheck: ICheckItem[];
  selectedCheck?: ICheckItem;
  checkToEdit?: ICheckItem;
  parametersList: string[];
  selectedCategoriesList: any[];
  nameList: any[];
}

const initialState: ICheckManagementSlice = {
  listCheck: [],
  selectedCheck: undefined,
  checkToEdit: undefined,
  parametersList: [],
  selectedCategoriesList: [],
  nameList: [],
};

export const checkManagementSlice = createSlice({
  name: 'checkManagement',
  initialState,
  reducers: {
    setListCheck: (state, action: PayloadAction<ICheckItem[]>) => {
      state.listCheck = action.payload;
    },
    setSelectedCheck: (state, action: PayloadAction<ICheckItem>) => {
      state.selectedCheck = action.payload;
    },
    setCheckToEdit: (state, action: PayloadAction<ICheckItem>) => {
      state.checkToEdit = action.payload;
    },
    setParametersList: (state, action: PayloadAction<string[]>) => {
      state.parametersList = action.payload;
    },
    setSelectedCategoriesList: (state, action: PayloadAction<any[]>) => {
      state.selectedCategoriesList = action.payload;
    },
    setListName: (state, action: PayloadAction<any[]>) => {
      state.nameList = action.payload;
    },
    resetListCheck: state => {
      state.listCheck = [];
    },
    resetSelectedCheck: state => {
      state.selectedCheck = undefined;
    },
    resetCheckToEdit: state => {
      state.checkToEdit = undefined;
    },
    resetParametersList: state => {
      state.parametersList = [];
    },
    resetSelectedCategoriesList: state => {
      state.selectedCategoriesList = [];
    },
    resetNameList: state => {
      state.nameList = [];
    },
    addCheckToList: (state, action: PayloadAction<ICheckItem>) => {
      state.listCheck = [...state.listCheck, action.payload];
    },
    addParameterToList: (state, action: PayloadAction<string>) => {
      state.parametersList = [...state.parametersList, action.payload];
    },
    addSelectedCategoryToList: (state, action: PayloadAction<any>) => {
      state.selectedCategoriesList = [
        ...state.selectedCategoriesList,
        action.payload,
      ];
    },
    removeCheckFromList: (state, action: PayloadAction<string>) => {
      state.listCheck = [
        ...state.listCheck.filter(
          (check: ICheckItem) => check._id !== action.payload
        ),
      ];
    },
    removeParameterFromList: (state, action: PayloadAction<string>) => {
      state.parametersList = [
        ...state.parametersList.filter(
          (parameter: string) => parameter !== action.payload
        ),
      ];
    },
    removeSelectedCategoryFromList: (state, action: PayloadAction<any>) => {
      state.selectedCategoriesList = [
        ...state.selectedCategoriesList.filter(
          (category: any) => category !== action.payload
        ),
      ];
    },
    editCheckFromList: (state, action: PayloadAction<ICheckItem>) => {
      state.listCheck = [
        ...state.listCheck.filter(
          (acl: ICheckItem) => acl._id !== action.payload._id
        ),
        action.payload,
      ];
    },
  },
});

export const {
  setListCheck,
  setSelectedCheck,
  setCheckToEdit,
  setParametersList,
  setSelectedCategoriesList,
  setListName,
  resetListCheck,
  resetSelectedCheck,
  resetCheckToEdit,
  resetParametersList,
  resetSelectedCategoriesList,
  resetNameList,
  addCheckToList,
  addParameterToList,
  addSelectedCategoryToList,
  removeCheckFromList,
  removeParameterFromList,
  removeSelectedCategoryFromList,
  editCheckFromList,
} = checkManagementSlice.actions;

export const getCheckList = (): AppThunk => dispatch => {
  getAllChecks().then(
    res => {
      dispatch(setListCheck(res));
    },
    err =>
      dispatch(
        addErrorMessage({
          title: i18n.t('ERRORSMSG.LOADINGCHECKS'),
        })
      )
  );
};

export const searchCheckByName =
  (idCat?: number, name?: string): AppThunk =>
  dispatch => {
    getCheckByName(idCat, name).then(
      res => {
        dispatch(setListCheck(res));
      },
      err =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.SEARCHCHECK'),
          })
        )
    );
  };

export const deleteCheck =
  (idCheckToRemove: string): AppThunk =>
  dispatch => {
    removeCheck(idCheckToRemove).then(
      res => {
        dispatch(removeCheckFromList(idCheckToRemove));
        dispatch(
          addSuccessMessage({
            title: i18n.t('SUCCESSMSG.DELETECHECK'),
          })
        );
      },
      err =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.DELETECHECK'),
          })
        )
    );
  };

export const resetCheckForm = (): AppThunk => dispatch => {
  dispatch(resetCheckToEdit());
  dispatch(resetParametersList());
  dispatch(resetSelectedCategoriesList());
  dispatch(resetNameList());
};

export const editCheckAction =
  (data: ICheckItem, callBack: () => void): AppThunk =>
  dispatch => {
    data._id &&
      updateCheck(data._id, data).then(
        res => {
          dispatch(editCheckFromList(data));
          callBack && callBack();
          dispatch(resetCheckForm());
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.INSERTCHECK'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.INSERTCHECK'),
            })
          )
      );
  };

export const getNamesList =
  (name: string): AppThunk =>
  dispatch => {
    getAclByName(name).then(
      res => {
        dispatch(setListName(res));
      },
      err =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.LOADINGNAMES'),
          })
        )
    );
  };

export const createCheckList =
  (data: ICheckItem, callBack: () => void): AppThunk =>
  (dispatch, getState) => {
    addCheck(data).then(
      res => {
        data._id = res._id;
        dispatch(addCheckToList(data));
        callBack && callBack();
        dispatch(resetCheckForm());
        dispatch(
          addSuccessMessage({
            title: i18n.t('SUCCESSMSG.INSERTCHECK'),
          })
        );
      },
      err =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.INSERTCHECK'),
          })
        )
    );
  };

export const selectListCheck = (state: RootState) =>
  state.checkManagement.listCheck;
export const selectSelectedCheck = (state: RootState) =>
  state.checkManagement.selectedCheck;
export const selectCheckToEdit = (state: RootState) =>
  state.checkManagement.checkToEdit;
export const selectListParameters = (state: RootState) =>
  state.checkManagement.parametersList;
export const selectSelectedListCategories = (state: RootState) =>
  state.checkManagement.selectedCategoriesList;
export const selectNameList = (state: RootState) =>
  state.checkManagement.nameList;

export default checkManagementSlice.reducer;
