import omit from 'lodash.omit';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { addErrorMessage } from '../../../app/errors/errorSlice';
import ToolbarButton from '../../../components/shared/custom/toolbar/toolbarButton/ToolbarButton';
import { useRoute } from '../../../utils/routes/useRoute';
import ContentPageLayoutV2 from '../ContentPageLayoutV2';
import { getCategory } from '../menu/menuLevels/categoryManagement/services/category.service';
import { getUserPreference } from '../menu/menuLevels/userManagement/services/userpreference.service';
import { UserPreference } from '../menu/menuLevels/userManagement/types';
import { ICategory } from '../models/Categories';
import ReportsAccordion from './components/ReportsAccordion';
import ReportsForm, { ReportsFormType } from './components/ReportsForm';
import ReportTable from './components/ReportsTable';
import GameReportLayout from './GameReportLayout';
import { retriveAll } from './reports.service';
import { GameQuestionsStat, SkillsScoreList, Warning } from './types';
import { setOmitFields, setValue } from './utils';

function GameReport() {
  const { t } = useTranslation();
  const { goBack } = useRoute();
  const dispatch = useDispatch();
  const [dataList, setDataList] = useState<
    {
      name: string;
      value: any;
    }[]
  >();
  const [categories, setCategories] = useState<ICategory[]>();
  const [users, setUsers] = useState<UserPreference[]>([]);
  const fields: { [key in string]: string } = {
    gameId: t('GAME.LABEL'),
    categoryId: t('CATEGORY.LABEL'),
    gameType: t('GAMEREPORT.TYPE'),
    totLifes: t('GAMEREPORT.TOTALLIFE'),
    totObtainedPoints: t('GAMEREPORT.TOTOBTAINEDPOINTS'),
    totObtainedLifes: t('GAMEREPORT.TOTOBTAINEDLIFES'),
    minResTimeServer: t('GAMEREPORT.MINRESTIMESERVER'),
    maxResTimeServer: t('GAMEREPORT.MAXRESTIMESERVER'),
    resTimeServerAvg: t('GAMEREPORT.AVGRESTIMESERVER'),
    minResTimeClient: t('GAMEREPORT.MINRESTIMECLIENT'),
    maxResTimeClient: t('GAMEREPORT.MAXRESTIMECLIENT'),
    resTimeClientAvg: t('GAMEREPORT.AVGRESTIMECLIENT'),
    totalScore: t('GAME.TOTALSCORE'),
    totalSkillsScore: t('GAMEREPORT.TOTSKILLSCORE'),
    skillsScoreList: t('GAMEREPORT.SKILLSCORELIST'),
    gameQuestionsStat: t('GAMEREPORT.GAMEQUESTIONSTAT'),
    warnings: t('GAMEREPORT.WARNINGS'),
    lifes: t('GAME.LIFES'),
    score: t('GAME.SCORE'),
    skill: t('GAMEMANAGEMENT.SKILLNAME'),
    message: t('GAMEREPORT.MESSAGE'),
    error: 'Errore',
  };

  useEffect(() => {
    loadCategories();
    loadUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function loadCategories() {
    getCategory({ depth: 1 })
      .then(res => setCategories([...(res as ICategory[])]))
      .catch(() =>
        dispatch(
          addErrorMessage({
            title: t('ERRORSMSG.LOAD_CATEGORIES'),
          })
        )
      );
  }

  function loadUsers() {
    getUserPreference('')
      .then(res => setUsers([...res]))
      .catch(() =>
        dispatch(
          addErrorMessage({
            title: t('ERRORSMSG.LOAD_USER_PREFERENCE'),
          })
        )
      );
  }

  function handleLoadReports(data: ReportsFormType) {
    retriveAll(data.categoryId, data.depth, data.username)
      .then(res => {
        const dataView = omit(res, setOmitFields(res.gameType));
        //costruisco il dataList
        const dataList = Object.entries(dataView).map(
          ([key, value], index) => ({
            name: fields[key],
            value: setCard(key, value, index),
          })
        );
        setDataList(dataList);
      })
      .catch(err => {
        setDataList([]);
        console.log('Error', err.message);
      });
  }

  function setCard(key: string, value: any, index: number) {
    const name = fields[key];
    if (typeof value === 'object' && value !== null) {
      return (
        <ReportsAccordion
          title={name}
          data={value as GameQuestionsStat[] | SkillsScoreList[] | Warning[]}
          fields={fields}
          index={index}
          key={`report-acc-${index}`}
          categories={categories}
        />
      );
    } else {
      return (
        <Card
          className={`row-gamereport-item ${
            index % 2 > 0 ? 'bgwhite' : 'bggrey'
          }`}
          key={`report-card-${index}`}
        >
          <Container key={`container-${index}`}>
            <Row
              className="display-inline-block"
              key={`container-row-${index}`}
            >
              <Col style={{ textAlign: 'start' }}>{name}</Col>
              <Col style={{ textAlign: 'end' }}>
                <b>{setValue(value, key, categories)}</b>
              </Col>
            </Row>
          </Container>
        </Card>
      );
    }
  }

  function bottomToolbarRightTemplate() {
    return (
      <div>
        <ToolbarButton clickAction={goBack} icon="exit_to_app" />
      </div>
    );
  }

  function menuSearchReport() {
    return (
      <>
        {categories && (
          <ReportsForm
            categories={categories}
            users={users}
            onSubmit={(e, data) => {
              handleLoadReports(data);
            }}
          />
        )}
      </>
    );
  }

  function resultTable() {
    return dataList ? (
      <>
        <h3 className="mb-5">
          Engage Soft Skill: {t('GAMEREPORT.RESULTTITLE')}
        </h3>
        {dataList.length > 0 && <ReportTable list={dataList} />}
        {dataList.length === 0 && <b>{t('APP.NORESULT')}</b>}
      </>
    ) : (
      <></>
    );
  }
  return (
    <>
      <ContentPageLayoutV2
        isEditMode={false}
        fullScreen
        toolbarRightTemplates={{
          bottom: bottomToolbarRightTemplate(),
        }}
        contentLayout={
          <GameReportLayout
            searchForm={menuSearchReport()}
            resultTable={resultTable()}
          />
        }
      />
    </>
  );
}

export default GameReport;
