import omit from 'lodash.omit';
import http from '../../../../utils/http';
import { IIdResponse } from '../../fabricJs/models/servicesResponse';
import { IBackgroundComments } from '../models/backgroundComments';

const urlComments = '/messages';

export const postMessage = (idCat: number, message: string): Promise<any> =>
  http.post(`${urlComments}/${idCat}/comment`, { message: message });

/* BACKGROUND */

export const getBgCommentsById = (id: string): Promise<any> =>
  http.get(`${urlComments}/${id}/backgrounds`);

export const deleteBgForComments = (
  bg: IBackgroundComments,
  idComments: string
): Promise<IIdResponse> => {
  return http.delete(`${urlComments}/${idComments}/backgrounds/${bg._id}`);
};

export const saveBgForComments = (
  bg: IBackgroundComments,
  idComments: string
): Promise<IIdResponse> => {
  const newBg = omit(bg, ['id', 'id_temp']);
  return http.post(`${urlComments}/${idComments}/backgrounds`, newBg);
};

export const editBgForComments = (
  bg: IBackgroundComments,
  idComments: string
): Promise<IIdResponse> => {
  const newBg = omit(bg, ['id', '_id', 'id_temp', 'gameId']);
  return http.put(`${urlComments}/${idComments}/backgrounds/${bg._id}`, newBg);
};
