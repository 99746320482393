import React from 'react';
import { ProgressBar } from 'react-bootstrap';
import Input from '../input/Input';

interface ProgressProps {
  className?: string;
  value: number;
  changeProgress: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Progress = ({ value = 0, changeProgress, ...rest }: ProgressProps) => {
  return (
    <div className="shared-progress">
      <ProgressBar now={value} />
      <Input
        type="text"
        className="inputProgress"
        value={value}
        onChange={changeProgress}
        {...rest}
      ></Input>
    </div>
  );
};

export default Progress;
