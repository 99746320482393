import omit from 'lodash.omit';
import http from '../../../../../../utils/http';
import { ICategory } from '../../../../models/Categories';

const urlcategory = '/categories';

export const getCategory = ({
  idCat,
  depth,
}: {
  idCat?: string | number;
  depth?: string | number;
}): Promise<ICategory | ICategory[]> => {
  return http.get(
    `${urlcategory}${idCat !== undefined ? `/${idCat}` : '/'}${
      depth ? `?depth=${depth}` : ''
    }`
  );
};

export const getAllCategories = (): Promise<ICategory[]> => {
  return http.get(`${urlcategory}`);
};

export const addCategory = (data: ICategory): Promise<ICategory> => {
  return http.post(`${urlcategory}`, data);
};

export const editCategory = (data: any): Promise<ICategory> => {
  let editedCategory: Partial<ICategory> = {
    id: 0,
    name: 'string',
    description: 'string',
    depth: 0,
    game_id: 'string',
    id_category: 0,
    tutor_email: 'string',
  };

  for (const key of Object.keys(data)) {
    editedCategory = {
      ...editedCategory,
      [key]:
        data[key] === undefined || data[key] === null || data[key] === ''
          ? undefined
          : data[key],
    };
  }
  const dataPatch =
    editedCategory.id === 0
      ? omit(editedCategory, ['father', 'children', 'id_category'])
      : omit(editedCategory, ['father', 'children']);
  return http.patch(`${urlcategory}/${dataPatch.id}`, dataPatch);
};

export const deleteCategory = (idCat: number): Promise<ICategory> =>
  http.delete(`${urlcategory}/${idCat}`);
