import { Public } from '@iad-os/react-ghost-auth';
import React, { useEffect, useState } from 'react';
import { seti18nPublic } from '../../config/i18nPublic';
import publicRoutes from '../../routes/public';
import DynamicRoute from './DynamicRoute';

const PublicRoutes = () => {
  const [loadi18nPublic, setLoadI18nPublic] = useState(false);

  useEffect(() => {
    seti18nPublic(setLoadI18nPublic);
  }, []);

  return (
    <Public>
      {loadi18nPublic && (
        <div className="public">
          <DynamicRoute routes={publicRoutes} />
        </div>
      )}
    </Public>
  );
};

export default PublicRoutes;
