import isEqual from 'lodash.isequal';
import omit from 'lodash.omit';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IBtnList } from '../../../../../app/auth/model/BtnList';
import AnimationFrame from '../../../../../components/shared/animation-frame/AnimationFrame';
import ToolbarBtn from '../../../../../components/shared/custom/sidebar/toolbarBtn/ToolbarBtn';
import ModalCustom from '../../../../../components/shared/design/modal/Modal';
import useGameHeaderForm from '../../../../../components/shared/game-header/useGameHeaderForm';
import FormElementOnlyOne from '../../../../../utils/forms/FormElementOnlyOne';
import FormElements from '../../../../../utils/forms/FormElements';
import { UseFormSubmitModel } from '../../../../../utils/forms/models/UseFormModel';
import { useForm } from '../../../../../utils/forms/useForm';
import {
  getObjForm,
  setObjForm,
} from '../../../../../utils/forms/useStructureForm';
import { processFile } from '../../../../../utils/readerFile';
import { ICategory } from '../../../models/Categories';
import { deleteFile, uploadUnzip } from '../../../services/upload.service';
import { AnimateData, AnimationType } from '../../models/types';
import useAnimationForm, { Animate2PlayInfo } from '../useAnimationForm';
import { validations } from './ValidationData';

function AnimationForm({
  animateData,
  onChangeDataForm,
  isBtnClicked,
  toolbarData,
  onToolbarAction,
  categories,
}: {
  animateData: AnimateData;
  onChangeDataForm: (data: AnimateData) => void;
  isBtnClicked: boolean;
  toolbarData: {
    left: IBtnList[];
    right: IBtnList[];
  };
  onToolbarAction: (action: string) => void;
  categories: ICategory[];
}) {
  const { t } = useTranslation();
  const [fileErrors, setErrors] = useState<{ [key: string]: any }>({});
  const [modalState, setModalState] = useState<{
    show: boolean;
    data?: Animate2PlayInfo;
  }>({
    show: false,
    data: undefined,
  });

  const submitHandler: UseFormSubmitModel = (event, dataSubmit: any) => {
    onToolbarAction('save');
  };
  const validationsData = validations();

  const form = useForm(submitHandler, validationsData);

  const handleOnDropZip = (
    files: File[],
    nameDrop: string,
    formName: string
  ) => {
    return new Promise((resolve, reject) => {
      if (files[0]) {
        const pathName = 'animates';
        processFile(files[0])
          .then((res: any) => {
            let formData = new FormData();
            formData.append(files[0].name, files[0]);
            return uploadUnzip(formData, {
              isPublic: 'true',
              pathName,
            });
          })
          .then((result: any) => {
            changeValue({
              name: formName,
              value: result.uri,
            });

            resolve(result);
          })
          .catch(err => reject(err));
      } else {
        setErrors({ ...fileErrors, [nameDrop]: { isNotValid: true } });
        reject();
      }
    });
  };

  const handleDeleteZip = (fileName: string, formName: string) => {
    const documentName = fileName;
    documentName &&
      deleteFile(documentName).then(res => {
        changeValue({
          name: formName,
          value: '',
        });
        console.log(res);
      });
  };

  const {
    isSubmit,
    errors,
    submitAction,
    changeValue,
    dataForm,
    setData,
    deleteElementByArray,
    dataFormatted,
  } = form;

  const { dataStructure } = useAnimationForm(
    t,
    dataForm,
    [],
    errors,
    handleOnDropZip,
    handleDeleteZip,
    handlePlayAnimate
  );

  const { gameHeaderDataStructure } = useGameHeaderForm(
    t,
    dataForm,
    dataFormatted,
    categories
  );

  useEffect(() => {
    const toForm = setObjForm(animateData);
    if (!isEqual(toForm, dataForm)) {
      setData(toForm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [animateData]);

  useEffect(() => {
    const toObj: AnimateData = getObjForm(dataForm);
    const fixedObj = {
      ...omit(getObjForm(dataForm), ['animates']),
      animates: toObj?.animates?.map((item: AnimationType) => {
        return {
          ...item,
          autostart: item.autostart ? item.autostart : false,
          withSound: item.withSound ? item.withSound : false,
        };
      }),
    };

    if (!isEqual(animateData, fixedObj) && dataFormCheck(fixedObj)) {
      onChangeDataForm(fixedObj as AnimateData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm]);

  function dataFormCheck(dataForm: any) {
    const check = dataForm.animates?.reduce(
      (acc: boolean, curr: AnimationType) =>
        acc && curr.autostart !== undefined && curr.withSound !== undefined,
      true
    );
    return check;
  }

  function handlePlayAnimate(animate: Animate2PlayInfo) {
    setModalState({ show: true, data: animate });
  }

  return (
    <>
      <ToolbarBtn
        btnListLeft={toolbarData.left}
        btnListRight={toolbarData.right}
        clickAction={(action: string) => {
          switch (action) {
            case 'save':
              submitAction();
              break;
            default:
              onToolbarAction(action);
          }
        }}
      />
      <Form onSubmit={submitAction}>
        <FormElements
          data={dataStructure.parent}
          dataFormatted={dataFormatted}
          errors={errors}
          isSubmit={isSubmit}
          dataForm={dataForm}
          changeValue={changeValue} //{handleDataFormChange}
          deleteElementByArray={deleteElementByArray}
        />
        <div style={{ marginBottom: '12px' }}>
          <FormElementOnlyOne
            data={gameHeaderDataStructure}
            dataFormatted={dataFormatted}
            errors={errors}
            isSubmit={isSubmit}
            dataForm={dataForm}
            changeValue={changeValue} //{handleDataFormChange}
            deleteElementByArray={deleteElementByArray}
          />
        </div>
        <FormElements
          data={dataStructure.animates}
          dataFormatted={dataFormatted}
          errors={errors}
          isSubmit={isSubmit}
          dataForm={dataForm}
          changeValue={changeValue} //{handleDataFormChange}
          deleteElementByArray={deleteElementByArray}
        />
      </Form>
      <ModalCustom
        size="xl"
        show={modalState.show && !!modalState.data && !!modalState.data.uri}
        onHide={() => setModalState({ show: false })}
        headerTemplate={
          <>{`${modalState.data?.episodio} @ ${modalState.data?.name} - ${modalState.data?.progressive}`}</>
        }
        bodyTemplate={
          <AnimationFrame
            title={'animation'}
            src={modalState.data?.uri || ''}
          />
        }
      />
    </>
  );
}

export default AnimationForm;
