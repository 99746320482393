import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from '../config/i18n';
import { getMicroCategoryByFatherId } from '../features/auth/game/microGame/services/listgame.service';
import {
  getAllCategories,
  getCategory,
} from '../features/auth/menu/menuLevels/categoryManagement/services/category.service';
import { ICategory } from '../features/auth/models/Categories';
import { createCategoriesOption } from '../utils/categoryUtils';
import { OptionSelect } from '../utils/forms/models/FormElementModel';
import { getListMicroGameByArea } from './../features/auth/game/microGame/services/listgame.service';
import { addErrorMessage } from './errors/errorSlice';
import { AppThunk, RootState } from './store';

export interface CategoriesSlice {
  categories: ICategory[];
  depthCategories: ICategory | undefined;
  categoriesOption: Partial<OptionSelect[]>;
  categorySelected: string | undefined;
  subCategorySelected: string | undefined;
  subCategoriesList: ICategory[];
  microgameList: any[];
}
const initialState: CategoriesSlice = {
  categories: [],
  depthCategories: undefined,
  categoriesOption: [],
  categorySelected: undefined,
  subCategorySelected: undefined,
  subCategoriesList: [],
  microgameList: [],
};

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<ICategory[]>) => {
      state.categories = action.payload;
    },
    setDepthCategories: (state, action: PayloadAction<ICategory>) => {
      state.depthCategories = action.payload;
    },
    setCategoriesOption: (state, action: PayloadAction<ICategory[]>) => {
      state.categoriesOption = createCategoriesOption(action.payload);
    },
    setCategorySelected: (state, action: PayloadAction<string>) => {
      state.categorySelected = action.payload;
    },
    setSubCategorySelected: (state, action: PayloadAction<string>) => {
      state.subCategorySelected = action.payload;
    },
    setSubCategoriesList: (state, action: PayloadAction<ICategory[]>) => {
      state.subCategoriesList = action.payload;
    },
    setMicrogameList: (state, action: PayloadAction<any[]>) => {
      state.microgameList = action.payload;
    },
    resetCategories: state => {
      state.categories = [];
    },
    resetCategorySelected: state => {
      state.categoriesOption = [];
    },
    resetSubCategorySelected: state => {
      state.subCategorySelected = undefined;
    },
    resetSubCategoriesList: state => {
      state.subCategoriesList = [];
    },
    resetMicrogameList: state => {
      state.microgameList = [];
    },
  },
});
export const {
  setCategories,
  setDepthCategories,
  resetCategories,
  setCategoriesOption,
  setCategorySelected,
  setSubCategorySelected,
  setSubCategoriesList,
  setMicrogameList,
  resetCategorySelected,
  resetSubCategorySelected,
  resetSubCategoriesList,
  resetMicrogameList,
} = categoriesSlice.actions;

export const resetAllCategories = (): AppThunk => dispatch => {
  dispatch(resetCategorySelected());
  dispatch(resetSubCategorySelected());
  dispatch(resetSubCategoriesList());
  dispatch(resetMicrogameList());
};

export const getListCategories = (): AppThunk => dispatch => {
  getAllCategories().then(
    res => {
      dispatch(setCategories(res));
    },
    err =>
      dispatch(
        addErrorMessage({
          title: i18n.t('ERRORSMSG.CATEGORY'),
        })
      )
  );
};

export const getDepthCategories = (): AppThunk => dispatch => {
  getCategory({ idCat: 0, depth: 'max' }).then(
    res => {
      dispatch(setDepthCategories(res as ICategory));
    },
    err =>
      dispatch(
        addErrorMessage({
          title: i18n.t('ERRORSMSG.CATEGORY'),
        })
      )
  );
};

export const setSubCategoriesListFromFather =
  (idCat: string): AppThunk =>
  dispatch => {
    getCategory({ idCat, depth: 'max' }).then(_res => {
      const res = _res as ICategory;
      res.children && dispatch(setSubCategoriesList(res.children));
    });
  };

export const setMicrogameListFromFather =
  (idCat: string): AppThunk =>
  (dispatch, getState) => {
    const subcat = getState().categories.subCategorySelected;
    getMicroCategoryByFatherId(idCat).then(res => {
      if (subcat) {
        const areaGame = res.find(
          (ele: any) => ele.areaCategoryId === +subcat
        )?.areaId;
        areaGame &&
          getListMicroGameByArea(areaGame).then(res => {
            dispatch(setMicrogameList(res[0].microgameList));
          });
      }
    });
  };

export const selectCategories = (state: RootState) =>
  state.categories.categories;

export const selectCategoriesOption = (state: RootState) =>
  state.categories.categoriesOption;

export const selectCategorySelected = (state: RootState) =>
  state.categories.categorySelected;

export const selectSubCategorySelected = (state: RootState) =>
  state.categories.subCategorySelected;

export const selectSubCategoriesList = (state: RootState) =>
  state.categories.subCategoriesList;

export const selectMicrogameList = (state: RootState) =>
  state.categories.microgameList;

export default categoriesSlice.reducer;
