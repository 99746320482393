import omit from 'lodash.omit';
import http from '../../../../../../utils/http';
import { AclItem } from '../model/config';

const acl = '/acl';
const httpCode = '/httpcodelist';

export const getAclById = (idACl: string): Promise<AclItem> =>
  http.get(`${acl}/function/${idACl}`);

export const getAclByName = (name: string): Promise<AclItem[]> =>
  http.get(`${acl}/function/?name=${name}`);

export const getAllAcl = (): Promise<AclItem[]> => http.get(`${acl}/function`);

export const addAcl = (newAcl: AclItem): Promise<any> =>
  http.post(`${acl}/function`, newAcl);

export const updateAcl = (idAcl: string, editedAcl: AclItem): Promise<any> => {
  const editAcl = omit(editedAcl, ['_id', '__v', 'id']);
  return http.put(`${acl}/function/${idAcl}`, editAcl);
};

export const removeAcl = (idAcl: string): Promise<any> =>
  http.delete(`${acl}/function/${idAcl}`);

export const getHttpCodes = (): Promise<any> => http.get(`${httpCode}`);
