import { useSelector } from 'react-redux';
import { selectUserPermissions } from '../../app/auth/authSlice';
import { issetPermissions } from '../../app/rolePermissions';

export function usePermissions() {
  const permissions = useSelector(selectUserPermissions);
  const issetPermission = (permissionList: string[], or: boolean = false) => {
    return issetPermissions(permissions, permissionList, or);
  };
  return {
    issetPermission,
  };
}
