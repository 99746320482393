import { FabricJSEditor } from 'fabricjs-react';
import { Dispatch } from 'react';
import { IPinScene } from './models/PinScene';
import { listTabNameScene } from './sceneData';
import {
  editSelectedPin,
  selectedPinSceneById,
  setTabsKeyScene,
} from './sceneSlice';
import { afterMovedPin, afterScaledPin, onMovingPin } from './screenUtils';
import { IPinFabric, positionText } from './useTextPin';

export const eventiPin = (
  obj: any,
  pinScene: IPinScene,
  editor: FabricJSEditor,
  isEditMode: boolean,
  positionPopup: () => void,
  drag: boolean,
  dispatch: Dispatch<any>,
  navigateTo: (action: string, id: string, other?: any) => void
) => {
  obj.on('moved', (e: fabric.IEvent) => {
    isEditMode &&
      editor.canvas &&
      editor.canvas.width &&
      dispatch(
        editSelectedPin(afterMovedPin(editor.canvas.width, obj, pinScene))
      );
  });
  obj.on('moving', (e: fabric.IEvent) => {
    obj.lockMovementX = !isEditMode;
    obj.lockMovementY = !isEditMode;
    isEditMode && onMovingPin(obj, editor, positionPopup);
  });
  obj.on('mousemove', (e: fabric.IEvent) => {
    drag = true;
  });
  obj.on('scaling', (e: fabric.IEvent) => {
    positionText(editor, obj as IPinFabric);
    positionPopup();
  });
  obj.on('scaled', (e: fabric.IEvent) => {
    obj.width &&
      obj.height &&
      editor.canvas.width &&
      dispatch(
        editSelectedPin(afterScaledPin(editor.canvas.width, obj, pinScene))
      );
  });
  obj.on('mousedown', (e: fabric.IEvent) => {
    drag = false;
    if (isEditMode) {
      editor.canvas.setActiveObject(obj);
      dispatch(setTabsKeyScene(listTabNameScene.pinScene));
    } else {
      /* const checkIdParams =
        (pinScene && pinScene?.action === navigationActionsName.goToMicroGame
          ? pinScene.resourceId
          : pinScene.subItemId?.toString()) || ''; */
      pinScene.subItemId !== undefined &&
        navigateTo(pinScene.action, pinScene.subItemId, {
          microgameId: pinScene.resourceId,
          resourceId: pinScene.resourceId,
          areaId: pinScene.areaId,
        });
    }
  });
  obj.on('mouseup', (e: fabric.IEvent) => {
    const target: any = { ...e.target };
    !drag &&
      target &&
      target.id &&
      isEditMode &&
      dispatch(selectedPinSceneById(target.id));
  });
};
