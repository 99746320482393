import { ILayoutGame } from './../models/layoutGame';
import omit from 'lodash.omit';
import http from '../../../../../utils/http';
import { IIdResponse } from '../../../fabricJs/models/servicesResponse';
import { IBackgroundGame } from '../models/backgroundGame';

const urlGameLayout = `/games`;

export const getBgForGame = (idGame: string): Promise<any[]> => {
  return http.get(`${urlGameLayout}/${idGame}/backgrounds`);
};
export const saveBgForGame = (
  bg: IBackgroundGame,
  idGame: string
): Promise<IIdResponse> => {
  const newBg = omit(bg, ['id', 'id_temp']);
  return http.post(`${urlGameLayout}/${idGame}/backgrounds`, newBg);
};
export const editBgForGame = (
  bg: IBackgroundGame,
  idGame: string
): Promise<IIdResponse> => {
  const newBg = omit(bg, ['id', '_id', 'id_temp', 'gameId']);
  return http.put(`${urlGameLayout}/${idGame}/backgrounds/${bg._id}`, newBg);
};
export const deleteBgForGame = (
  bg: IBackgroundGame,
  idGame: string
): Promise<IIdResponse> => {
  return http.delete(`${urlGameLayout}/${idGame}/backgrounds/${bg._id}`);
};

export const getLayoutForGame = (idGame: string): Promise<any[]> => {
  return http.get(`${urlGameLayout}/${idGame}/layouts`);
};
export const saveLayoutForGame = (
  layout: ILayoutGame,
  idGame: string
): Promise<IIdResponse> => {
  const newLayout = omit(layout, ['gameId', 'id_temp']);
  return http.post(`${urlGameLayout}/${idGame}/layouts`, newLayout);
};

export const editLayoutForGame = (
  layout: ILayoutGame,
  idGame: string
): Promise<IIdResponse> => {
  const newLayout = omit(layout, ['_id', 'gameId']);
  return http.put(
    `${urlGameLayout}/${idGame}/layouts/${layout._id}`,
    newLayout
  );
};
export const deleteLayoutForGame = (
  layout: ILayoutGame,
  idGame: string
): Promise<IIdResponse> => {
  return http.delete(`${urlGameLayout}/${idGame}/layouts/${layout._id}`);
};
