import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import i18n from '../config/i18n';
import {
  IPositionCoords,
  IPositionCoordsBe,
} from '../features/auth/models/Position';
import {
  addPositionCoords,
  createPositionCoords,
  getPositionCoords,
  patchPositionCoords,
} from '../features/auth/services/positionUser.service';
import { isFull } from './../utils/objUtils';
import { addErrorMessage } from './errors/errorSlice';
import { AppThunk, RootState } from './store';

export interface PositionUserSlice {
  positionUser: IPositionCoordsBe | undefined;
}
const initialState: PositionUserSlice = {
  positionUser: undefined,
};

export const positionUserSlice = createSlice({
  name: 'positionUser',
  initialState,
  reducers: {
    setPositionUser: (state, action: PayloadAction<IPositionCoordsBe>) => {
      state.positionUser = action.payload;
    },
    resetPositionUser: state => {
      state.positionUser = undefined;
    },
  },
});
export const { setPositionUser, resetPositionUser } = positionUserSlice.actions;

export const getPositionUser =
  (catId: string, pos?: IPositionCoords): AppThunk =>
  (dispatch, getState) => {
    getPositionCoords(catId).then(
      res => {
        if (!isFull(res) && pos) {
          return dispatch(
            addPositionUser({
              name: catId,
              x: pos.x,
              y: pos.y,
              z: pos.z,
            })
          );
        }
        dispatch(setPositionUser(res));
      },
      err => {
        pos &&
          dispatch(
            createPositionUser({
              name: catId,
              x: pos.x,
              y: pos.y,
              z: pos.z,
            })
          );
      }
    );
  };

export const addPositionUser =
  (position: IPositionCoordsBe): AppThunk =>
  (dispatch, getState) => {
    addPositionCoords(position).then(
      res => {
        //console.log("coordinate salvate");
      },
      err => {
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.FETCHPOSITIONUSER'),
          })
        );
      }
    );
  };
export const patchPositionUser =
  (position: IPositionCoordsBe): AppThunk =>
  (dispatch, getState) => {
    patchPositionCoords(position).then(
      res => {
        //console.log("coordinate salvate");
      },
      err => {
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.MODIFYPOSITIONUSER'),
          })
        );
      }
    );
  };

export const createPositionUser =
  (position: IPositionCoordsBe): AppThunk =>
  (dispatch, getState) => {
    createPositionCoords(position).then(
      res => {
        //console.log("create - coordinate salvate");
      },
      err => {
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.MODIFYPOSITIONUSER'),
          })
        );
      }
    );
  };

export const selectPositionUser = (state: RootState) =>
  state.positionUser.positionUser;

export default positionUserSlice.reducer;
