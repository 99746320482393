import { faUser } from '@fortawesome/free-solid-svg-icons';
import { useAuthentication } from '@iad-os/react-ghost-auth';
import React, { useEffect, useState } from 'react';
import { Container, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getUserLoggedIn, selectUser } from '../../../../app/auth/authSlice';
import i18n from '../../../../config/i18n';
import ContentPageLayoutV2 from '../../../../features/auth/ContentPageLayoutV2';
import {
  selectDropdownSelectableLangs,
  setDropdownSelectableLangs,
} from '../../../../features/auth/menu/menuLevels/languagesManagement/languagesManagementSlice';
import { getLanguages } from '../../../../features/auth/menu/menuLevels/languagesManagement/services/languages.service';
import { editUserPreferenceOnline } from '../../../../features/auth/menu/menuLevels/userManagement/services/userpreference.service';
import { selectMenuStatus } from '../../../../features/auth/menu/menuSlice';
import { menu } from '../../../../routes/routeNameAuthType';
import { useRoute } from '../../../../utils/routes/useRoute';
import ToolbarButton from '../../../shared/custom/toolbar/toolbarButton/ToolbarButton';
import LanguageSelector from '../../../shared/languageSelector/LanguageSelector';
import { setModal } from '../../../shared/redux/modal/modalSlice';
import FooterLayout from './FooterLayout';
import HeaderLayout from './HeaderLayout';
import useLogo from '../../../../hooks/useLogo';

interface AuthLayoutProps {
  children: React.ReactNode;
}
const ProtectedLayout = ({ children }: AuthLayoutProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const menuStatus = useSelector(selectMenuStatus);
  const { logout } = useAuthentication();
  const { changeRoute } = useRoute();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const dropdownSelectableLangs = useSelector(selectDropdownSelectableLangs);
  const routePath = user?.firstPageAccess;
  const logoLink = useLogo();

  const editProfile = () => {
    dispatch(
      setModal(
        [],
        {
          title: t('USERMANAGEMENT.PROFILE'),
          templateBody: 'editProfileUsr',
        },
        {},
        'lg'
      )
    );
  };

  const changeLanguage = (lng: string) => {
    setSelectedLanguage(lng);
    i18n.changeLanguage(lng);
    editUserPreferenceOnline({ language: lng }).then(res =>
      window.location.reload()
    );
  };

  useEffect(() => {
    getLanguages().then(res => {
      let selectLang: any = res.map((ele: any) => ({
        label: ele.lang,
        langCode: ele.id,
        value: ele.id,
      }));

      dispatch(setDropdownSelectableLangs(selectLang));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.store?.data]);

  useEffect(() => {
    dispatch(getUserLoggedIn());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    i18n.changeLanguage(user?.preference.language);
  }, [user]);

  return (
    <>
      <div id="authLayout">
        <div
          className={`${!menuStatus ? 'containerApp' : 'container-app-menu'}`}
        >
          {menuStatus && <HeaderLayout logout={logout}></HeaderLayout>}
          <div className="main-auth">
            <Container fluid>
              <main>
                {menuStatus && children}
                {!menuStatus && (
                  <ContentPageLayoutV2
                    fullScreen
                    isEditMode={false}
                    contentLayout={children}
                    toolbarTopTemplates={{
                      left: (
                        <div className="logo-toolbar">
                          <ToolbarButton
                            className="logo-img"
                            clickAction={() => {
                              routePath &&
                                changeRoute(routePath.route, {
                                  id: routePath.categoryId,
                                });
                            }}
                            img={logoLink.url}
                            imgSize={logoLink.width}
                            styleBtn={{
                              backgroundColor: 'rgba(255, 255, 255, 0.5)',
                              borderRadius: '0px',
                            }}
                          />
                        </div>
                      ),
                    }}
                    toolbarLeftTemplates={{
                      top: (
                        <ToolbarButton
                          clickAction={() => {
                            changeRoute(menu);
                          }}
                          icon="menu"
                          styleBtn={{
                            color: '#e68047',
                          }}
                        />
                      ),
                      bottom: (
                        <Dropdown>
                          <Dropdown.Toggle
                            variant=""
                            className={'dropdown-user'}
                          >
                            <ToolbarButton
                              clickAction={() => {}}
                              fontAwesomeIcon={faUser}
                              styleBtn={{
                                color: '#e68047',
                              }}
                            />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item onClick={editProfile}>
                              {t('APP.PROFILE')}
                            </Dropdown.Item>
                            <div style={{ padding: '0px 14px' }}>
                              <LanguageSelector
                                selectedLanguage={selectedLanguage}
                                onSelectedLanguageChange={changeLanguage}
                                availableLanguages={dropdownSelectableLangs}
                              />
                            </div>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={logout}>
                              {t('APP.LOGOUT')}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      ),
                    }}
                  />
                )}
              </main>
            </Container>
          </div>
        </div>
      </div>
      <FooterLayout></FooterLayout>
    </>
  );
};

export default ProtectedLayout;
