import * as validationType from './validationType';
export interface DataValidation {
  value: string;
  data?: { [key: string]: any } | undefined;
  moreData?: { [key: string]: any };
}

type ValidationData = { [key: string]: (element: DataValidation) => boolean };

const checkRequired = (value: string) => {
  return (
    value !== undefined &&
    ((typeof value === 'string' && value !== '') ||
      (typeof value === 'boolean' && value !== false) ||
      typeof value === 'number' ||
      (Array.isArray(value) && !!value.length))
  );
};

const checkLength = (value: number, length: number) => {
  return isNumber(value) && value !== undefined && value !== 0
    ? value === length
    : true;
};
const checkMinLength = (value: number, length: number) => {
  return isNumber(value) && value !== undefined && value !== 0
    ? value >= length
    : true;
};
const checkMaxLength = (value: number, length: number) => {
  return isNumber(value) && value !== undefined && value !== 0
    ? value <= length
    : true;
};
const isNumber = (value: any) => {
  return value !== undefined && value !== '' ? !Number.isNaN(+value) : true;
};

const checkMinNumber = (value: number, minValue: number) => {
  return isNumber(value) && value !== undefined ? value > minValue : true;
};

const checkMaxNumber = (value: number, maxValue: number) => {
  return isNumber(value) && value !== undefined && value !== 0
    ? value <= maxValue
    : true;
};

const isDate = (value: any) => {
  return value !== undefined && value !== '' ? value instanceof Date : false;
};

const checkStartEndDate = (start: Date, end: Date) => {
  return isDate(start) && isDate(end) ? start > end : true;
};

const validations: ValidationData = {
  [validationType.required]: ({ value }: DataValidation) =>
    checkRequired(value),

  [validationType.email]: ({ value }: DataValidation) =>
    value !== undefined && value !== '' ? /\S+@\S+\.\S+/.test(value) : true,

  [validationType.length]: ({ value, moreData }: DataValidation) => {
    return checkLength(value?.length, moreData?.length);
  },
  [validationType.cf]: ({ value }: DataValidation) => {
    return checkLength(value?.length, 16);
  },
  [validationType.piva]: ({ value }: DataValidation) => {
    return checkLength(value?.length, 11);
  },
  [validationType.cap]: ({ value }: DataValidation) => {
    return isNumber(value) && checkLength(value?.length, 5);
  },

  [validationType.confirm]: ({ value, data, moreData }: DataValidation) =>
    data &&
    moreData &&
    moreData.fieldCompare &&
    value === data[moreData.fieldCompare],

  [validationType.requiredOne]: ({ value, data, moreData }: DataValidation) => {
    return (
      data &&
      moreData &&
      moreData.fieldCompare &&
      (checkRequired(value) || checkRequired(data[moreData.fieldCompare]))
    );
  },

  [validationType.minLength]: ({ value, moreData }) =>
    checkMinLength(value?.length, moreData?.min),

  [validationType.maxLength]: ({ value, moreData }) =>
    checkMaxLength(value?.length, moreData?.max),

  [validationType.percentage]: ({ value }) => {
    return (
      isNumber(value) &&
      checkMinLength(+value, 0) &&
      checkMaxLength(+value, 100)
    );
  },

  [validationType.isNumber]: ({ value }) => isNumber(value),

  [validationType.upperCharacter]: ({ value }) => /.*[A-Z]/.test(value),

  [validationType.specialCharacter]: ({ value }) =>
    // eslint-disable-next-line no-useless-escape
    /.*[\@#!~$%^&*()-+\/:.,<>?|]/.test(value),

  [validationType.oneNumber]: ({ value }) => /.*\d/.test(value),

  [validationType.minNumber]: ({ value, data, moreData }) => {
    return checkMaxNumber(Number(value), moreData?.max);
  },
  [validationType.maxNumber]: ({ value, data, moreData }) => {
    return checkMinNumber(Number(value), moreData?.min);
  },
  [validationType.isDate]: ({ value }: DataValidation) => {
    return isDate(value);
  },
  [validationType.isValidStartEndDate]: ({ data }: DataValidation) => {
    return checkStartEndDate(data?.startDate, data?.endDate);
  },
};

export default validations;
