import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Icon from '../../../icon/Icon';

function DropzoneBox(_props: {
  onDrop: (acceptedFiles: any) => void;
  onClose: () => void;
  accept?: string;
}) {
  const onDrop = useCallback(
    acceptedFiles => {
      _props.onDrop(acceptedFiles);
    },
    [_props]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: _props.accept,
  });

  return (
    <>
      <div className="dropzone-box" {...getRootProps()}>
        <input {...getInputProps()} />
        <Icon icon="save_alt" size="48px" colorStyle="#34a381"></Icon>
        <p className="">Carica oppure trascina qui</p>
      </div>
    </>
  );
}

export default DropzoneBox;
