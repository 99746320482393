import http from '../../../../utils/http';
import { IDocumentModel } from '../model/document';

const urlResources = `/hypercontent`;
const urlHyperlinks = `/hyperlinks`;

export const getHyperlinks = ({
  catId = '',
  areaId = '',
  instanceId = '',
}: {
  catId?: string;
  areaId?: string;
  instanceId?: string;
}): Promise<any[]> =>
  http.get(
    `${urlResources}${urlHyperlinks}/?categoryId=${catId}&subCategoryId=${areaId}&instanceId=${instanceId}`
  );

export const getHyperlinksByInstanceId = ({
  instanceId,
}: {
  instanceId: string;
}): Promise<any[]> => {
  return http.get(`${urlHyperlinks}/${instanceId}`);
};

//hyperlinks/cdn/id

export const getHyperlinksByCdn = ({
  id,
}: {
  id: string;
}): Promise<IDocumentModel> => {
  return http.get(`${urlHyperlinks}/cdn/${id}`);
};
