export const isFull = (obj: any = {}): boolean =>
  obj && isObject(obj) && !!Object.keys(obj).length;

export const isEmpty = (obj: any = {}): boolean => isFull(obj);

export const isObject = (obj: any): boolean =>
  typeof obj === 'object' && obj !== null && obj.constructor === Object;

export const copyObject = <T>(obj: T): T => JSON.parse(JSON.stringify(obj));

export const recursiveReset = (object: any) => {
  const obj = copyObject(object);
  cleanObject(obj);
  recursiveControlArray(obj);
  return obj;
};

function recursiveControlArray(obj: any) {
  // eslint-disable-next-line array-callback-return
  Object.keys(obj).map(key => {
    if (obj[key] instanceof Array) {
      const aaa = obj[key].slice(0, 1);
      recursiveControlArray(aaa);
    } else if (obj[key] === Object(obj[key])) {
      recursiveControlArray(obj[key]);
    }
  });
}
function cleanObject(o: any) {
  for (let [key, value] of Object.entries(o)) {
    let propType = typeof o[key];

    switch (propType) {
      case 'number':
        o[key] = undefined;
        break;

      case 'string':
        o[key] = undefined;
        break;

      case 'boolean':
        o[key] = true;
        break;

      case 'undefined':
        o[key] = undefined;
        break;

      default:
        if (value === null) {
          continue;
        }

        cleanObject(o[key]);
        break;
    }
  }
}
