import React from 'react';
import { useTranslation } from 'react-i18next';
import FormGroup from '../../components/shared/design/form/form-group/FormGroup';
import FormControlCustom from './FormControlCustom';
import { FormGroupCustomModel } from './models/FormElementModel';

const FormGroupCustom = ({
  isSubmit,
  errors,
  label,
  display,
  name,
  onBlur,
  multiply,
  selectedValue,
  changeValue,
  ...rest
}: FormGroupCustomModel) => {
  const { t } = useTranslation();

  return (
    <FormGroup
      display={display}
      label={{ label: label || '' }}
      error={{
        isVisible: isSubmit && !!errors && !!errors[name],
        message: t(
          'ERRORSMSG.' + errors[name]?.code.toUpperCase(),
          errors[name]?.params
        ),
      }}
    >
      <FormControlCustom
        isSubmit={isSubmit}
        errors={errors}
        name={name}
        onBlur={onBlur}
        multiply={multiply}
        selectedValue={selectedValue}
        changeValue={changeValue}
        {...rest}
      />
    </FormGroup>
  );
};

export default FormGroupCustom;
