import React from 'react';
import { Tabs, TabsProps } from 'react-bootstrap';

const TabsPaneV2 = (_props: Omit<TabsProps, 'className'>) => {
  return (
    <Tabs {..._props} className="shared-tabs">
      {_props.children}
    </Tabs>
  );
};

export default TabsPaneV2;
