import React from 'react';
import useInterceptor from '../../hooks/useInterceptor';
import axios from 'axios';

const AuthInterceptor = () => {
  useInterceptor({
    axiosInstance: axios,
  });
  return <></>;
};

export default AuthInterceptor;
