import { isArray } from './../../../../../utils/arrayUtils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  addErrorMessage,
  addSuccessMessage,
} from '../../../../../app/errors/errorSlice';
import { AppThunk, RootState } from '../../../../../app/store';
import i18n from '../../../../../config/i18n';
import { ICategory } from '../../../models/Categories';
import {
  resetModal,
  setModal,
} from '../../../../../components/shared/redux/modal/modalSlice';
import {
  getCategory,
  addCategory,
  editCategory,
  deleteCategory,
} from './services/category.service';

export interface ICategoryManagementSlice {
  listCategory: ICategory[];
  listSubCategory: ICategory[];
  selectedCategory: ICategory | undefined;
  categoryToEdit: ICategory | undefined;
}
const initialState: ICategoryManagementSlice = {
  listCategory: [],
  listSubCategory: [],
  selectedCategory: undefined,
  categoryToEdit: undefined,
};

export const categoryManagementSlice = createSlice({
  name: 'categoryManagement',
  initialState,
  reducers: {
    setListCategory: (state, action: PayloadAction<ICategory[]>) => {
      state.listCategory = action.payload;
    },
    setListSubCategory: (state, action: PayloadAction<ICategory[]>) => {
      state.listSubCategory = action.payload;
    },
    setSelectedCategory: (state, action: PayloadAction<ICategory>) => {
      state.selectedCategory = action.payload;
    },
    setCategoryToEdit: (state, action: PayloadAction<ICategory>) => {
      state.categoryToEdit = action.payload;
    },
    resetListCategory: state => {
      state.listCategory = [];
    },
    resetListSubCategory: state => {
      state.listSubCategory = [];
    },
    resetSelectedCategory: state => {
      state.selectedCategory = undefined;
    },
    resetCategoryToEdit: state => {
      state.categoryToEdit = undefined;
    },
  },
});

export const {
  setListCategory,
  setSelectedCategory,
  setListSubCategory,
  resetListCategory,
  resetListSubCategory,
  resetSelectedCategory,
  setCategoryToEdit,
  resetCategoryToEdit,
} = categoryManagementSlice.actions;

export const getListCategory =
  ({ idCat, depth }: { idCat?: number; depth?: string | number }): AppThunk =>
  dispatch => {
    getCategory({ idCat, depth }).then(
      (res: any) => {
        dispatch(setListCategory(isArray(res) ? res : [res]));
      },
      (err: any) =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.CATEGORY'),
          })
        )
    );
  };
export const getListSubCategory =
  ({ idCat, depth }: { idCat?: number; depth?: string | number }): AppThunk =>
  dispatch => {
    getCategory({ idCat, depth }).then(
      (res: any) => {
        res.children && dispatch(setListSubCategory(res.children));
      },
      (err: any) =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.LOADSUBCATEGORY'),
          })
        )
    );
  };
export const addCategoryAction =
  (data: any, callBack: () => void): AppThunk =>
  (dispatch, getState) => {
    addCategory(data).then(
      (res: any) => {
        const newListCat = [
          ...getState().categoryManagement.listCategory,
          data,
        ];
        const newListSubCat = [
          ...getState().categoryManagement.listSubCategory,
          data,
        ];

        dispatch(setListCategory(newListCat));
        dispatch(setListSubCategory(newListSubCat));
        callBack && callBack();
        dispatch(
          addSuccessMessage({
            title: i18n.t('SUCCESSMSG.ADDCATEGORY'),
          })
        );
      },
      (err: any) =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.ADDCATEGORY'),
          })
        )
    );
  };
export const editCategoryAction =
  (data: any, callBack: () => void): AppThunk =>
  (dispatch, getState) => {
    editCategory(data).then(
      (res: any) => {
        const newListCat = getState().categoryManagement.listCategory;
        dispatch(
          setListCategory([
            ...newListCat.filter(ele => ele.id !== data.id),
            data,
          ])
        );
        dispatch(setSelectedCategory(data));

        callBack && callBack();
        dispatch(resetCategoryToEdit());
        dispatch(
          addSuccessMessage({
            title: i18n.t('SUCCESSMSG.EDITCATEGORY'),
          })
        );
      },
      (err: any) =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.EDITCATEGORY'),
          })
        )
    );
  };
export const deleteCategoryAction =
  (idCat: number): AppThunk =>
  (dispatch, getState) => {
    const buttonList = [
      {
        label: i18n.t('MODAL.CANCELL'),
        action: 'cancelDelete',
        type: 'secondary',
        dataType: {},
      },
      {
        label: i18n.t('MODAL.CONFIRM'),
        action: 'confirmDelete',
        type: 'primary',
        dataType: {},
      },
    ];
    const confirmDelete = () => {
      deleteCategory(idCat).then(
        (res: any) => {
          const newListCat = getState().categoryManagement.listCategory.filter(
            ele => ele.id !== idCat
          );
          dispatch(setListCategory(newListCat));
          dispatch(resetModal());
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETECATEGORY'),
            })
          );
        },
        (err: any) => {
          dispatch(resetModal());
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETECATEGORY'),
            })
          );
        }
      );
    };
    const cancelDelete = () => {
      dispatch(resetModal());
    };
    dispatch(
      setModal(
        buttonList,
        {
          title: i18n.t('MESSAGES.DELETELOCALE'),
          body: i18n.t('MESSAGES.CONFIRMDELETECATEGORY ' + idCat),
        },
        {
          cancelDelete,
          confirmDelete,
        }
      )
    );
  };
export const resetCategoryAll = (): AppThunk => dispatch => {
  dispatch(resetListCategory());
  dispatch(resetListSubCategory());
  dispatch(resetSelectedCategory());
  dispatch(resetCategoryToEdit());
};

export const selectListCategory = (state: RootState) =>
  state.categoryManagement.listCategory;

export const selectListSubCategory = (state: RootState) =>
  state.categoryManagement.listSubCategory;

export const selectSelectedCategory = (state: RootState) =>
  state.categoryManagement.selectedCategory;

export const selectCategoryToEdit = (state: RootState) =>
  state.categoryManagement.categoryToEdit;

export default categoryManagementSlice.reducer;
