import React from 'react';
import { ValueName } from './models/DataForm';

import FormElements from './FormElements';
import FormElementsTemplateRow from './FormElementsTemplateRow';
import {
  deleteElementByArrayModel,
  templateRowModel,
} from './models/FormElementModel';
import Icon from '../../components/shared/icon/Icon';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';

const FormElementsArrayRow = ({
  data,
  dataFormatted = {},
  errors,
  isSubmit = false,
  changeValue = () => {},
  dataForm,
  onBlur = () => {},
  templateRow,
  removeElement,
  deleteElementByArray,
  className,
}: {
  data: any;
  dataFormatted?: any;
  templateRow?: templateRowModel;
  isSubmit?: boolean;
  errors: { [key: string]: any };
  changeValue?: ({ value, name }: ValueName) => void;
  dataForm: { [key: string]: any };
  addElement?: (data: string) => void;
  removeElement: () => void;
  onBlur?: ({ value, name }: ValueName) => void;
  deleteElementByArray?: deleteElementByArrayModel;
  className?: string;
}) => {
  const { t } = useTranslation();
  return (
    <FormElementsTemplateRow
      values={data.value}
      templateRow={templateRow}
      removeAction={removeElement}
    >
      <FormElements
        deleteElementByArray={deleteElementByArray}
        data={data.elements}
        dataFormatted={dataFormatted}
        changeValue={changeValue}
        dataForm={dataForm}
        errors={errors}
        isSubmit={isSubmit}
        onBlur={onBlur}
      />
      {!templateRow && (
        <Row className="justify-content-end">
          <Col xs="auto">
            <Icon
              icon="delete"
              label={t('APP.DELETE')}
              title={t('APP.DELETE')}
              size="20px"
              colorStyle="#01a381"
              clickAction={removeElement}
            ></Icon>
          </Col>
        </Row>
      )}
    </FormElementsTemplateRow>
  );
};

export default FormElementsArrayRow;
