import React from 'react';
import { Col } from 'react-bootstrap';
import FileUpload from '../../../../../components/shared/custom/fileUpload/FileUpload';
import Label from '../../../../../components/shared/design/form/label/Label';
import { ITypesImg } from '../../../models/thumbs';

interface IFileUploadSm {
  label: string;
  handleOnDrop: (files: File[], nameDrop: string) => void;
  fileDataImport?: ITypesImg;
  notValidFile: { [key: string]: any };
  nameDrop: string;
  disable?: boolean;
}

const FileUploadSm = ({
  label,
  handleOnDrop,
  fileDataImport,
  notValidFile,
  nameDrop,
  disable = false,
}: IFileUploadSm) => {
  return (
    <Col>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <Label label={label}></Label>
        {!disable && (
          <FileUpload
            handleOnDrop={handleOnDrop}
            fileDataImport={fileDataImport}
            notValidFile={notValidFile}
            nameDrop={nameDrop}
            small={true}
          />
        )}
      </div>
    </Col>
  );
};

export default FileUploadSm;
