import BASE_PATHS from './basePaths';

export const redirectPath = BASE_PATHS.PROTECTED;
export const projectMap = 'projectMap';
export const scene = 'scene';
export const game = 'game';
export const microgame = 'microgame';
export const microgameList = 'microgameList';
export const menu = 'menu';
export const links = 'links';
export const resources = 'resources';
export const video = 'video';
export const course = 'course';
export const doc = 'doc';
export const comments = 'comments';
export const animations = 'animations';
export const disclaimer = 'disclaimer';
export const myprogress = 'myprogress';
export const search = 'search';
export const gamerole = 'gamerole';
export const wallmanage = 'wallmanage';
export const help = 'help';
export const exports = 'export';
export const badgemanager = 'badgemanager';
export const resetgame = 'resetgame';
export const badgeassigner = 'badgeassigner';
export const moodlemanager = 'moodlemanager';
export const menumanagement = 'menumanagement';
export const usermanagement = 'usermanagment';
export const batch = 'batch';
export const tooltip = 'tooltip';
export const hyperlink = 'hyperlink';
export const rolesmanagement = 'rolesmanagement';
export const categorymanagement = 'categorymanagement';
export const languagesmanagement = 'languagesmanagement';
export const aclmanagement = 'aclmanagement';
export const checkmanagement = 'checkmanagement';
export const gdpr = 'gdpr';
export const gdpruseronline = 'useronline';
export const gdprconfiguration = 'configuration';
export const report = 'report';
export const meetings = 'meetings';
export const weblinks = 'weblinks';
export const cycleMaps = 'cyclemaps';
export const gamereport = 'gamereport';
export const radar = 'radars';
export const skillValuation = 'skill_valuation';
export const attemptreport = 'attemptreport';
export const ranking = 'ranking';
