import React from 'react';
import { Tab } from 'react-bootstrap';
import { IBtnList } from '../../../../app/auth/model/BtnList';
import TabsPaneV2 from '../../../../components/shared/design/tabs/TabsV2';
import WeblinkForm from '../adminSidebar/components/WeblinkForm';
import { WeblinkData } from '../models/types';

type SidebarProps = {
  btnListRight: IBtnList[];
  weblinkActionToolbarBtn: <T>(action: string, data?: T) => void;
  handleDataFormChange: (data: WeblinkData) => void;
  weblinkData: WeblinkData | undefined;
};

function SidebarContent(props: SidebarProps) {
  const {
    btnListRight,
    weblinkActionToolbarBtn,
    handleDataFormChange,
    weblinkData,
  } = props;

  return (
    <>
      <TabsPaneV2 defaultActiveKey="weblink">
        <Tab eventKey="weblink" title="WebLink">
          <WeblinkForm
            weblinkData={weblinkData}
            onChangeDataForm={handleDataFormChange}
            onToolbarAction={weblinkActionToolbarBtn}
            toolbarData={{ left: [], right: btnListRight }}
          />
        </Tab>
      </TabsPaneV2>
    </>
  );
}

export default SidebarContent;
