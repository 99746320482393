import React from 'react';
import { Col, Row } from 'react-bootstrap';
import FormElementsArrayOne from './FormElementsArrayOne';
import FormGroupCustom from './FormGroupCustom';
import { ValueName } from './models/DataForm';
import {
  deleteElementByArrayModel,
  FormElementModelNew,
} from './models/FormElementModel';

const FormElementOnlyOne = ({
  data = [],
  dataFormatted = {},
  errors,
  isSubmit = false,
  changeValue = () => {},
  dataForm,
  onBlur = () => {},
  deleteElementByArray,
  className,
}: {
  data: FormElementModelNew[];
  dataFormatted?: any;
  isSubmit?: boolean;
  errors: { [key: string]: any };
  changeValue?: ({ value, name }: ValueName) => void;
  dataForm: { [key: string]: any };
  onBlur?: ({ value, name }: ValueName) => void;
  deleteElementByArray?: deleteElementByArrayModel;
  className?: string;
}) => {
  let issetCol = !!data.find(ele => ele.col);
  const formGroup = ({
    label,
    name,
    dataElement = {},
    typeElement,
    options,
    multiply: multiplyElement,
  }: FormElementModelNew) => (
    <FormGroupCustom
      key={name}
      isSubmit={isSubmit}
      name={name}
      errors={errors}
      label={label}
      display={dataElement.display}
      dataElement={dataElement}
      typeElement={typeElement}
      value={dataForm[name]}
      options={options}
      changeValue={value => changeValue({ value, name })}
      onBlur={value => onBlur({ value, name })}
      multiply={multiplyElement}
      selectedValue={dataForm[name]}
    />
  );
  const formArray = (index: number, ele: FormElementModelNew) => (
    <FormElementsArrayOne
      deleteElementByArray={deleteElementByArray}
      key={index}
      dataFormatted={dataFormatted}
      data={ele}
      changeValue={changeValue}
      dataForm={dataForm}
      errors={errors}
      isSubmit={isSubmit}
      onBlur={onBlur}
      className={className}
    />
  );

  const templateWrapper = (
    formElement: FormElementModelNew,
    dataF: any,
    dFormatted: any
  ) => {
    return formElement.template
      ? formElement.template(
          formGroup(formElement),
          formElement,
          dataF,
          dFormatted
        )
      : formGroup(formElement);
  };
  const templateWrapperFormArray = (
    formElement: FormElementModelNew,
    dataF: any,
    dFormatted: any,
    index: number
  ) => {
    return formElement.template
      ? formElement.template(
          formArray(index, formElement),
          formElement,
          dataF,
          dFormatted
        )
      : formArray(index, formElement);
  };

  const form = (dataF: any, dFormatted: any) => {
    return data.map((ele: FormElementModelNew, index) =>
      ColContainer(ele, index, dataF, dFormatted)
    );
  };

  const ColContainer = (
    ele: FormElementModelNew,
    index: number,
    dataF: any,
    dFormatted: any
  ) => {
    return ele.form ? (
      templateWrapperFormArray(ele, dataF, dFormatted, index)
    ) : issetCol ? (
      <Col key={index} {...ele.col}>
        {templateWrapper(ele, dataF, dFormatted)}
      </Col>
    ) : (
      templateWrapper(ele, dataF, dFormatted)
    );
  };
  return (
    <>
      {issetCol ? (
        <Row>{form(dataForm, dataFormatted)}</Row>
      ) : (
        form(dataForm, dataFormatted)
      )}
    </>
  );
};

export default FormElementOnlyOne;
