import omit from 'lodash.omit';
import http from '../../../../../../utils/http';
import { UserPreference } from '../types';

const urlPreferences = '/preferences';

export const getUserPreference = (userId: string) => {
  return http.get<UserPreference[]>(`${urlPreferences}/${userId}`);
};

export const editUserPreference = (userPreference: any): Promise<any> => {
  const data = omit(userPreference, ['id', '_id']);
  const id = userPreference.id ? userPreference.id : userPreference._id;
  return http.patch(`${urlPreferences}/${id}`, data);
};
export const getUserPreferenceOnline = (): Promise<any> => {
  return http.get(`${urlPreferences}/online`);
};
export const editUserPreferenceOnline = (userPreference: any): Promise<any> => {
  return http.patch(`${urlPreferences}/online`, userPreference);
};

export const deleteUserPreference = (userId: string): Promise<any> => {
  return http.delete(`${urlPreferences}/${userId}`);
};
