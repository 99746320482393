import React from 'react';
import CardShadow from '../../design/card/CardShadow';
import Icon from '../../icon/Icon';

interface IItemBgGameAvailable {
  url: string;
  clickRemoveItem: () => void;
  clickActionItem: () => void;
  children?: React.ReactChild;
  altString?: string;
}

const ThumbItem = (props: IItemBgGameAvailable) => {
  const { url, clickRemoveItem, clickActionItem, children, altString } = props;

  return (
    <CardShadow className="thumb-item pointer" clickAction={clickActionItem}>
      {!children ? (
        <img className="thumb-img" src={url} alt={altString} />
      ) : (
        <div onClick={clickActionItem}>{children}</div>
      )}
      <Icon
        className="thumb-close pointer"
        icon="close"
        onClick={e => {
          e.stopPropagation();
          clickRemoveItem();
        }}
      ></Icon>
    </CardShadow>
  );
};

export default ThumbItem;
