import React, { ReactNode } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../../components/shared/icon/Icon';

const RowTemplateDelete = ({
  className,
  children,
  values,
  deleteElement,
}: {
  className?: string;
  children: ReactNode;
  values?: any;
  deleteElement: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <Row>
        <Col xs="12">{children}</Col>
      </Row>
      <Row className="justify-content-end">
        <Col xs="auto">
          <Icon
            icon="delete"
            label={t('APP.DELETE')}
            title={t('APP.DELETE')}
            size="20px"
            colorStyle="#01a381"
            clickAction={deleteElement}
          ></Icon>
        </Col>
      </Row>
    </div>
  );
};

export default RowTemplateDelete;
