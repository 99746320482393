import React, { SetStateAction, useState } from 'react';
import FileUpload from '../../../../../components/shared/custom/fileUpload/FileUpload';
import { getImageSize, processFile } from '../../../../../utils/readerFile';
import { fileBgImport } from '../../../data/thumbData';
import ItemThumbAvailable from '../../../fabricJs/adminSidebar/components/ItemThumbAvailable';
import { IThumb } from '../../../fabricJs/models/Thumb';
import { uploadFile } from '../../../services/upload.service';
import { ICustomBackground } from '../../models/types';
import { getAvailableBackgroundFiles } from '../../services/background.service';

interface IDisplayBgsAvailable {
  listThumb: IThumb[];
  clickAddThumb: (item: IThumb) => void;
  clickRemoveThumb: (item: IThumb) => void;
  setThumb: (thumbs: SetStateAction<ICustomBackground[]>) => void;
  pathName: string;
}

const DisplayBgsAvailable = (props: IDisplayBgsAvailable) => {
  const { listThumb, clickAddThumb, clickRemoveThumb, setThumb, pathName } =
    props;
  const [errors, setErrors] = useState<{ [key: string]: any }>({});

  const thumbs = listThumb.map((thumb: IThumb, key: number) => {
    return (
      <ItemThumbAvailable
        key={key}
        thumb={thumb}
        clickAddThumb={() => clickAddThumb(thumb)}
        clickRemoveThumb={() => clickRemoveThumb(thumb)}
      />
    );
  });

  const handleOnDropBg = (files: File[], nameDrop: string) => {
    files[0]
      ? processFile(files[0]).then((res: any) => {
          getImageSize(res).then(() => {
            let formData = new FormData();
            formData.append(files[0].name, files[0]);
            uploadFile(formData, {
              isPublic: 'true',
              pathName: pathName,
              prefix: 'bg',
            }).then(res => {
              // API GET Available animation background
              getAvailableBackgroundFiles(pathName).then(res =>
                setThumb(res as ICustomBackground[])
              );
            });
          });
        })
      : setErrors({ ...errors, [nameDrop]: { isNotValid: true } });
  };
  return (
    <>
      <div className="d-flex flex-column h-100">
        <div className="d-flex-col-beetween">
          <div className="d-flex flex-wrap justify-content-between">
            {thumbs}
          </div>
          <div className="">
            <FileUpload
              handleOnDrop={handleOnDropBg}
              fileDataImport={fileBgImport}
              notValidFile={errors}
              nameDrop={'bgAnimate'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DisplayBgsAvailable;
