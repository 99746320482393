import omit from 'lodash.omit';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { IBtnList } from '../../../app/auth/model/BtnList';
import ToolbarButton from '../../../components/shared/custom/toolbar/toolbarButton/ToolbarButton';
import IEFrame from '../../../components/shared/ie-frame/IEFrame';
import { useRoute } from '../../../utils/routes/useRoute';
import EditModeBtn from '../common/EditModeBtn';
import ContentPageLayoutV2 from '../ContentPageLayoutV2';
import SidebarContent from './components/SidebarContent';
import { WeblinkData } from './models/types';
import {
  deleteWeblink,
  getWeblink,
  postWeblink,
  putWeblink,
} from './services/weblink.service';

function WeblinkLayout() {
  const { id } = useParams<{ id: string }>();
  const { goBack } = useRoute();
  const [weblink, setWeblink] = useState<WeblinkData>({ sceneId: 5, url: '' });
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [keyFrame, setKeyFrame] = useState<number>(0);

  useEffect(() => {
    reloadWeblinkFrame();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    frameRefresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weblink, isEditMode]);

  const funcWeblinkToolbarBtn: { [key: string]: (data?: any) => void } = {
    delete: () => {
      deleteWeblinkById();
    },
    save: () => {
      if (weblink) {
        weblink._id ? updateWeblink(weblink) : createWeblink(weblink);
      }
    },
  };

  const weblinkToolbarBtn: IBtnList[] = [
    {
      label: 'APP.DELETE',
      icon: 'delete',
      color: '#34a381',
      action: 'delete',
    },
    {
      label: 'APP.SAVE',
      icon: 'save',
      color: '#34a381',
      action: 'save',
    },
  ];

  function handlerSidebar() {
    setIsEditMode(!isEditMode);
  }

  function deleteWeblinkById() {
    if (weblink && weblink._id) {
      deleteWeblink(weblink._id, id).then(res => {
        setWeblink({ sceneId: 5, url: '' });
        setIsEditMode(false);
      });
    }
  }

  function updateWeblink(data: WeblinkData) {
    console.log('update', data);
    if (data && data._id) {
      putWeblink(id, data._id, omit(data, ['_id'])).then(reloadWeblinkFrame);
    }
  }

  function createWeblink(data: WeblinkData) {
    if (data) {
      const weblink = Object.assign({}, data);
      console.log('create', weblink);
      postWeblink(id, omit(weblink, ['_id'])).then(reloadWeblinkFrame);
    }
  }

  function reloadWeblinkFrame() {
    getWeblink(id).then(
      res => {
        setWeblink(res);
        console.log('reloadWeblinkFrame', weblink);
        return res._id!;
      },
      err => {
        setWeblink({ sceneId: 5, url: '' });
        setIsEditMode(false);
      }
    );
  }

  function handlerWeblinkActionToolbarBtn<T>(action: string, data?: T) {
    funcWeblinkToolbarBtn &&
      funcWeblinkToolbarBtn[action] &&
      funcWeblinkToolbarBtn[action](data);
  }

  function handleDataFormChange(data: WeblinkData) {
    setWeblink({ ...data });
    console.log('handleDataForm', weblink);
  }

  function topToolbarRightTemplate() {
    return (
      <div>
        <EditModeBtn handlerAction={handlerSidebar} />
      </div>
    );
  }

  function bottomToolbarRightTemplate() {
    return (
      <div>
        <ToolbarButton clickAction={goBack} icon="exit_to_app" />
      </div>
    );
  }

  function sidebarTemplate() {
    return (
      <SidebarContent
        btnListRight={weblinkToolbarBtn}
        weblinkActionToolbarBtn={handlerWeblinkActionToolbarBtn}
        handleDataFormChange={handleDataFormChange}
        weblinkData={weblink}
      />
    );
  }

  function frameRefresh() {
    setKeyFrame(keyFrame + 1);
  }

  return (
    <ContentPageLayoutV2
      fullScreen
      toolbarRightTemplates={{
        top: topToolbarRightTemplate(),
        bottom: bottomToolbarRightTemplate(),
      }}
      isEditMode={isEditMode}
      contentLayout={
        <>
          {weblink && (
            <IEFrame
              title={`${weblink?.url}`}
              src={weblink?.url}
              height={weblink?.height ? Number(weblink?.height) : undefined}
              width={weblink?.width ? Number(weblink?.width) : undefined}
              frameBorder={0}
              allowFullScreen={true}
              key={keyFrame}
            />
          )}
        </>
      }
      sidebarContentTemplate={sidebarTemplate()}
    />
  );
}

export default WeblinkLayout;
