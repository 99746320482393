export const MOCK_SKILL_RADAR_RESULT = {
  title: 'Radar Chart',
  skillsScoreFirstAttempt: [
    {
      skill: 'Apprendimento',
      score: 45,
    },
    {
      skill: 'Carisma',
      score: 25,
    },
    {
      skill: 'Comunicazione',
      score: 32,
    },
    {
      skill: 'Curiosità',
      score: 13,
    },
    {
      skill: 'Problem solving',
      score: 15,
    },
  ],
  skillsScoreSecondAttempt: [
    {
      skill: 'Apprendimento',
      score: 38,
    },
    {
      skill: 'Carisma',
      score: 29,
    },
    {
      skill: 'Comunicazione',
      score: 21,
    },
    {
      skill: 'Curiosità',
      score: 45,
    },
    {
      skill: 'Problem solving',
      score: 56,
    },
  ],
  rgbFirstAttempt: '#01a381',
  rgbSecondAttempt: '#d66711',
};

export const MOCK_SKILL_RADAR_CONFIG = {
  title: 'Radar Chart',
  categoryId: 3000,
  skills: [
    'Apprendimento',
    'Carisma',
    'Comunicazione',
    'Curiosità',
    'Problem solving',
  ],
  doubleAttempt: false,
  rgbFirstAttempt: '#01a381',
  rgbSecondAttempt: '#d66711',
};
