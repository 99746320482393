import { navigationActionsName } from '../features/auth/data/navigationData';
import {
  IAnswer,
  IQuestion,
} from '../features/auth/game/gamePlatform/models/gameQuestionConfiguration';
import { ICategory } from '../features/auth/models/Categories';
import { ICategoryPin } from '../features/auth/models/Pin';
import { MicrogameItem } from './../features/auth/game/microGame/model/gameList';

export const createCategoriesOption = (categories: Partial<ICategory>[]) => {
  return categories.map(cat => ({
    value: `${cat.id}`,
    label: `${cat.id} - ${cat.name}`,
  }));
};
export const createResourcesOption = (hyperlink: any[]) => {
  return hyperlink.map(link => ({
    value: link._id,
    label: link.description,
  }));
};
export const createMicrogameOption = (resourceId: MicrogameItem[]) => {
  return resourceId.map(micro => ({
    value: '' + micro.categoryId,
    label: micro.name,
  }));
};

export const createQuestionItemsOption = (
  questions: IQuestion[],
  langId: string
) => [
  ...questions.map(item => {
    const id = item.id;
    const filteredQuestion = item.questionItems.find(
      item => item.languageId === langId
    );

    return {
      value: `${id}`,
      label: `${filteredQuestion?.question}`,
    };
  }),
];

export const createAnswersOption = (answers: Partial<IAnswer>[]) => [
  ...answers.map(answer => {
    return {
      value: answer.id,
      label: answer.answer,
    };
  }),
];

export function fixCategoriesData(
  dataForm: any,
  action: string,
  depthCategories?: ICategory
) {
  const category = findCategory(dataForm.subItemId, depthCategories);
  if (isDocOrVideo(action)) {
    dataForm.category = dataForm.category ? dataForm.category : {};
    dataForm.category.id = Number(dataForm.subItemId);
    dataForm.category.name = category?.name || 'not-found';
    if (dataForm?.category?.subCategory) {
      dataForm.category.subCategory = convertSubcateryIdToNumber(
        dataForm.category.subCategory
      );
    }
  } else {
    delete dataForm.category;
  }
  return dataForm;
}

export function fixGoToCategoryIdData(dataForm: any, action: string) {
  //const prevValue = dataForm.goToCategoryId;
  //const newDf = {
  //  ...dataForm,
  //  goToCategoryId: dataForm.subItemId ? dataForm.subItemId : prevValue,
  //};
  //return newDf;
  return dataForm;
}

export function convertSubcateryIdToNumber(s: any): ICategoryPin | undefined {
  if (!s) return undefined;
  return {
    id: Number(s.id),
    subCategory: s.subCategory
      ? convertSubcateryIdToNumber(s.subCategory)
      : undefined,
  } as ICategoryPin;
}

export function isDocOrVideo(action: string) {
  return (
    action === navigationActionsName.goToDoc ||
    action === navigationActionsName.goToVideo
  );
}

export function isDocOrVideoOrCourseOrResources(action: string) {
  return (
    action === navigationActionsName.goToDoc ||
    action === navigationActionsName.goToVideo ||
    action === navigationActionsName.goToCourse ||
    action === navigationActionsName.goToResources
  );
}

export function findCategory(
  childrenId: string,
  category: ICategory | undefined
): ICategory | undefined {
  if (!category) return undefined;
  if (category.id === Number(childrenId)) {
    return category;
  }

  let find: ICategory | undefined = undefined;

  if (category.children) {
    for (const item of category.children) {
      const result = findCategory(childrenId, item);
      if (result) {
        find = Object.assign({}, result);
        break;
      }
    }
  }
  return find;
}
