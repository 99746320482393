import http, { HttpCall } from '../utils/http';
import { useRoute } from '../utils/routes/useRoute';
import { Trigger } from './useEvalutation';

type HttpCallType = keyof HttpCall;

export type ActionType = {
  payload: { [key in string]: any };
  feedback?: boolean;
  callback?: (error: Error | undefined, data?: any) => void;
  next?: ActionType;
} & (GoToAction | CallApiAction | ReloadAction | TriggerAction);

type GoToAction = {
  action: 'goTo';
};

type CallApiAction = {
  action: 'callApi';
};
type ReloadAction = {
  action: 'reload';
};
type TriggerAction = {
  action: 'trigger';
  payload: Trigger;
};

export type ActionFn = {
  [key in ActionType['action']]: (action: ActionType) => void;
};

function useAction() {
  const { changeRoute, getPathRoute } = useRoute();

  function handleCallApi(action: ActionType) {
    const { method, path, data } = action.payload;
    const call: { [key in HttpCallType]: () => Promise<any> } = {
      get: () => http.get(path),
      delete: () => http.delete(path),
      patch: () => http.patch(path, data),
      post: () => http.post(path, data),
      put: () => http.put(path, data),
    };
    call[method as HttpCallType]()
      .then(res => {
        if (action.feedback && !action.next) {
          action.callback && action.callback(undefined, res);
        } else if (action.next) {
          action.next.callback = action.callback;
          actions[action.next.action](action.next);
        }
      })
      .catch(err => {
        action.feedback && action.callback && action.callback(err);
      });
  }

  function handleGoTo(action: ActionType) {
    const goto = getPathRoute(action.payload.route, action.payload.params);
    if (window.location.pathname === goto) {
      window.location.reload();
    } else {
      changeRoute(action.payload.route, action.payload.params);
    }
  }

  const actions: ActionFn = {
    goTo: handleGoTo,
    callApi: handleCallApi,
    reload: action => window.location.reload(),
    trigger: action => {
      throw new Error('trigger not accepted');
    },
  };

  return actions;
}

export default useAction;
