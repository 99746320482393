// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useEffect, useState } from 'react';
import { TFunction } from 'i18next';
import { createStructureForm } from '../../../../utils/forms/useStructureForm';
import { FormElementModelNew } from '../../../../utils/forms/models/FormElementModel';
import { createSameOptionsWithoutEmpty } from '../../../../utils/select';
import { getAllSkills } from '../../game/gamePlatform/services/gameConfiguration.service';

function useRadarForm(t: TFunction, dataForm: any, categoryId: number) {
  const [skillsOptions, setSkillsOptions] = useState<string[]>([]);
  const radarStructuredForm: FormElementModelNew[] = createStructureForm(
    [
      {
        typeElement: 'input',
        label: t('RADAR.TITLE'),
        name: 'title',
        dataElement: {
          placeholder: t('RADAR.TITLE'),
          type: 'text',
        },
        col: { xs: 12 },
      },
      {
        typeElement: 'input',
        label: t('GAMEMANAGEMENT.CATEGORYID'),
        name: 'categoryId',
        dataElement: {
          placeholder: t('GAMEMANAGEMENT.CATEGORYID'),
          type: 'text',
          disabled: true,
        },
        col: { xs: 6 },
      },
      {
        typeElement: 'switch',
        label: t('RADAR.DOUBLEATTEMPT'),
        name: 'doubleAttempt',
        dataElement: {
          type: 'checkbox',
          noRequired: false,
        },
        col: { xs: 6 },
      },
      {
        typeElement: 'select',
        label: t('GAMEMANAGEMENT.SKILLNAME'),
        name: 'skills',
        multiply: true,
        dataElement: {
          type: 'text',
        },
        options: createSameOptionsWithoutEmpty(skillsOptions),
        col: { xs: 12 },
      },
      {
        typeElement: 'input',
        label: t('RADAR.RGBFIRSTATTEMPT'),
        name: 'rgbFirstAttempt',
        dataElement: {
          label: t('RADAR.RGBFIRSTATTEMPT'),
          type: 'color',
        },
        col: { xs: 6 },
      },
      {
        typeElement: 'input',
        label: t('RADAR.RGBSECONDATTEMPT'),
        name: 'rgbSecondAttempt',
        dataElement: {
          label: t('RADAR.RGBSECONDATTEMPT'),
          type: 'color',
        },
        col: { xs: 6 },
      },
    ],
    dataForm
  );

  useEffect(() => {
    categoryId && loadSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  function loadSkills() {
    setSkillsOptions([]);
    getAllSkills('it', undefined, categoryId).then(res =>
      setSkillsOptions(res)
    );
  }

  return { radarStructuredForm };
}

export default useRadarForm;
