import React from 'react';

export type GameReportLayoutProps = {
  searchForm: JSX.Element;
  resultTable: JSX.Element;
};
function GameReportLayout(props: GameReportLayoutProps) {
  const { searchForm, resultTable } = props;

  return (
    <div className="game-container content-game d-flex flex-row">
      <div className="left-side report-tree-view.brand content-form">
        {searchForm}
      </div>
      <div className="right-side">
        <div className="wall" data-simplebar>
          {resultTable}
        </div>
      </div>
    </div>
  );
}

export default GameReportLayout;
