import isEmpty from 'lodash.isempty';
import React, { lazy } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoaderFullScreenApp from '../../app/load/LoaderFullScreenApp';
import { Routes, SingleRoute } from '../../utils/routes/models/routes';

interface Props {
  routes: Routes;
  layouts?: {
    name: string;
    component: (data: React.ReactNode) => React.ReactNode;
  }[];
}
const DynamicRoute = (props: Props) => {
  const { routes, layouts } = props;

  return (
    <React.Suspense fallback={<LoaderFullScreenApp></LoaderFullScreenApp>}>
      <Switch>
        {!isEmpty(layouts) &&
          layouts!.map(layout => (
            <Route
              key={layout.name}
              path={routes.list
                .filter(ele => ele.layout === layout.name)
                .map(el => el.path)}
            >
              {layout.component(
                routes.list
                  .filter(ele => ele.layout === layout.name)
                  .map(ele => (
                    <Route
                      key={ele.path}
                      exact
                      path={ele.path}
                      component={getComponent(ele)}
                    />
                  ))
              )}
            </Route>
          ))}
        {routes.list
          .filter(ele => !ele.layout)
          .map(ele => (
            <Route
              key={ele.path}
              exact
              path={ele.path}
              component={getComponent(ele)}
            />
          ))}
        {routes.redirect && (
          <Redirect from={routes.basepath} to={routes.redirect} />
        )}
      </Switch>
    </React.Suspense>
  );
};
export default DynamicRoute;

function getComponent(sr: SingleRoute) {
  return typeof sr.component === 'string'
    ? lazy(() => import(`../../${sr.component}`))
    : sr.component;
}
