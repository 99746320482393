import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addErrorMessage } from '../../../app/errors/errorSlice';
import { AppThunk, RootState } from '../../../app/store';
import i18n from '../../../config/i18n';
import { IMenuItem } from './models/menuItem';
import { getMenu } from './services/menu.service';

export interface IMenuSlice {
  listMenu: IMenuItem[];
  isOpen: boolean;
}
const initialState: IMenuSlice = {
  listMenu: [],
  isOpen: false,
};

export const menuSlice = createSlice({
  name: 'menuSlice',
  initialState,
  reducers: {
    changeStatus: state => {
      state.isOpen = !state.isOpen;
    },
    setListMenu: (state, action: PayloadAction<IMenuItem[]>) => {
      state.listMenu = action.payload;
    },
    resetListMenu: state => {
      state.listMenu = [];
    },
    removeMenuFromMenuList: (state, action: PayloadAction<string>) => {
      let firstFilter = state.listMenu.filter(
        menu => menu._id !== action.payload
      );
      let newListMenu = firstFilter.map(menu => {
        let newChildrenList = menu.childrenItems.filter(
          children => children._id !== action.payload
        );
        menu.childrenItems = [...newChildrenList];
        return menu;
      });
      state.listMenu = newListMenu;
    },
    addMenuInMenuList: (state, action: PayloadAction<IMenuItem>) => {
      if (action.payload.fatherId) {
        let fatherMenu = state.listMenu.filter(
          menu => menu._id === action.payload.fatherId
        );
        fatherMenu[0].childrenItems = [
          ...fatherMenu[0].childrenItems,
          action.payload,
        ];
        const notSortedListMenu = [
          ...state.listMenu.filter(menu => menu._id !== fatherMenu[0]._id),
          fatherMenu[0],
        ];
        state.listMenu = notSortedListMenu.sort(
          (a, b) => a.orderNumber - b.orderNumber
        );
      } else {
        const notSortedListMenu = [
          ...state.listMenu.filter(menu => menu._id !== action.payload._id),
          action.payload,
        ];
        state.listMenu = notSortedListMenu.sort(
          (a, b) => a.orderNumber - b.orderNumber
        );
      }
    },
    editMenuInMenuList: (state, action: PayloadAction<IMenuItem>) => {
      if (action.payload.fatherId) {
        let fatherMenu = state.listMenu.filter(
          menu => menu._id === action.payload.fatherId
        );
        fatherMenu[0].childrenItems = [
          ...fatherMenu[0].childrenItems.filter(
            children => children._id !== action.payload._id
          ),
          action.payload,
        ];
        const notSortedListMenu = [
          ...state.listMenu.filter(menu => menu._id !== fatherMenu[0]._id),
          fatherMenu[0],
        ];
        state.listMenu = notSortedListMenu.sort(
          (a, b) => a.orderNumber - b.orderNumber
        );
      } else {
        const notSortedListMenu = [
          ...state.listMenu.filter(menu => menu._id !== action.payload._id),
          action.payload,
        ];
        state.listMenu = notSortedListMenu.sort(
          (a, b) => a.orderNumber - b.orderNumber
        );
      }
    },
  },
});
export const {
  setListMenu,
  removeMenuFromMenuList,
  addMenuInMenuList,
  editMenuInMenuList,
  changeStatus,
} = menuSlice.actions;

export const getListMenu = (): AppThunk => dispatch => {
  getMenu().then(
    res => {
      dispatch(setListMenu(res));
    },
    err =>
      dispatch(
        addErrorMessage({
          title: i18n.t('ERRORSMSG.LOADINGMENU'),
        })
      )
  );
};

export const selectListMenu = (state: RootState) => state.menu.listMenu;
export const selectMenuStatus = (state: RootState) => state.menu.isOpen;

export default menuSlice.reducer;
