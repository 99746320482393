import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Datetime from 'react-datetime';
import Input from '../design/form/input/Input';

const DateTimePicker: React.FC<{
  error: boolean;
  name: string;
  value?: Date;
  onChangeAction: (value?: Date) => void;
  disabled: boolean;
}> = props => {
  const { onChangeAction, value: valueInput, ...inputProps } = props;
  const [value, setValue] = useState<Date | undefined>(valueInput);

  const [calendarOpen, setCalendarOpen] = useState<boolean>(false);

  useEffect(() => {
    onChangeAction(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    setValue(valueInput);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueInput]);

  return (
    <Datetime
      locale={'it'}
      initialValue={value}
      onChange={value => {
        if (typeof value === 'string') {
          setValue(undefined);
        } else {
          setValue(value.toDate());
        }
      }}
      timeFormat={'HH:mm'}
      timeConstraints={{
        minutes: { min: 0, max: 60 * 24 - 1, step: 15 },
      }}
      renderInput={(props, openCalendar, closeCalendar) => {
        function handleClick() {
          if (calendarOpen) {
            openCalendar();
          } else {
            closeCalendar();
          }
          setCalendarOpen(prev => !prev);
        }

        return (
          <Input
            value={value ? moment(value).format('DD/MM/YYYY HH:mm') : undefined}
            {...inputProps}
            onClick={handleClick}
          />
        );
      }}
    />
  );
};

export default DateTimePicker;
