import React from 'react';
import { Alert, Table } from 'react-bootstrap';
import { cupImages, rankIcon } from '../constants';
import { Rank } from '../models/type';
import isEmpty from 'lodash.isempty';
import { useTranslation } from 'react-i18next';

type RankCardProps = {
  ranking: Rank[];
  currentRank?: number;
};

function RankCard(props: RankCardProps) {
  const { ranking, currentRank } = props;

  const { t } = useTranslation();

  return (
    <div>
      <Table bordered responsive className="rank">
        <thead>
          <tr>
            <th>POS</th>
            <th>ID/NAME</th>
            <th>PERCORSI</th>
            <th>KM</th>
            {rankIcon.map(r => (
              <th>
                <img
                  src={r.url}
                  alt={`${r.description}`}
                  style={{ maxWidth: '28px' }}
                />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {ranking.map(rank => (
            <tr className={`${rank.Ranking === currentRank ? 'current' : ''}`}>
              <td>
                <span>{rank.Ranking}</span>
                {rank.Ranking <= 3 && (
                  <img
                    src={cupImages[rank.Ranking - 1]}
                    alt={''}
                    width={'28px'}
                    height={'28px'}
                  />
                )}
              </td>
              <td>
                <span>{rank.Username}</span>
              </td>
              <td>
                <span>{rank.Routes}</span>
              </td>
              <td>
                <span>{rank.Km}</span>
              </td>
              {rank.RoadFailures.map((fail, index) => (
                <td>
                  <span>{fail.value}</span>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      {isEmpty(currentRank) && (
        <Alert variant={'warning'}>{`⚠ ${t('GAME.NOTINRANKING')}`}</Alert>
      )}
    </div>
  );
}

export default RankCard;
