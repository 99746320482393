import { useAuthentication, useToken } from '@iad-os/react-ghost-auth';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { useLayoutEffect, useMemo, useState } from 'react';

export type InterceptorProps = {
  axiosInstance: AxiosInstance;
  onError?: (err: Error) => void;
  timeout?: number;
};

const useInterceptor = (props: InterceptorProps) => {
  const { axiosInstance, onError, timeout } = props;
  const [numberOfCalls, setNumberOfCalls] = useState<number>(0);
  const loading = useMemo(() => numberOfCalls > 0, [numberOfCalls]);

  const { token } = useToken();

  const { refreshToken } = useAuthentication();

  //const token = useMemo<string>(() => import.meta.env.VITE_TOKEN_4_LIFE, []);

  useLayoutEffect(() => {
    if (timeout) {
      axiosInstance.defaults.timeout = timeout;
    }
    const req = axiosInstance.interceptors.request.use(
      config => {
        setNumberOfCalls(prev => prev + 1);
        if (token.access_token) {
          config.headers = {
            ...config.headers,
            Authorization: `Bearer ${token.access_token}`,
          };
        }
        return config;
      },
      error => {
        setNumberOfCalls(prev => prev - 1);
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.request.eject(req);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useLayoutEffect(() => {
    const res = axiosInstance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        const originalRequest: AxiosRequestConfig & { _retry: boolean } =
          error.config;
        if (error?.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          return refreshToken()
            .then(res => {
              originalRequest.headers = {
                ...originalRequest.headers,
                Authorization: `Bearer ${res.access_token}`,
              };
              return axios.create().request(originalRequest);
            })
            .catch(error => {
              onError && onError(error);
              return Promise.reject(error);
            })
            .finally(() => {
              setNumberOfCalls(prev => prev - 1);
            });
        }
        setNumberOfCalls(prev => prev - 1);
        onError && onError(error);
        return Promise.reject(error);
      }
    );
    return () => {
      axios.interceptors.response.eject(res);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    numberOfCalls,
  };
};

export default useInterceptor;
