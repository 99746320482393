import isEmpty from 'lodash.isempty';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from '../../app/auth/authSlice';
import { useRoute } from '../../utils/routes/useRoute';

function HomePage() {
  const user = useSelector(selectUser);
  const { changeRoute } = useRoute();

  useEffect(() => {
    if (user) {
      const { firstPageAccess } = user;
      const router = !isEmpty(firstPageAccess)
        ? firstPageAccess
        : { categoryId: '0', route: 'scene' };
      changeRoute(router.route, { id: router.categoryId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return <div></div>;
}

export default HomePage;
