import React from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../design/form/buttons/primary-button/PrimaryButton';
import SecondaryButton from '../design/form/buttons/secondary-button/SecondaryButton';
import { mergeClass } from '../functions/function';
import Icon from '../icon/Icon';
import { ModalPropsStyle, ModalPropsBtn } from './models/ModalProps';

interface ModalProps {
  className?: string;
  showModal?: boolean;
  text?: { title?: string; body?: string };
  modalStyle: ModalPropsStyle;
  btn?: ModalPropsBtn[];
  templateHeader?: React.ReactNode;
  templateBody?: React.ReactNode;
  templateFooter?: React.ReactNode;
  clickAction?: (action: string) => void;
  closeAction?: () => void;
  closeButton?: boolean;
  size?: 'sm' | 'lg' | 'xl';
}
const ModalMsg = ({
  className,
  showModal = false,
  text,
  btn,
  modalStyle,
  templateHeader,
  templateBody,
  templateFooter,
  clickAction,
  closeAction,
  closeButton = false,
  size,
  ...rest
}: ModalProps) => {
  const { t } = useTranslation();
  const handleClick = (action: string) => {
    clickAction && clickAction(action);
  };

  const mStyle = {
    backgroundColor: modalStyle.style.bgColor,
    color: modalStyle.style.color,
  };

  return (
    <>
      <Modal
        className={mergeClass('shared-modal', className)}
        show={showModal}
        onHide={closeAction}
        keyboard={false}
        size={size}
      >
        <Modal.Header closeButton={closeButton} style={mStyle}>
          <div className="d-flex align-items-center w-100">
            {modalStyle.icon.ico && (
              <Icon
                icon={modalStyle.icon.ico}
                color={modalStyle.icon.color}
                size="26px"
              ></Icon>
            )}
            <Modal.Title className="w-100">
              {templateHeader ? templateHeader : text?.title}
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body style={mStyle}>
          {templateBody ? templateBody : text?.body}
        </Modal.Body>
        <Modal.Footer style={mStyle}>
          {templateFooter ? (
            templateFooter
          ) : (
            <div className="d-flex w-50 justify-content-end">
              {btn?.map((ele, index) => {
                const type =
                  !ele.type || ele.type === '' ? 'primary' : ele.type;
                return type === 'primary' ? (
                  <div className=" mx-2" key={index}>
                    <PrimaryButton
                      onClick={() => handleClick(ele.action)}
                      {...ele.dataType}
                    >
                      {t(ele.label)}
                    </PrimaryButton>
                  </div>
                ) : (
                  <div className=" mx-2" key={index}>
                    <SecondaryButton
                      onClick={() => handleClick(ele.action)}
                      {...ele.dataType}
                    >
                      {t(ele.label)}
                    </SecondaryButton>
                  </div>
                );
              })}
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalMsg;
