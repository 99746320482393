import React from 'react';
import { Container, Row } from 'react-bootstrap';

export type ReportTableProps = {
  list:
    | {
        name: string;
        value: JSX.Element;
      }[]
    | undefined;
};

function ReportTable(props: ReportTableProps) {
  const { list } = props;

  return (
    <Container
      style={{
        width: '100%',
        paddingTop: '4px',
      }}
    >
      <Row xs="1">
        {list?.map((item, index) => {
          const gameReport = item.value;
          return <div key={index}>{gameReport}</div>;
        })}
      </Row>
    </Container>
  );
}

export default ReportTable;
