import http from '../../../../utils/http';
import { SkillsRadarConfig, SkillsRadarResult } from '../models/type';
import { MOCK_SKILL_RADAR_CONFIG, MOCK_SKILL_RADAR_RESULT } from './mockData';

const BASE_URL = `/radars`;

export const getSkillRadarResult = (
  categoryId: string
): Promise<SkillsRadarResult> => {
  return new Promise((resolve, reject) => {
    http
      .get(`/usergamestatus${BASE_URL}/${categoryId}`)
      .then(res => resolve(res))
      .catch(err => reject(MOCK_SKILL_RADAR_RESULT));
  });
};

export const postSkillRadarConfig = (
  skillRadarConfig: SkillsRadarConfig
): Promise<SkillsRadarConfig> => http.post(`${BASE_URL}`, skillRadarConfig);

export const putSkillRadarConfig = (
  categoryId: string,
  skillRadarConfig: SkillsRadarConfig
): Promise<SkillsRadarConfig> =>
  http.put(`${BASE_URL}/${categoryId}`, skillRadarConfig);

export const getSkillRadarConfig = (
  categoryId: string
): Promise<SkillsRadarConfig> => {
  return new Promise((resolve, reject) => {
    http
      .get(`${BASE_URL}/${categoryId}`)
      .then(res => resolve(res))
      .catch(err => reject(MOCK_SKILL_RADAR_CONFIG));
  });
};

export const deleteSkillRadarConfig = (
  categoryId: string
): Promise<SkillsRadarConfig> => http.delete(`${BASE_URL}/${categoryId}`);
