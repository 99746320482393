import isEmpty from 'lodash.isempty';
import React, { useLayoutEffect, useRef, useState } from 'react';
import useWindowSize from '../../../features/auth/fabricJs/useWindowSize';
import useAction, { ActionType } from '../../../hooks/useAction';
import useEvaluation, { EvaluationType } from '../../../hooks/useEvalutation';
type Size = {
  width?: number | string;
  height?: number | string;
};

function AnimationFrame(props: {
  src: string;
  title: string;
  width?: number;
  height?: number;
  evalutations?: EvaluationType[];
}) {
  const [size, setSize] = useState<Size>({
    width: props.width || '100%',
    height: props.height,
  });

  const conteinerRef = useRef<HTMLDivElement | null>(null);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const { size: windowSize } = useWindowSize();

  const actions = useAction();

  const evalutation = useEvaluation(props.evalutations || []);

  useLayoutEffect(() => {
    resize(conteinerRef.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conteinerRef, windowSize]);

  function resize(r: HTMLDivElement | null) {
    const width = r?.offsetWidth || props.width || 0;
    const toHeight = (width * 1080) / 1920;
    const parentH = props.height || windowSize[1] - 20;
    if (toHeight > parentH) {
      const toWidth = (1920 * parentH) / 1080;
      if (size.width !== toWidth || size.height !== parentH) {
        setSize({ width: toWidth, height: parentH });
      }
    } else if (size.height !== toHeight) {
      setSize({ width: '100%', height: toHeight });
    }
  }

  window.onmessage = (event: any) => {
    const { data }: { data?: ActionType } = event;

    if (data?.action === 'trigger') {
      evalutation(data.payload, (err, res) => {
        const message = {
          type: 'feedback',
          data: { success: !!err, res, req: data.payload },
        };
        iframeRef.current?.contentWindow?.postMessage(message, '*');
      });
    } else if (data && data.action && !isEmpty(data.action)) {
      actions[data.action]({
        ...data,
        callback: (err, res) => {
          const message = {
            type: 'feedback',
            data: { success: !!err, res, req: data },
          };
          console.log('message2iFrame', message);
          iframeRef.current?.contentWindow?.postMessage(message, '*');
        },
      });
    }
  };

  return (
    <div ref={resize} className={`d-flex justify-content-center`}>
      <div
        ref={conteinerRef}
        style={{
          width: size.width,
          height: size.height,
        }}
      >
        <iframe
          ref={iframeRef}
          src={props.src}
          title={props.title}
          width="100%"
          height="100%"
          frameBorder="0"
          allow="autoplay"
        />
      </div>
    </div>
  );
}

export default AnimationFrame;
