import { useState } from 'react';

function useStateHistory<T>(initialValue?: T) {
  const [history, setHistory] = useState<T[]>(
    initialValue ? [initialValue] : []
  );
  const [index, setIndex] = useState(initialValue ? 0 : -1);

  const [state, setState] = useState<T | undefined>(initialValue);

  const setNewState = (newState: T) => {
    setState(newState);
    setHistory(history => history.slice(0, index + 1).concat(newState));
    setIndex(index => index + 1);
  };

  function setNewIndex(nIndex: number) {
    if (nIndex >= 0 && nIndex <= history.length - 1) {
      setIndex(nIndex);
      const app = [...history];
      setState(app[nIndex]);
      setHistory(history => history.slice(0, nIndex + 1));
    }
  }
  function undo() {
    if (index > 0) {
      setIndex(i => i - 1);
      setState(history[index - 1]);
    }
  }

  function redo() {
    if (index < history.length - 1) {
      setIndex(i => i + 1);
      setState(history[index + 1]);
    }
  }

  function reset() {
    setState(undefined);
    setHistory([]);
    setIndex(-1);
  }

  function resetWith(state: T) {
    setState(state);
    setHistory([state]);
    setIndex(0);
  }

  return [
    state,
    setNewState,
    { history, index, setIndex: setNewIndex, undo, redo, reset, resetWith },
  ] as [
    T | undefined,
    (state: T) => void,
    {
      history: T[];
      index: number;
      setIndex: (index: number) => void;
      undo: () => void;
      redo: () => void;
      reset: () => void;
      resetWith: (state: T) => void;
    }
  ];
}

export default useStateHistory;
