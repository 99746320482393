import { createValidationsForm } from '../../../utils/forms/useStructureForm';
import { required } from '../../../utils/forms/utils/validationType';

export const validations = () => {
  return createValidationsForm([
    {
      name: 'acl',
      validations: [
        {
          name: required,
          params: { name: 'acl' },
        },
      ],
    },
    {
      name: 'name',
      validations: [
        {
          name: required,
          params: { name: 'name' },
        },
      ],
    },
  ]);
};
