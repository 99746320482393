import ReportLayout from '../features/auth/reports/ReportLayout';
import { SingleRoute } from '../utils/routes/models/routes';
import * as routeName from './routeNameAuthType';

const routeComments: SingleRoute[] = [
  {
    path: '/categories/:categoryId/reports',
    component: ReportLayout,
    name: routeName.report,
  },
];
export default routeComments;
