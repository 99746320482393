import { IThumbType } from '../fabricJs/models/Thumb';
import {
  ITypeLayoutIcons,
  ITypesBgImg,
  ITypesPinImg,
  ITypesPinImgSCene,
  ITypesThumbImport,
  ITypesVideoComplete,
} from '../models/thumbs';

export const thumbType: { [key: string]: IThumbType } = {
  bg: 'BG',
  pin: 'PIN',
};

//map

export const filePinMapImport: ITypesPinImg = {
  svg: 'image/svg+xml',
  gif: 'image/gif',
  png: 'image/png',
};

//scene

export const fileDataImport: ITypesThumbImport = {
  //togli
  svg: 'image/svg+xml',
  png: 'image/png',
};

export const filePinSceneImport: ITypesPinImgSCene = {
  jpeg: 'image/jpeg',
  jpg: 'image/jpg',
  svg: 'image/svg+xml',
  png: 'image/png',
  gif: 'image/gif',
};
export const fileVideoImport: ITypesVideoComplete = {
  mp4: 'video/mp4',
  avi: 'video/x-msvideo',
  mov: 'video/quicktime',
};

export const fileBgImport: ITypesBgImg = {
  jpeg: 'image/jpeg',
  png: 'image/png',
};

export const fileBadgeImport: ITypesPinImgSCene = {
  jpeg: 'image/jpeg',
  jpg: 'image/jpg',
  svg: 'image/svg+xml',
  png: 'image/png',
  gif: 'image/gif',
};
//game

export const fileIconsGameImport: ITypeLayoutIcons = {
  svg: 'image/svg+xml',
};
