import React from 'react';
import { useTranslation } from 'react-i18next';
import { copyObject } from '../../../utils/objUtils';
import { arrayObjSort } from '../../../utils/sortArray';
import { IMenuItem } from './models/menuItem';

const SubMenuItem = ({
  itemList,
  handleClickSubLevel,
  activesubItem,
}: {
  itemList: IMenuItem[];
  handleClickSubLevel: (route: string) => void;
  activesubItem: string;
}) => {
  const { t } = useTranslation();

  const menu = copyObject(itemList);
  const copyMenu = arrayObjSort(menu, 'orderNumber');

  return (
    <>
      {copyMenu.map((ele: IMenuItem) => {
        return (
          <li
            key={ele._id}
            onClick={() => handleClickSubLevel(ele.route)}
            title={t(ele.label)}
          >
            <i className={`${ele.icon}`}></i>
            <span
              className={`sub-item ${
                activesubItem === ele.route ? 'active' : ''
              }`}
            >
              {t(ele.label)}
            </span>
          </li>
        );
      })}
    </>
  );
};

export default SubMenuItem;
