import oro from './../../../assets/img/ranking/oro.png';
import argento from './../../../assets/img/ranking/argento.png';
import bronzo from './../../../assets/img/ranking/bronzo.png';
import assente from './../../../assets/img/ranking/smoove_0.png';
import basso from './../../../assets/img/ranking/smoove_1.png';
import medio from './../../../assets/img/ranking/smoove_2.png';
import alto from './../../../assets/img/ranking/smoove_3.png';
import altissimo from './../../../assets/img/ranking/smoove_4.png';

export const rankIcon = [
  { rank: 0, url: assente, description: 'assente' },
  { rank: 1, url: basso, description: 'basso' },
  { rank: 2, url: medio, description: 'medio' },
  { rank: 3, url: alto, description: 'alto' },
  { rank: 4, url: altissimo, description: 'altissimo' },
];

export const cupImages = [oro, argento, bronzo];
