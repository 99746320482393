import React from 'react';

interface ISidebar {
  isOpen: boolean;
  toolbarLeft?: React.ReactNode;
  children: React.ReactNode;
}
const Sidebar = (props: ISidebar) => {
  const { isOpen, toolbarLeft, children } = props;
  const open = isOpen && 'open';

  return (
    <div className={`sidebar-container ${open}`}>
      <div className={`container-sidebar ${open}`}>
        {toolbarLeft && <div>{toolbarLeft}</div>}
        {isOpen && children}
      </div>
    </div>
  );
};

export default Sidebar;
