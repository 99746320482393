import { ITabs } from '../../../../components/shared/design/tabs/models/tabsModels';

export const listTabNamePin = {
  pin: 'pin',
  check: 'check',
};

export const listTabsPin: ITabs[] = [
  {
    keyTab: listTabNamePin.pin,
    title: 'APP.PIN',
  },
  {
    keyTab: listTabNamePin.check,
    title: 'CHECK.CHECK',
  },
];
