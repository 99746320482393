import React from 'react';
//@ts-ignore
import { SpriteAnimator } from 'react-sprite-animator';
import ThumbItem from '../../../../../components/shared/custom/thumb/ThumbItem';

interface IItemThumbAvailable {
  thumb: any;
  clickAddThumb: () => void;
  clickRemoveThumb: () => void;
  altString?: string;
}

const ItemThumbAvailable = (props: IItemThumbAvailable) => {
  const { thumb, clickAddThumb, clickRemoveThumb, altString } = props;
  const getSizeScale = () => {
    // TODO da sistemare
    if (thumb.image.iconSize.width < 150) {
      return 1;
    }
    if (thumb.image.iconSize.width < 350) {
      return 2;
    }
    if (thumb.image.iconSize.width > 350) {
      return 4;
    }
  };

  return (
    <div className="">
      {thumb.type?.includes('sprite') ? (
        <>
          <ThumbItem
            url={thumb.image.iconUrl}
            clickActionItem={clickAddThumb}
            clickRemoveItem={clickRemoveThumb}
          >
            <SpriteAnimator
              scale={getSizeScale()}
              className="sprite-animate"
              sprite={thumb.image.iconUrl}
              width={thumb.image.iconSize.width}
              height={thumb.image.iconSize.height}
              fps={thumb.image.sprite?.fps}
              shouldAnimate={!!thumb.image.sprite}
            />
          </ThumbItem>
        </>
      ) : (
        <>
          <ThumbItem
            url={thumb.image ? thumb.image.iconUrl : thumb.badgeImg}
            clickActionItem={clickAddThumb}
            clickRemoveItem={clickRemoveThumb}
            altString={altString}
          ></ThumbItem>
        </>
      )}
    </div>
  );
};

export default ItemThumbAvailable;
