import React from 'react';
import { usePermissions } from '../../app/auth/usePemissions';

interface IsPermissionProps {
  permissions: string[];
  children: React.ReactNode;
  or?: boolean;
}
const IsPermission = ({
  permissions,
  children,
  or = false,
}: IsPermissionProps) => {
  const { issetPermission } = usePermissions();
  const isPermissions = issetPermission(permissions, or);
  return <>{isPermissions ? children : null}</>;
};

export default IsPermission;
