import React from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { ICategory } from '../../../models/Categories';
import { setValue } from '../../utils';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type ReportsAccordionProps = {
  title: string;
  data: any[];
  fields: { [key in string]: string };
  index: number;
  categories: ICategory[] | undefined;
};

function ReportsAccordion(props: ReportsAccordionProps) {
  const { title, data, fields, index, categories } = props;

  return (
    data && (
      <>
        <Accordion
          className={`row-gamereport-item ${
            index % 2 > 0 ? 'bgwhite ' : 'bggrey '
          } pointer`}
          key={`acc-${index}`}
        >
          <Card style={{ backgroundColor: 'transparent', border: '0px' }}>
            <Accordion.Toggle
              as={Card.Header}
              style={{
                backgroundColor: 'transparent',
                padding: '0px',
                textAlign: 'start',
                borderBottom: '0px',
              }}
              eventKey="0"
              key={`togg-${index}`}
            >
              {data.length > 0 && (
                <b>
                  <FontAwesomeIcon icon={faAngleRight} />
                  {` ${title}`}
                </b>
              )}
              {data.length === 0 && (
                <>
                  <FontAwesomeIcon icon={faAngleRight} />
                  {` ${title}`}
                </>
              )}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0" key={`coll-${index}`}>
              <Card.Body>
                {data.map((item, index) => (
                  <Card
                    key={`card-${index}`}
                    className={'row-gamereport-item p-0 m-1'}
                    style={{ backgroundColor: 'transparent' }}
                  >
                    <Card.Body className={'p-0'} key={`card-body-${index}`}>
                      {Object.entries(item).map(([key, value], i) => (
                        <Row key={`row-${i}`}>
                          <Col xs={12} style={{ textAlign: 'start' }}>
                            <b>{fields[key]}</b>
                            {` : `}
                            {`${setValue(value, key, categories)}`}
                          </Col>
                        </Row>
                      ))}
                    </Card.Body>
                  </Card>
                ))}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </>
    )
  );
}
export default ReportsAccordion;
