import omit from 'lodash.omit';
import http from '../../../../utils/http';
import { AnimateData } from './../models/types';

const BASE_URL = `/animates`;

export const getAnimate = (idScene: string): Promise<AnimateData> =>
  http.get(`${BASE_URL}/${idScene}/animate`);

export const getAnimateToPlay = (idScene: string): Promise<AnimateData> =>
  http.get(`${BASE_URL}/${idScene}/play`);

export const getAnimates = (
  idScene: string,
  idAnimate: string
): Promise<AnimateData> =>
  http.get(`${BASE_URL}/${idScene}/animate/${idAnimate}`);

export const postAnimate = (
  idScene: string,
  animate: AnimateData
): Promise<{ _id: string }> =>
  http.post(`${BASE_URL}/${idScene}/animate`, animate);

export const putAnimate = (
  idScene: string,
  idAnimate: string,
  animate: AnimateData
): Promise<AnimateData> => {
  const newAnimation = omit(animate, ['_id', 'animateId']);
  return http.put(`${BASE_URL}/${idScene}/animate/${idAnimate}`, newAnimation);
};

export const deleteAnimate = (
  idAnimate: string,
  idScene: string
): Promise<void> => http.delete(`${BASE_URL}/${idScene}/animate/${idAnimate}`);

export const getGameQuestionAnimates = (): Promise<AnimateData[]> =>
  http.get(`${BASE_URL}`);
