import { SingleRoute } from '../utils/routes/models/routes';
import * as routeName from './routeNameAuthType';

const routeMaps: SingleRoute[] = [
  {
    path: '/',
    component: 'features/auth/projectMap/ProjectMap',
    name: routeName.projectMap,
  },
];
export default routeMaps;
