import { SingleRoute } from '../utils/routes/models/routes';
import * as routeName from './routeNameAuthType';

const routeScene: SingleRoute[] = [
  {
    path: '/categories/:id',
    component: 'features/auth/fabricJs/SceneSample',
    name: routeName.scene,
  },
];
export default routeScene;
