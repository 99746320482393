import * as routeName from './routeNameAuthType';
import routeMap from './map';
import routeScene from './scene';
import routeGame from './game';
import routeMenu from './menu';
import { Routes, SingleRoute } from '../utils/routes/models/routes';
import routeResources from './resources';
import routeMicroGame from './microgame';
import routeComments from './comments';
import routeAnimations from './animations';
import HomePage from '../features/auth/HomePage';
import routeMetabase from './metabase';
import BASE_PATHS from './basePaths';
import routeWeblinks from './weblinks';
import routeCycleMaps from './cyclemap';
import routeGameReport from './gameReport';
import routeRadar from './radar';
import ranking from './ranking';

const BASE_PATH = BASE_PATHS.PROTECTED;

const routesList: {
  list: { path: string; childrenRoute: SingleRoute[] }[];
} = {
  list: [
    {
      path: `${BASE_PATH}/maps`,
      childrenRoute: routeMap,
    },
    {
      path: `${BASE_PATH}/links`,
      childrenRoute: routeResources,
    },
    {
      path: `${BASE_PATH}/microgames`,
      childrenRoute: routeMicroGame,
    },
    {
      path: `${BASE_PATH}/scenes`,
      childrenRoute: routeScene,
    },
    {
      path: `${BASE_PATH}/games`,
      childrenRoute: routeGame,
    },
    {
      path: `${BASE_PATH}/menu`,
      childrenRoute: routeMenu,
    },
    {
      path: `${BASE_PATH}/comments`,
      childrenRoute: routeComments,
    },
    {
      path: `${BASE_PATH}/animations`,
      childrenRoute: routeAnimations,
    },
    {
      path: `${BASE_PATH}`,
      childrenRoute: [
        {
          path: '',
          component: HomePage,
          name: 'HomePage',
        },
      ],
    },
    {
      path: `${BASE_PATH}/metabases`,
      childrenRoute: routeMetabase,
    },
    {
      path: `${BASE_PATH}/weblinks`,
      childrenRoute: routeWeblinks,
    },
    {
      path: `${BASE_PATH}/cyclemaps`,
      childrenRoute: routeCycleMaps,
    },
    {
      path: `${BASE_PATH}/gamereport`,
      childrenRoute: routeGameReport,
    },
    {
      path: `${BASE_PATH}/radars`,
      childrenRoute: routeRadar,
    },
    {
      path: `${BASE_PATH}/ranking`,
      childrenRoute: ranking,
    },
  ],
};

const routes: Routes = {
  list: routesList.list.reduce(
    (arr: SingleRoute[], routeGroup) => [
      ...arr,
      ...routeGroup.childrenRoute.map(ele => ({
        ...ele,
        path: routeGroup.path + ele.path,
      })),
    ],
    []
  ),
  redirect: routeName.redirectPath,
  basepath: BASE_PATHS.PROTECTED,
};

export default routes;
