import React from 'react';
import { useTranslation } from 'react-i18next';
import Accordion from '../../../../../../components/shared/accordion/Accordion';
import { IPerimeterBadgeActive } from '../models/serviceResponse';
import SingleBadge from './SingleBadge';

interface IBadgeContainer {
  cardTitle: string;
  listBadges: IPerimeterBadgeActive[];
}
const BadgeContainer = (props: IBadgeContainer) => {
  const { cardTitle, listBadges } = props;
  const { t } = useTranslation();
  return (
    <>
      <div className="badges-row-info-container">
        <div className="row-info-title">{cardTitle}</div>
      </div>
      <div className="mt-3">
        {listBadges &&
          listBadges.length > 0 &&
          listBadges.map(perimeter => {
            return (
              <div
                key={perimeter.category.id}
                className="accordion-badges-container"
              >
                <Accordion
                  title={t(perimeter.category.name)}
                  show={true}
                  className="badges-accordion"
                >
                  <div className="badges-list-container">
                    {perimeter.badges.length > 0 &&
                      perimeter.badges.map(badge => {
                        return (
                          <SingleBadge key={badge.badge.id} badgeInfo={badge} />
                        );
                      })}
                  </div>
                </Accordion>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default BadgeContainer;
