import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PrimaryButton from '../../../../../components/shared/design/form/buttons/primary-button/PrimaryButton';
import { FormElementModelNew } from '../../../../../utils/forms/models/FormElementModel';
import { UseFormSubmitModel } from '../../../../../utils/forms/models/UseFormModel';
import { useForm } from '../../../../../utils/forms/useForm';
import {
  createStructureForm,
  createValidationsForm,
} from '../../../../../utils/forms/useStructureForm';
import { required } from '../../../../../utils/forms/utils/validationType';
import { UserPreference } from '../../../menu/menuLevels/userManagement/types';
import { ICategory } from '../../../models/Categories';

export type UseReportsFormProps<T> = {
  submitHandler: UseFormSubmitModel<T>;
  categories: ICategory[];
  users: UserPreference[];
};

function useReportsForm<T>(props: UseReportsFormProps<T>) {
  const { submitHandler, categories, users } = props;

  const { t } = useTranslation();

  const userOptions = useMemo(
    () =>
      users.map(({ _id, name, surname }) => ({
        value: _id,
        label: `${name} ${surname}`,
      })),
    [users]
  );

  const form = useForm<T>(
    submitHandler,
    createValidationsForm([
      {
        name: 'username',
        validations: [
          {
            name: required,
            params: { name: 'username' },
          },
        ],
      },
      {
        name: 'categoryId',
        validations: [
          {
            name: required,
            params: { name: 'categoryId' },
          },
        ],
      },
    ])
  );

  const { dataForm } = form;

  const searchFormStructure: FormElementModelNew[] = createStructureForm([
    {
      typeElement: 'select',
      label: '',
      name: `username`,
      multiply: false,
      dataElement: {
        placeholder: t('GAMEREPORT.SELECTPARTICIPANT'),
        type: 'text',
      },
      options: [
        {
          value: '-1',
          label: t('GAMEREPORT.SELECTPARTICIPANT'),
        },
        ...userOptions,
      ],
      col: { xs: 12, md: 6 },
    },
    {
      typeElement: 'input',
      label: '',
      name: `depth`,
      dataElement: {
        type: 'number',
        placeholder: t('CATEGORYMANAGEMENT.DEPTH'),
      },
      col: { xs: 4, md: 3 },
    },
  ]);

  const treeViewStructure: FormElementModelNew[] = createStructureForm(
    [
      {
        typeElement: 'treeview',
        label: t('APP.FORM.CATEGORY'),
        name: `categoryId`,
        dataElement: {
          type: 'text',
          values: categories,
        },
        col: { xs: 12 },
      },
      {
        typeElement: 'input',
        name: 'button',
        col: {
          xs: 12,
          style: {
            marginTop: '25px',
            justifyContent: 'center',
            display: 'flex',
          },
        },
        template: (children: ReactNode) => {
          return <PrimaryButton>{t('APP.SEARCH')}</PrimaryButton>;
        },
      },
    ],
    dataForm
  );

  return {
    searchFormStructure,
    treeViewStructure,
    form,
  };
}

export default useReportsForm;
