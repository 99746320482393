import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import omit from 'lodash.omit';
import {
  addErrorMessage,
  addSuccessMessage,
} from '../../../app/errors/errorSlice';
import { AppThunk, RootState } from '../../../app/store';
import i18n from '../../../config/i18n';
import { copyObject } from '../../../utils/objUtils';
import { IMessageContent } from '../menu/menuLevels/wallManage/model/config';
import { getMessageByView } from '../menu/menuLevels/wallManage/services/wallManage.service';
import { deleteFile, getFiles } from '../services/upload.service';
import { listTabNameComments } from './CommentsData';
import { IBackgroundComments } from './models/backgroundComments';
import { IMessageManagement } from './models/config';
import {
  deleteBgForComments,
  editBgForComments,
  getBgCommentsById,
  postMessage,
  saveBgForComments,
} from './services/comments.service';

interface ICommentsSlice {
  tabUploadKeyComments: string;
  commentsSelectedBg?: IBackgroundComments;
  commentsBg?: IBackgroundComments;
  listBackgroundCommentsAvailable: IBackgroundComments[];
  commentsList: IMessageContent[];
  commentManagement?: IMessageManagement;
  currentPage: number;
}

const initialState: ICommentsSlice = {
  tabUploadKeyComments: listTabNameComments.background,
  commentsSelectedBg: undefined,
  commentsBg: undefined,
  listBackgroundCommentsAvailable: [],
  commentsList: [],
  commentManagement: undefined,
  currentPage: 0,
};

export const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    setTabUploadKeyComments: (state, action: PayloadAction<string>) => {
      state.tabUploadKeyComments = action.payload;
    },
    setCommentsSelectedBg: (
      state,
      action: PayloadAction<IBackgroundComments>
    ) => {
      state.commentsSelectedBg = action.payload;
    },
    setCommentsBg: (state, action: PayloadAction<IBackgroundComments>) => {
      state.commentsBg = action.payload;
    },
    setCommentsList: (state, action: PayloadAction<IMessageContent[]>) => {
      state.commentsList = action.payload;
    },
    setCommentManagement: (
      state,
      action: PayloadAction<IMessageManagement>
    ) => {
      state.commentManagement = action.payload;
    },
    setListBackgroundComments: (
      state,
      action: PayloadAction<IBackgroundComments[]>
    ) => {
      state.listBackgroundCommentsAvailable = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    resetCommentsSelectedBg: state => {
      state.commentsSelectedBg = undefined;
    },
    resetCommentsBg: state => {
      state.commentsBg = undefined;
    },
    resetCommentsList: state => {
      state.commentsList = [];
    },
    resetCommentManagement: state => {
      state.commentManagement = undefined;
    },
    resetCurrentPage: state => {
      state.currentPage = 0;
    },
    removeBgCommentsAvailable: (
      state,
      action: PayloadAction<IBackgroundComments>
    ) => {
      state.listBackgroundCommentsAvailable =
        state.listBackgroundCommentsAvailable.filter(
          img => img.url !== action.payload.url
        );
    },
    addAvailableBackgroundCommentsList: (
      state,
      action: PayloadAction<IBackgroundComments>
    ) => {
      state.listBackgroundCommentsAvailable = [
        ...state.listBackgroundCommentsAvailable.filter(
          img => img.url !== action.payload.url
        ),
        action.payload,
      ];
    },
    addCommentInList: (state, action: PayloadAction<IMessageContent>) => {
      state.commentsList = [
        action.payload,
        ...state.commentsList.filter(
          comment => comment.id !== action.payload.id
        ),
      ];
    },
    addNextCommentsInList: (
      state,
      action: PayloadAction<IMessageContent[]>
    ) => {
      state.commentsList = [...state.commentsList, ...action.payload];
    },
  },
});

export const {
  setTabUploadKeyComments,
  setCommentsSelectedBg,
  setCommentsBg,
  setCommentsList,
  setCommentManagement,
  setListBackgroundComments,
  setCurrentPage,
  resetCommentsSelectedBg,
  resetCommentsBg,
  resetCommentsList,
  resetCurrentPage,
  removeBgCommentsAvailable,
  addAvailableBackgroundCommentsList,
  addCommentInList,
  addNextCommentsInList,
} = commentsSlice.actions;

export const getBgComments =
  (id: string): AppThunk =>
  dispatch => {
    getBgCommentsById(id).then(
      res => {
        dispatch(setCommentsBg(res[0]));
      },
      err =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.LOADINGBGSSAVAILABLE'),
          })
        )
    );
  };

export const addBgInComments =
  (background: IBackgroundComments): AppThunk =>
  dispatch => {
    const backgroundConId = {
      ...background,
      id_temp: (Math.random() * 10).toString(),
    };
    dispatch(setCommentsSelectedBg(backgroundConId));
  };

export const deleteCommentsBg =
  (commentsId: string): AppThunk =>
  (dispatch, getState) => {
    const bgComments = getState().comments.commentsBg;
    bgComments &&
      deleteBgForComments(bgComments, commentsId).then(
        res => {
          dispatch(resetCommentsBg());
          dispatch(resetCommentsSelectedBg());
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETEBG'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETEBG'),
            })
          )
      );
  };

export const saveCommentsBg =
  (id: string, selectedBg: IBackgroundComments): AppThunk =>
  (dispatch, getState) => {
    const bgComments = getState().comments.commentsBg;
    const copyBg = copyObject(selectedBg);
    if (selectedBg.id_temp && !bgComments) {
      delete copyBg.id_temp;
      saveBgForComments(selectedBg, id).then(
        res => {
          copyBg._id = res._id;
          dispatch(setCommentsBg(copyBg));
          dispatch(resetCommentsSelectedBg());
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.SAVEBG'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.SAVEBG'),
            })
          )
      );
    }
    if (selectedBg.id_temp && bgComments) {
      const editBg = { ...selectedBg, _id: bgComments._id };
      editBgForComments(editBg, id).then(
        res => {
          dispatch(setCommentsBg(editBg));
          dispatch(resetCommentsSelectedBg());
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.EDITBG'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.EDITBG'),
            })
          )
      );
    }
  };

export const deleteBgAvailable =
  (bg: IBackgroundComments): AppThunk =>
  dispatch => {
    const documentName = 'backgrounds/' + bg.url.split('/').pop();
    documentName &&
      deleteFile(documentName).then(
        data => {
          dispatch(removeBgCommentsAvailable(bg));
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETEBGAVAILABLE'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETEBGAVAILABLE'),
            })
          )
      );
  };

export const sendMessage =
  (id: number, message: string): AppThunk =>
  dispatch => {
    postMessage(id, message).then(
      res => {
        dispatch(addCommentInList(res));
        dispatch(
          addSuccessMessage({
            title: i18n.t('SUCCESSMSG.SAVEMESSAGE'),
          })
        );
      },
      err =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.SAVEMESSAGE'),
          })
        )
    );
  };

export const getListMessages =
  ({
    id,
    page = 0,
    size = 5,
  }: {
    id: string;
    page?: number;
    size?: number;
  }): AppThunk =>
  dispatch => {
    getMessageByView({ id, page, size }).then(
      (res: IMessageManagement) => {
        res.content && dispatch(setCommentsList(res.content));
        let commentManagement: IMessageManagement = omit(res, ['content']);
        dispatch(setCommentManagement(commentManagement));
        dispatch(setCurrentPage(page + 1));
      },
      err =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.LOADMESSAGES'),
          })
        )
    );
  };

export const getNextMessages =
  ({
    id,
    page = 0,
    size = 5,
  }: {
    id: string;
    page?: number;
    size?: number;
  }): AppThunk =>
  dispatch => {
    getMessageByView({ id, page, size }).then(
      (res: IMessageManagement) => {
        res.content && dispatch(addNextCommentsInList(res.content));
        let commentManagement: IMessageManagement = omit(res, ['content']);
        dispatch(setCommentManagement(commentManagement));
        dispatch(setCurrentPage(page + 1));
      },
      err =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.LOADMESSAGES'),
          })
        )
    );
  };

export const getListBgAvailableComments = (): AppThunk => dispatch => {
  getFiles({ pathName: 'backgrounds' }).then(
    data => {
      const newList = data.map(bg => {
        return {
          url: bg.url,
          name: bg.originalfilename,
          initialHeight: 1920,
          initialWidth: 1080,
          type: bg.mimetype,
        };
      });
      dispatch(setListBackgroundComments(newList));
    },
    err =>
      dispatch(
        addErrorMessage({
          title: i18n.t('ERRORSMSG.LOADINGBGSSAVAILABLE'),
        })
      )
  );
};

export const selectTabUploadKeyComments = (state: RootState) =>
  state.comments.tabUploadKeyComments;
export const selectCommentsSelectedBg = (state: RootState) =>
  state.comments.commentsSelectedBg;
export const selectCommentsBg = (state: RootState) => state.comments.commentsBg;
export const selectListBackgroundCommentsAvailable = (state: RootState) =>
  state.comments.listBackgroundCommentsAvailable;
export const selectCommentsList = (state: RootState) =>
  state.comments.commentsList;
export const selectCommentManagement = (state: RootState) =>
  state.comments.commentManagement;
export const selectCurrentPage = (state: RootState) =>
  state.comments.currentPage;

export const selectBackgroundNew = createSelector(
  selectCommentsSelectedBg,
  selectCommentsBg,
  (selectedBg, savedBg) => {
    let newBg: IBackgroundComments;
    if (selectedBg && selectedBg.id_temp) {
      newBg = copyObject(selectedBg);
      return newBg;
    }
    return savedBg;
  }
);

export default commentsSlice.reducer;
