import React, { useMemo } from 'react';
import { FormControlProps } from 'react-bootstrap/FormControl';
import { useTranslation } from 'react-i18next';
import MultiSelect from 'react-multi-select-component';
import { mergeClass } from '../../../../functions/function';

interface FormProps extends FormControlProps {
  error?: boolean;
  options?: { label: string; value: any }[];
  multiply?: boolean;
  selectedValue?: any[];
  changeSelect?: (value?: any) => void;
  value?: any[];
}

const MultiSelectCustom: React.FC<
  FormProps & React.HTMLProps<HTMLInputElement>
> = ({
  children,
  className,
  options = [],
  error = false,
  multiply = false,
  changeSelect = () => {},
  value = '[]',
  selectedValue,
  disabled,
  ...rest
}) => {
  const { t } = useTranslation();
  const parseValue =
    typeof selectedValue === 'string' ? JSON.parse(selectedValue) : [];
  const valueMulti = useMemo(() => {
    return Array.isArray(parseValue)
      ? options.filter(
          ele =>
            parseValue
              .map((ele: any) =>
                typeof ele === 'number' ? ele.toString() : ele
              )
              .indexOf(ele.value) !== -1
        )
      : [];
  }, [options, parseValue]);

  let changeHandler = (data: { label: string; value: any }[]) => {
    changeSelect(JSON.stringify(data.map(ele => ele.value)));
  };

  return (
    <MultiSelect
      className={mergeClass(
        `multi-select ${error ? 'error' : ''}`,
        disabled ? 'disabled' : ''
      )}
      {...rest}
      options={options}
      value={valueMulti}
      onChange={changeHandler}
      labelledBy={'Select'}
      overrideStrings={{
        selectSomeItems: `${t('APP.FORM.SELECTSOMEITEMS')}...`,
        allItemsAreSelected: t('APP.FORM.ALLSELECTED'),
        selectAll: t('APP.FORM.SELECTALL'),
        search: t('APP.SEARCH'),
      }}
    />
  );
};

export default MultiSelectCustom;
