import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Accordion from '../../components/shared/accordion/Accordion';
import { mergeClass } from '../../components/shared/functions/function';
import Icon from '../../components/shared/icon/Icon';
import FormElementsArrayRow from './FormElementsArrayRow';
import { ValueName } from './models/DataForm';
import {
  deleteElementByArrayModel,
  FormElementModelNew,
  templateRowModel,
} from './models/FormElementModel';
import {
  getDataFormArray,
  getDataFormArrayName,
  getPreString,
  getPropertyArray,
  splitArrayByDivisor,
} from './useStructureForm';

const FormElementsArray = ({
  data,
  dataFormatted = {},
  errors,
  isSubmit = false,
  changeValue = () => {},
  dataForm,
  onBlur = () => {},
  deleteElementByArray,
  className,
}: {
  data: {
    pre?: string;
    form?: FormElementModelNew[] | FormElementModelNew;
    templateRow?: templateRowModel;
    name?: string;
    label?: string;
  };
  dataFormatted?: any;
  isSubmit?: boolean;
  errors: { [key: string]: any };
  changeValue?: ({ value, name }: ValueName) => void;
  dataForm: { [key: string]: any };
  addElement?: (data: string) => void;
  onBlur?: ({ value, name }: ValueName) => void;
  deleteElementByArray?: deleteElementByArrayModel;
  className?: string;
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);

  const dataStructure: any = getDataFormArray(
    data,
    data.pre || '',
    dataFormatted
  );
  const addElementHandler = () => {
    const dataInfo =
      getDataFormArrayName(data, data.pre || '', dataFormatted) || [];
    if ((data.form as any)?.typeElement) {
      changeValue({
        value: undefined,
        name:
          getPreString(data.pre) +
          splitArrayByDivisor([data.name, dataInfo.length]),
      });
    } else {
      (data.form as FormElementModelNew[])?.forEach(ele =>
        changeValue({
          value: undefined,
          name:
            getPreString(data.pre) +
            splitArrayByDivisor([data.name, dataInfo.length, ele.name]),
        })
      );
    }
  };
  const removeElementHandler = (index: number) => {
    deleteElementByArray &&
      deleteElementByArray(
        getPropertyArray(data.pre || '', data.name || ''),
        index
      );
  };

  return (
    <>
      <Accordion
        title={data.label || ''}
        clickHandler={() => setShow(!show)}
        className={mergeClass('form-accordion', className)}
        labelBold={dataStructure.length > 0 ? 'label-bold' : undefined}
      >
        <Row style={{ display: show ? undefined : 'none' }}>
          <Col xs="12">
            {dataStructure.map((el: any, index: number) => (
              <FormElementsArrayRow
                key={el.name + index}
                data={el}
                dataFormatted={dataFormatted}
                templateRow={data.templateRow}
                changeValue={changeValue}
                errors={errors}
                deleteElementByArray={deleteElementByArray}
                removeElement={() => removeElementHandler(el.index)}
                isSubmit={isSubmit}
                onBlur={onBlur}
                dataForm={dataForm}
              />
            ))}
          </Col>
        </Row>

        <Row className="justify-content-end">
          <Col xs="auto">
            <Icon
              icon="add"
              label={t('APP.ADD')}
              title={t('APP.ADD')}
              size="20px"
              colorStyle="#01a381"
              clickAction={addElementHandler}
            ></Icon>
          </Col>
        </Row>
      </Accordion>
    </>
  );
};

export default FormElementsArray;
