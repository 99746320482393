import Ranking from '../features/auth/ranking';
import { SingleRoute } from '../utils/routes/models/routes';
import * as routeName from './routeNameAuthType';

const uri: string = '';

const routeRanking: SingleRoute[] = [
  {
    path: `${uri}`,
    component: Ranking,
    name: routeName.ranking,
  },
];
export default routeRanking;
