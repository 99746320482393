import React from 'react';

interface ICardShadow {
  className?: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
  clickAction?: () => void;
}

const CardShadow = ({
  children,
  className,
  style,
  clickAction,
}: ICardShadow) => {
  return (
    <div
      className={`card-shadow ${className}`}
      style={style}
      onClick={clickAction}
    >
      {children}
    </div>
  );
};

export default CardShadow;
