import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getUserLogged } from '../../features/auth/services/user.service';
import { AppThunk, RootState } from '../store';
import { issetPermissions, permissionType } from './../rolePermissions';
import { AuthState } from './model/Auth';
import { IMe } from './model/User';

const initialState: AuthState = {
  user: undefined,
  isLogged: undefined,
  token: undefined,
  permissions: [],
  isEditMode: false,
  welcomeMessage: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLogged: (state, action: PayloadAction<boolean>) => {
      state.isLogged = action.payload;
    },
    setUser: (state, action: PayloadAction<IMe>) => {
      state.user = action.payload;
    },
    destroyUser: state => {
      state.user = undefined;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    destroyToken: state => {
      state.token = undefined;
    },
    setPermissions: (state, action: PayloadAction<string[]>) => {
      state.permissions = action.payload;
    },
    setIsEditMode: (state, action: PayloadAction<boolean>) => {
      state.isEditMode = issetPermissions(state.permissions, [
        permissionType.ROLE_ADMIN,
      ])
        ? action.payload
        : false;
    },
    setWelcomeMessage: (state, action: PayloadAction<boolean>) => {
      state.welcomeMessage = action.payload;
    },
  },
});

export const {
  setUser,
  destroyUser,
  setToken,
  setPermissions,
  setIsLogged,
  setIsEditMode,
  destroyToken,
  setWelcomeMessage,
} = authSlice.actions;

export const getUserLoggedIn = (): AppThunk => dispatch => {
  getUserLogged().then(res => {
    dispatch(setUser(res));
    dispatch(setPermissions(res.ROLES));
    dispatch(setWelcomeMessage(true));
  });
};

export const logoutAction = (): AppThunk => dispatch => {
  localStorage.removeItem('token');
  dispatch(destroyUser());
  dispatch(setPermissions([]));
  dispatch(destroyToken());
  dispatch(setIsLogged(false));
  dispatch(setWelcomeMessage(false));
};
export const selectUser = (state: RootState) => state.auth.user;
export const selectIsLogged = (state: RootState) => state.auth.isLogged;
export const selectUserPermissions = (state: RootState) =>
  state.auth.permissions;
export const selectIsEditMode = (state: RootState) => state.auth.isEditMode;
export const selectWelcomeMessage = (state: RootState) =>
  state.auth.welcomeMessage;

export default authSlice.reducer;
