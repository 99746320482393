import { useState, useEffect } from 'react';
import moment from 'moment';

function checkForIOS() {
  const today = moment().toDate();
  const lastPrompt = moment(localStorage.getItem('installPrompt'));
  const days = moment(today).diff(lastPrompt, 'days');
  const ua = window.navigator.userAgent;
  console.log(ua);
  const webkit = !!ua.match(/WebKit/i);
  const isIPad = !!ua.match(/iPad/i);
  const isIPhone = !!ua.match(/iPhone/i);
  const isIOS = isIPad || isIPhone;
  const isSafari = isIOS && webkit && !ua.match(/CriOS/i);

  const prompt =
    (isNaN(days) || days > 30) && isIOS && isSafari && isIOSInAppBrowser();

  //  if (prompt && 'localStorage' in window) {
  //    localStorage.setItem('installPrompt', today.toDateString());
  //  }

  return { isIOS, isSafari, prompt };
}

function isIOSInAppBrowser() {
  // Check if device is iPad, iPhone or iPod (this bit is naive and should probably check additional stuff)
  if (Boolean(window.navigator.userAgent.match(/iPad|iPhone|iPod/)) === false)
    return false;

  // Check if navigator is standalone but display-mode isn't
  if (
    window.matchMedia('(display-mode: standalone)').matches === false &&
    window.matchMedia('(display-mode: fullscreen)').matches === false
  ) {
    return true;
  } else {
    return false;
  }
}

function useIsIOS() {
  const [isIOS, setIsIOS] = useState(
    {} as {
      isIOS: boolean;
      isSafari: boolean;
      prompt: boolean;
    }
  );

  useEffect(() => {
    setIsIOS(checkForIOS());
    return () => console.log('CLEANUP INSTALL PROMPT', isIOS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isIOS;
}

export default useIsIOS;
