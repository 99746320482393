import { clearEmptyProperty } from '../requestUtils';

export default (config: any) => {
  if (
    config.data &&
    config.headers['Content-Type']?.includes('application/json')
  ) {
    config.data = clearEmptyProperty(config.data);
  }

  return config;
};
