import React from 'react';

interface IToolbar {
  isOpen: boolean;
  leftTemplate?: React.ReactNode;
  centerTemplate?: React.ReactNode;
  rightTemplate?: React.ReactNode;
}

const ToolbarTop = (props: IToolbar) => {
  const { isOpen, leftTemplate, centerTemplate, rightTemplate } = props;

  return (
    <div className={`toolbar-top ${isOpen && 'open'}`}>
      <div className="left-content">{leftTemplate && leftTemplate}</div>
      <div className="center-content">{centerTemplate && centerTemplate}</div>
      <div className="right-content">{rightTemplate && rightTemplate}</div>
    </div>
  );
};

export default ToolbarTop;
