import { required } from '../../../../../utils/forms/utils/validationType';

export const validations = () => {
  return [
    {
      name: 'url',
      validations: [
        {
          name: required,
          params: { name: 'url' },
        },
      ],
    },
  ];
};
