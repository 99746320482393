import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { setIsEditMode } from '../../../app/auth/authSlice';
import {
  addErrorMessage,
  addSuccessMessage,
} from '../../../app/errors/errorSlice';
import { AppThunk, RootState } from '../../../app/store';
import i18n from '../../../config/i18n';
import { copyObject } from '../../../utils/objUtils';
import { addAvailableBackgroundGameList } from '../game/gamePlatform/gamePlatformSlice';
import { deleteFile, getFiles } from '../services/upload.service';
import { IBackgroundResource } from './model/backgroundResource';
import { IFileResource } from './model/fileResource';
import { IResource, IResourceBackground } from './model/layout';
import { listTabNameResource } from './ResourceData';
import {
  deleteBgForResource,
  deleteResourceSelected,
  editBgForResource,
  getBgResourceById,
  getHyperlinkByCategoryType,
  getResourceById,
  saveBgForResource,
  saveResourceById,
  updateResourceById,
} from './services/resources.service';

export interface ResourceSlice {
  resourceBg: IResourceBackground | undefined;
  resourceSelectedBg: IResourceBackground | undefined;
  listBackgroundResourceAvailable: IBackgroundResource[];
  listResourceFiles: IFileResource[];
  tabUploadKeyResource: string;
  listResources: any[];
  isSumbitForm: boolean;
  newResource: IResource | undefined;
  selectedResource?: IResource;
}

const initialState: ResourceSlice = {
  resourceBg: undefined,
  resourceSelectedBg: undefined,
  listBackgroundResourceAvailable: [],
  listResourceFiles: [],
  tabUploadKeyResource: listTabNameResource.background,
  listResources: [],
  isSumbitForm: false,
  newResource: undefined,
  selectedResource: undefined,
};

export const resourceSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {
    setListResources: (state, action: PayloadAction<any[]>) => {
      state.listResources = action.payload;
    },
    setResourceBg: (state, action: PayloadAction<IResourceBackground>) => {
      state.resourceBg = action.payload;
    },
    setNewResource: (state, action: PayloadAction<any>) => {
      state.newResource = action.payload;
    },
    setResourceSelectedBg: (
      state,
      action: PayloadAction<IResourceBackground>
    ) => {
      state.resourceSelectedBg = action.payload;
    },
    setListBackgroundResource: (
      state,
      action: PayloadAction<IBackgroundResource[]>
    ) => {
      state.listBackgroundResourceAvailable = action.payload;
    },
    resetResourceSelectedBg: state => {
      state.resourceSelectedBg = undefined;
    },
    resetResourceBg: state => {
      state.resourceBg = undefined;
    },
    resetResource: state => {
      state.newResource = undefined;
    },
    resetFileSelectedList: state => {
      state.listResourceFiles = [];
    },
    removeBgResourceAvailable: (
      state,
      action: PayloadAction<IBackgroundResource>
    ) => {
      state.listBackgroundResourceAvailable =
        state.listBackgroundResourceAvailable.filter(
          img => img.url !== action.payload.url
        );
    },
    setTabUploadKeyResource: (state, action: PayloadAction<string>) => {
      state.tabUploadKeyResource = action.payload;
    },
    addAvailableBackgroundResourceList: (
      state,
      action: PayloadAction<IBackgroundResource>
    ) => {
      state.listBackgroundResourceAvailable = [
        ...state.listBackgroundResourceAvailable.filter(
          ele => ele.url !== action.payload.url
        ),
        action.payload,
      ];
    },
    setIsSumbitForm: (state, action: PayloadAction<boolean>) => {
      state.isSumbitForm = action.payload;
    },
    setFileSelected: (state, action: PayloadAction<IFileResource>) => {
      state.listResourceFiles = [
        ...state.listResourceFiles.filter(
          ele => ele.linkUrl !== action.payload.linkUrl
        ),
        action.payload,
      ];
    },
    removeFileSelected: (state, action: PayloadAction<IFileResource>) => {
      state.listResourceFiles = [
        ...state.listResourceFiles.filter(
          ele => ele.linkUrl !== action.payload.linkUrl
        ),
      ];
    },
    setSelectedResource: (state, action: PayloadAction<IResource>) => {
      state.selectedResource = action.payload;
    },
    resetSelectedResource: state => {
      state.selectedResource = undefined;
    },
    editResourceInList: (state, action: PayloadAction<IResource>) => {
      state.listResources = [
        ...state.listResources.filter(
          resource => resource._id !== action.payload._id
        ),
        action.payload,
      ];
    },
  },
});
export const {
  setListResources,
  setResourceBg,
  setNewResource,
  setFileSelected,
  setResourceSelectedBg,
  setListBackgroundResource,
  resetResourceSelectedBg,
  resetResourceBg,
  resetResource,
  removeBgResourceAvailable,
  setTabUploadKeyResource,
  addAvailableBackgroundResourceList,
  setIsSumbitForm,
  setSelectedResource,
  resetSelectedResource,
  removeFileSelected,
  resetFileSelectedList,
  editResourceInList,
} = resourceSlice.actions;

export const getListResources =
  (id: string): AppThunk =>
  dispatch => {
    getResourceById(id).then(
      res => {
        console.log(res);
      },
      err =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.LOADRESOURCES'),
          })
        )
    );
  };

export const getHyperlinksList =
  (id: string, type: string = ''): AppThunk =>
  dispatch => {
    getHyperlinkByCategoryType(id, type).then(
      res => {
        dispatch(setListResources(res));
      },
      err =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.LOADHYPERLINKS'),
          })
        )
    );
  };

export const getBgResources =
  (id: string): AppThunk =>
  dispatch => {
    getBgResourceById(id).then(
      res => {
        dispatch(setResourceBg(res[0]));
        dispatch(setListBackgroundResource(res));
      },
      err =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.LOADINGBGSSAVAILABLE'),
          })
        )
    );
  };

export const addBgInResource =
  (background: IResourceBackground): AppThunk =>
  dispatch => {
    const backgroundConId = {
      ...background,
      id_temp: (Math.random() * 10).toString(),
    };
    dispatch(setResourceSelectedBg(backgroundConId));
  };

export const addFileInResource =
  (file: IFileResource): AppThunk =>
  dispatch => {
    dispatch(setFileSelected(file));
  };

export const saveResourceBg =
  (id: string, selectedBg: IResourceBackground): AppThunk =>
  (dispatch, getState) => {
    const bgResource = getState().resource.resourceBg;
    const copyBg = copyObject(selectedBg);
    if (selectedBg.id_temp && !bgResource) {
      delete copyBg.id_temp;
      saveBgForResource(selectedBg, id).then(
        res => {
          copyBg._id = res._id;
          dispatch(setResourceBg(copyBg));
          dispatch(resetResourceSelectedBg());
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.SAVEBG'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.SAVEBG'),
            })
          )
      );
    }
    if (selectedBg.id_temp && bgResource) {
      const editBg = { ...selectedBg, _id: bgResource._id };
      editBgForResource(editBg, id).then(
        res => {
          dispatch(setResourceBg(editBg));
          dispatch(resetResourceSelectedBg());
          dispatch(addAvailableBackgroundGameList(editBg));
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.EDITBG'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.EDITBG'),
            })
          )
      );
    }
  };

export const saveResource =
  (id: string, resourceForm: IResource): AppThunk =>
  (dispatch, getState) => {
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    const copyResource = <IResource>copyObject(resourceForm);
    copyResource.linkMimeType = {
      imageUrl: 'img/icon/link/consortium.png',
    };

    if (resourceForm) {
      saveResourceById(copyResource).then(
        res => {
          const listResources = getState().resource.listResources;
          let newResource = copyResource;
          newResource._id = res._id;
          dispatch(setListResources([...listResources, newResource]));
          dispatch(setNewResource(copyResource));
          dispatch(resetResource());
          dispatch(resetFileSelectedList());
          dispatch(setIsEditMode(false));
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.SAVERESOURCE'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.SAVERESOURCE'),
            })
          )
      );
    }
  };

export const updateResource =
  (id: string, resourceForm: IResource): AppThunk =>
  (dispatch, getState) => {
    const copyResource = copyObject(resourceForm);
    copyResource.linkMimeType = {
      imageUrl: 'img/icon/link/consortium.png',
    };
    if (resourceForm) {
      updateResourceById(id, copyResource).then(
        res => {
          //dispatch(setNewResource(copyResource));
          dispatch(editResourceInList(copyResource));
          dispatch(resetResource());
          dispatch(resetSelectedResource());
          dispatch(resetFileSelectedList());
          dispatch(setIsEditMode(false));
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.SAVERESOURCE'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.SAVERESOURCE'),
            })
          )
      );
    }
  };

export const deleteResourceBg =
  (resourceId: string): AppThunk =>
  (dispatch, getState) => {
    const bgResource = getState().resource.resourceBg;
    bgResource &&
      deleteBgForResource(bgResource, resourceId).then(
        res => {
          dispatch(resetResourceBg());
          dispatch(resetResourceSelectedBg());
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETEBG'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETEBG'),
            })
          )
      );
  };

export const deleteSelectedResource =
  (resourceId: string): AppThunk =>
  (dispatch, getState) => {
    const selectedResource = getState().resource.selectedResource;
    selectedResource &&
      deleteResourceSelected(resourceId).then(
        (res: any) => {
          const listResources = getState().resource.listResources;
          const newlistResources = listResources.filter(
            el => el._id !== resourceId
          );
          dispatch(setListResources(newlistResources));
          dispatch(resetSelectedResource());
          dispatch(resetFileSelectedList());
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETERESOURCE'),
            })
          );
        },
        (err: any) =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETERESOURCE'),
            })
          )
      );
  };

export const deleteBgAvailable =
  (bg: IBackgroundResource): AppThunk =>
  dispatch => {
    const documentName = 'backgrounds/' + bg.url.split('/').pop();
    documentName &&
      deleteFile(documentName).then(
        data => {
          dispatch(removeBgResourceAvailable(bg));
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETEBGAVAILABLE'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETEBGAVAILABLE'),
            })
          )
      );
  };

export const getListBgAvailableResource = (): AppThunk => dispatch => {
  getFiles({ pathName: 'backgrounds' }).then(
    data => {
      const newList = data.map(bg => {
        return {
          url: bg.url,
          name: bg.originalfilename,
          initialHeight: 1920,
          initialWidth: 1080,
          type: bg.mimetype,
        };
      });
      dispatch(setListBackgroundResource(newList));
    },
    err =>
      dispatch(
        addErrorMessage({
          title: i18n.t('ERRORSMSG.LOADINGBGSSAVAILABLE'),
        })
      )
  );
};

export const selectResourceBg = (state: RootState) => state.resource.resourceBg;
export const selectResourceSelectedBg = (state: RootState) =>
  state.resource.resourceSelectedBg;
export const selectListBackgroundResourceAvailable = (state: RootState) =>
  state.resource.listBackgroundResourceAvailable;
export const selectTabUploadKeyResource = (state: RootState) =>
  state.resource.tabUploadKeyResource;
export const selectIsSumbitForm = (state: RootState) =>
  state.resource.isSumbitForm;
export const selectNewResource = (state: RootState) =>
  state.resource.newResource;
export const selectResourceList = (state: RootState) =>
  state.resource.listResources;
export const selectListResourceFiles = (state: RootState) =>
  state.resource.listResourceFiles;
export const selectSelectedResource = (state: RootState) =>
  state.resource.selectedResource;

export const selectBackgroundNew = createSelector(
  selectResourceSelectedBg,
  selectResourceBg,
  (selectedBg, savedBg) => {
    let newBg: IBackgroundResource;
    if (selectedBg && selectedBg.id_temp) {
      newBg = copyObject(selectedBg);
      return newBg;
    }
    return savedBg;
  }
);
export default resourceSlice.reducer;
