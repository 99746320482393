import { SingleRoute } from '../utils/routes/models/routes';
import * as routeName from './routeNameAuthType';

const routeGame: SingleRoute[] = [
  {
    path: '/categories/:areaId',
    component: 'features/auth/game/gamePlatform/GamePlatform',
    name: routeName.game,
  },
  {
    path: '/categories/:idCat/areas/:areaId/resources/:resourceId',
    component: 'features/auth/game/gamePlatform/GamePlatform',
    name: routeName.microgame,
  },
];
export default routeGame;
