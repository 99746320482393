import { TFunction } from 'i18next';
import { ResourceType } from './typeDocumentData';

export const navigationActionsName = {
  noAction: 'noAction',

  goToMap: 'goToMap',

  goToScene: 'goToScene',

  goToGame: 'goToGame',

  goToMicroGame: 'goToMicroGame',

  goToListGame: 'goToListGame',

  goToResources: 'goToResources',

  goToVideo: 'goToVideo',

  goToDoc: 'goToDoc',

  goToComments: 'goToComments',

  goToAnimations: 'goToAnimations',

  goToReport: 'goToReport',

  goToWeblinks: 'goToWeblinks',

  goToCourse: 'goToCourse',

  goToCycleMap: 'goToCycle',

  gotoGameReport: 'goToGameReport',

  gotoRadar: 'goToRadar',

  gotoRanking: 'goToRanking',
};
export const navigationActions = (t: TFunction) => {
  return [
    {
      label: t('APP.SAVE'),
      value: -1,
    },
    {
      label: t('NAVIGATION.NOACTION'),
      value: navigationActionsName.noAction,
    },
    {
      label: t('NAVIGATION.GOTOMAP'),
      value: navigationActionsName.goToMap,
    },
    {
      label: t('NAVIGATION.GOTOSCENE'),
      value: navigationActionsName.goToScene,
    },
    {
      label: t('NAVIGATION.GOTOGAME'),
      value: navigationActionsName.goToGame,
    },
    {
      label: t('NAVIGATION.GOTOLISTGAME'),
      value: navigationActionsName.goToListGame,
    },
    {
      label: t('NAVIGATION.GOTOMICROGAME'),
      value: navigationActionsName.goToMicroGame,
    },
    {
      label: t('NAVIGATION.GOTORESOURCES'),
      value: navigationActionsName.goToResources,
    },
    {
      label: t('NAVIGATION.GOTOVIDEO'),
      value: navigationActionsName.goToVideo,
    },
    {
      label: t('NAVIGATION.GOTODOC'),
      value: navigationActionsName.goToDoc,
    },
    {
      label: t('NAVIGATION.GOTOCOMMENTS'),
      value: navigationActionsName.goToComments,
    },
    {
      label: t('NAVIGATION.GOTOANIMATIONS'),
      value: navigationActionsName.goToAnimations,
    },
    {
      label: t('NAVIGATION.GOTOREPORT'),
      value: navigationActionsName.goToReport,
    },
    {
      label: t('NAVIGATION.GOTOWEBLINKS'),
      value: navigationActionsName.goToWeblinks,
    },
    {
      label: t('NAVIGATION.GOTOCOURSE'),
      value: navigationActionsName.goToCourse,
    },
    {
      label: t('NAVIGATION.GOTOCYCLE'),
      value: navigationActionsName.goToCycleMap,
    },
    {
      label: t('NAVIGATION.GOTOGAMEREPORT'),
      value: navigationActionsName.gotoGameReport,
    },
    {
      label: t('NAVIGATION.GOTORADAR'),
      value: navigationActionsName.gotoRadar,
    },
    {
      label: t('NAVIGATION.GOTORANKING'),
      value: navigationActionsName.gotoRanking,
    },
  ];
};

export const getResourceNavigationActionName = (resource: ResourceType) => {
  switch (resource) {
    case 'course':
      return navigationActionsName.goToCourse;
    case 'doc':
      return navigationActionsName.goToDoc;
    case 'video':
      return navigationActionsName.goToVideo;
  }
};
