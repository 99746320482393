import React from 'react';
import { ITypesImg, ITypesJson } from '../../../../features/auth/models/thumbs';
import DropzoneBox from '../../design/dropzone/dropzone-box/DropzoneBox';
import DropzoneSmall from '../../design/dropzone/dropzone-small/DropzoneSmall';

interface IFileUpload {
  handleOnDrop: (files: File[], nameDrop: string) => void;
  fileDataImport?: ITypesImg | ITypesJson;
  notValidFile: { [key: string]: any };
  nameDrop: string;
  small?: boolean;
}

const FileUpload = (props: IFileUpload) => {
  const { handleOnDrop, fileDataImport, notValidFile, nameDrop, small } = props;
  const handleOnDropCustom = (files: File[]) => {
    handleOnDrop(files, nameDrop);
  };

  const handleOnClose = () => {
    console.log('');
  };
  return (
    <>
      <div className="d-flex flex-column align-items-center w-100">
        {small ? (
          <DropzoneSmall
            onDrop={handleOnDropCustom}
            onClose={handleOnClose}
            accept={Object.values(fileDataImport || '').join(',')}
          />
        ) : (
          <DropzoneBox
            onDrop={handleOnDropCustom}
            onClose={handleOnClose}
            accept={Object.values(fileDataImport || '').join(',')}
          />
        )}
        {notValidFile &&
          notValidFile[nameDrop] &&
          notValidFile[nameDrop].isNotValid && (
            <span className="mt-2">Attenzione file non valido!</span>
          )}
      </div>
    </>
  );
};

export default FileUpload;
