import isEqual from 'lodash.isequal';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { getGameConfig } from '../../../features/auth/game/gamePlatform/services/gameConfiguration.service';
import { getGameHeaderSkills } from '../../../features/auth/gamereport/reports.service';
import { SkillsScoreList } from '../../../features/auth/gamereport/types';
import CardShadow from '../design/card/CardShadow';

export type GameHeaderProps = {
  backgroundcolor: string;
  isSkillScoreChanged: boolean;
  categoryId: string;
};
export const GameHeader = (props: GameHeaderProps) => {
  const { backgroundcolor, categoryId, isSkillScoreChanged } = props;
  const [gameHeaderSkills, setGameHeaderSkills] = useState<SkillsScoreList[]>(
    []
  );
  useEffect(() => {
    loadSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    //gameConfig
    isSkillScoreChanged && loadSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSkillScoreChanged]);

  function loadSkills() {
    getGameConfig(categoryId).then(gameConfig => {
      //gameHeader skills
      gameConfig.gameHeader &&
        getGameHeaderSkills(gameConfig.gameHeader?.gameFatherId, true).then(
          header => {
            //filtro le skills
            setGameHeaderSkills(
              header.skillsScoreList.filter(sk =>
                (gameConfig.gameHeader?.Skills as string[]).find(selected =>
                  isEqual(selected, sk.skill)
                )
              )
            );
          }
        );
    });
  }

  return (
    <>
      {gameHeaderSkills && gameHeaderSkills.length > 0 && (
        <CardShadow
          className="game-header-skills flex-column"
          style={{ backgroundColor: backgroundcolor }}
        >
          <Container>
            {gameHeaderSkills.map((skill, index) => (
              <Row className="d-flex flex-row" key={index}>
                <Col xs={9}>
                  <span className={'text-nowrap'}>{skill.skill}</span>
                </Col>
                <Col className="text-right p-0" xs={3}>
                  <b>{skill.score}</b>
                </Col>
              </Row>
            ))}
          </Container>
        </CardShadow>
      )}
    </>
  );
};
