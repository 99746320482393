import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import CardShadow from '../../../design/card/CardShadow';
import Icon from '../../../icon/Icon';

const ToolbarButton = ({
  clickAction,
  icon,
  img,
  imgSize,
  className,
  fontAwesomeIcon,
  styleBtn,
  title,
}: {
  clickAction: () => void;
  icon?: string;
  img?: string;
  imgSize?: string;
  fontAwesomeIcon?: IconProp;
  className?: string;
  styleBtn?: any;
  title?: string;
}) => {
  return (
    <CardShadow
      className={`toolbar-btn ${className}`}
      style={{
        backgroundColor: styleBtn ? styleBtn.backgroundColor : '',
        borderTopRightRadius:
          styleBtn && styleBtn.borderRadius ? styleBtn.borderRadius : 'default',
        borderTopLeftRadius:
          styleBtn && styleBtn.borderRadius ? styleBtn.borderRadius : 'default',
      }}
      clickAction={() => clickAction()}
    >
      {icon && (
        <Icon
          icon={icon}
          size="32px"
          colorStyle={styleBtn ? styleBtn.color : '#34a381'}
          title={title}
        />
      )}
      {fontAwesomeIcon && (
        <div className="fa-custom">
          <div className="shared-icon">
            <div
              style={{
                height: '32px',
                width: '32px',
              }}
            >
              <FontAwesomeIcon className="fa-2x" icon={fontAwesomeIcon} />
            </div>
          </div>
        </div>
      )}
      {img && (
        <img
          src={img}
          width={imgSize ? imgSize : '42px'}
          height="auto"
          title={title}
          alt={''}
        />
      )}
    </CardShadow>
  );
};

export default ToolbarButton;
