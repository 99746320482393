import React, { useState } from 'react';
import FileUploadSm from '../../../features/auth/game/gamePlatform/components/FileUploadSm';
import { ITypesImg } from '../../../features/auth/models/thumbs';
import Label from '../design/form/label/Label';
import Icon from '../icon/Icon';
import { has } from 'remove-accents';

interface IFileManager {
  label: string;
  onDrop: (files: File[], nameDrop: string) => void;
  fileDataImport?: ITypesImg;
  notValidFile: { [key: string]: any };
  nameDrop: string;
  onDelete?: (fileName: string) => void;
  uploadedFileName?: string;
  fileNameToShow?: string;
  disable?: boolean;
}

const FileManager = ({
  label,
  onDrop,
  fileDataImport,
  notValidFile,
  nameDrop,
  onDelete,
  uploadedFileName,
  fileNameToShow,
  disable = false,
}: IFileManager) => {
  const [file, setFile] = useState<File>();

  const onDropFile = (files: File[], nameDrop: string) => {
    const fileName = files[0].name;
    if (!has(fileName)) {
      onDrop(files, nameDrop);
      setFile(files[0]);
    } else {
      alert('Nome file non valido');
    }
  };

  const onClickDelete = () => {
    if ((file && file.name) || uploadedFileName) {
      onDelete && onDelete(file?.name || uploadedFileName || '');
      setFile(undefined);
    }
  };

  return (
    <div>
      {(file && file.name) || uploadedFileName ? (
        <>
          <Label label="File caricato" />
          <div className="d-flex">
            <span>
              {file?.name || fileNameToShow ? fileNameToShow : uploadedFileName}
            </span>
            {onDelete !== undefined && (
              <Icon icon="delete" className="pointer" onClick={onClickDelete} />
            )}
          </div>
        </>
      ) : (
        <FileUploadSm
          label={label}
          handleOnDrop={onDropFile}
          notValidFile={notValidFile}
          nameDrop={nameDrop}
          fileDataImport={fileDataImport}
          disable={disable}
        />
      )}
    </div>
  );
};

export default FileManager;
