import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import useIsIOS from '../../hooks/useIsIOS';

type Props = {
  children: React.ReactNode;
};

function ScreenOrientation(props: Props) {
  const { children } = props;
  const [landscapeRequired, setLandscapeRequired] = useState(false);

  const { isIOS } = useIsIOS();

  useEffect(() => {
    const dm = detectMob();
    const lr = window.innerHeight > window.innerWidth;
    setLandscapeRequired(lr && dm);
  }, []);

  window.onorientationchange = (event: any) => {
    const angle = isIOS
      ? event.target.orientation
      : event.target.screen.orientation.angle;
    console.log(`the orientation of the device is now ${angle}`);
    const lr = angle === 0 || angle === 180;
    setLandscapeRequired(lr);
    !lr && window.location.reload();
  };

  function detectMob() {
    const toMatch = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return toMatch.some(toMatchItem => {
      return window.navigator.userAgent.match(toMatchItem);
    });
  }

  return (
    <div>
      {!landscapeRequired && children}
      {landscapeRequired && <LandscapeModal />}
    </div>
  );
}

function LandscapeModal() {
  return (
    <Modal show={true} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <div className={`d-flex flex-column bd-highlight text-center py-5`}>
          <span role="img" aria-label="ClockwiseAndMobile">
            🔃📱
          </span>
          <h2>Ruota il tuo smartphone!</h2>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ScreenOrientation;
