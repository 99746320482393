export const required = 'required';
export const requiredOne = 'requiredOne';
export const email = 'email';
export const length = 'length';
export const cf = 'cf';
export const piva = 'piva';
export const cap = 'cap';
export const confirm = 'confirm';
export const minLength = 'minLength';
export const maxLength = 'maxLength';
export const percentage = 'percentage';
export const isNumber = 'isNumber';
export const upperCharacter = 'upperCharacter';
export const specialCharacter = 'specialCharacter';
export const oneNumber = 'oneNumber';
export const maxNumber = 'maxNumber';
export const minNumber = 'minNumber';
export const isDate = 'isDate';
export const isValidStartEndDate = 'isValidStartEndDate';
