import MyProgress from '../features/auth/menu/menuLevels/myProgress/MyProgress';
import { SingleRoute } from '../utils/routes/models/routes';
import * as routeName from './routeNameAuthType';

const routeMenu: SingleRoute[] = [
  {
    path: '/disclaimer',
    component: 'features/auth/menu/menuLevels/Disclaimer',
    name: routeName.disclaimer,
    layout: 'menuLayout',
  },
  {
    path: '/myprogress',
    component: MyProgress,
    name: routeName.myprogress,
    layout: 'menuLayout',
  },
  {
    path: '/search',
    component: 'features/auth/menu/menuLevels/Search',
    name: routeName.search,
    layout: 'menuLayout',
  },
  {
    path: '/gamerole',
    component:
      'features/auth/menu/menuLevels/gameRoleManagement/GameRoleManagement',
    name: routeName.gamerole,
    layout: 'menuLayout',
  },
  {
    path: '/wallmanage',
    component: 'features/auth/menu/menuLevels/wallManage/WallManage',
    name: routeName.wallmanage,
    layout: 'menuLayout',
  },
  {
    path: '/help',
    component: 'features/auth/menu/menuLevels/Help',
    name: routeName.help,
    layout: 'menuLayout',
  },
  {
    path: '/export',
    component: 'features/auth/menu/menuLevels/exportManagement/Exports',
    name: routeName.exports,
    layout: 'menuLayout',
  },
  {
    path: '/gdpr',
    component: 'features/auth/menu/menuLevels/gdpr/Gdpr',
    name: routeName.gdpr,
    layout: 'menuLayout',
  },
  {
    path: '/useronline',
    component: 'features/auth/menu/menuLevels/gdpr/GdprUserOnLine',
    name: routeName.gdpruseronline,
    layout: 'menuLayout',
  },
  {
    path: '/configuration',
    component: 'features/auth/menu/menuLevels/gdpr/GdprConfiguration',
    name: routeName.gdprconfiguration,
    layout: 'menuLayout',
  },
  {
    path: '/resetgame',
    component: 'features/auth/menu/menuLevels/ResetGame',
    name: routeName.resetgame,
    layout: 'menuLayout',
  },
  {
    path: '/usermanagment',
    component: 'features/auth/menu/menuLevels/userManagement/UserManagement',
    name: routeName.usermanagement,
    layout: 'menuLayout',
  },
  {
    path: '/batch',
    component: 'features/auth/menu/menuLevels/Batch',
    name: routeName.batch,
    layout: 'menuLayout',
  },
  {
    path: '/tooltip',
    component: 'features/auth/menu/menuLevels/Tooltip',
    name: routeName.tooltip,
    layout: 'menuLayout',
  },
  {
    path: '/hyperlink',
    component: 'features/auth/menu/menuLevels/Hyperlink',
    name: routeName.hyperlink,
    layout: 'menuLayout',
  },
  {
    path: '/moodlemanager',
    component: 'features/auth/menu/menuLevels/MoodleManager',
    name: routeName.moodlemanager,
    layout: 'menuLayout',
  },
  {
    path: '/menumanagement',
    component: 'features/auth/menu/menuLevels/menuManager/MenuManager',
    name: routeName.menumanagement,
    layout: 'menuLayout',
  },
  {
    path: '/languagesmanagement',
    component:
      'features/auth/menu/menuLevels/languagesManagement/LanguagesManagement',
    name: routeName.languagesmanagement,
    layout: 'menuLayout',
  },
  {
    path: '/categorymanagement',
    component: 'features/auth/menu/menuLevels/categoryManagement/Category',
    name: routeName.categorymanagement,
    layout: 'menuLayout',
  },
  {
    path: '/rolesmanagement',
    component: 'features/auth/menu/menuLevels/rolesManagement/Roles',
    name: routeName.rolesmanagement,
    layout: 'menuLayout',
  },
  {
    path: '/aclmanagement',
    component: 'features/auth/menu/menuLevels/aclManagement/AclManagement',
    name: routeName.aclmanagement,
    layout: 'menuLayout',
  },
  {
    path: '/checkmanagement',
    component: 'features/auth/menu/menuLevels/checkManagement/CheckManagement',
    name: routeName.checkmanagement,
    layout: 'menuLayout',
  },
  {
    path: '/meetings',
    component: 'features/auth/menu/menuLevels/meetings/index',
    name: routeName.meetings,
    layout: 'menuLayout',
  },
  {
    path: '/',
    component: 'features/auth/menu/menuLevels/Disclaimer',
    name: routeName.menu,
    layout: 'menuLayout',
  },
  {
    path: '/skill_valuation',
    component: 'features/auth/menu/menuLevels/skillValuation/index',
    name: routeName.skillValuation,
    layout: 'menuLayout',
  },
  {
    path: '/badgemanager',
    component: 'features/auth/menu/menuLevels/badgeManager/index',
    name: routeName.badgemanager,
    layout: 'menuLayout',
  },
  {
    path: '/badgeassigner',
    component: 'features/auth/menu/menuLevels/badgeAssigner/index',
    name: routeName.badgeassigner,
    layout: 'menuLayout',
  },
  {
    path: '/attemptreport',
    component: 'features/auth/menu/menuLevels/attemptReport/index',
    name: routeName.attemptreport,
    layout: 'menuLayout',
  },
];
export default routeMenu;
