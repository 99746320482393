import React, { useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { user } from '../../../../app/auth/initDataUser';
import { selectDropdownSelectableLangs } from '../../../../features/auth/menu/menuLevels/languagesManagement/languagesManagementSlice';
import {
  editUserPreferenceOnline,
  getUserPreferenceOnline,
} from '../../../../features/auth/menu/menuLevels/userManagement/services/userpreference.service';
import { useEditProfile } from '../../../../features/auth/modals/editProfile/useEditProfile';
import FormElements from '../../../../utils/forms/FormElements';
import { useForm } from '../../../../utils/forms/useForm';
import PrimaryButton from '../../../shared/design/form/buttons/primary-button/PrimaryButton';
import SecondaryButton from '../../../shared/design/form/buttons/secondary-button/SecondaryButton';
import { resetModal } from '../../../shared/redux/modal/modalSlice';

const ModalEditProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dropdownSelectableLangs = useSelector(selectDropdownSelectableLangs);

  const submitHandler: any = (
    event: React.FormEvent<HTMLFormElement> | undefined,
    data: any
  ) => {
    const usr = {
      name: data.name,
      surname: data.surname,
      birthDate: data.birthDate,
      gender: data.gender,
      email: data.email,
      titleOfStudyCode: data.titleOfStudyCode,
      language: data.language,
    };
    editUserPreferenceOnline(usr).then(res => {
      dispatch(resetModal());
    });
  };

  useEffect(() => {
    getUserPreferenceOnline().then(res => {
      setData(res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const { validationsData, userRow } = useEditProfile(
    t,
    dropdownSelectableLangs
  );
  const { isSubmit, errors, submitAction, changeValue, dataForm, setData } =
    useForm(submitHandler, validationsData);

  const cancelEdit = () => {
    dispatch(resetModal());
  };

  return (
    <div className="p-3" style={{ background: '#f1f1f1' }}>
      <Form onSubmit={submitAction}>
        <Row className="w-100">
          <Col xs="12">
            <Row className="align-items-center">
              <Col xs="12" md="12">
                <FormElements
                  data={userRow}
                  errors={errors}
                  isSubmit={isSubmit}
                  dataForm={dataForm}
                  changeValue={changeValue}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="w-100 justify-content-end">
          <Col xs="auto">
            <SecondaryButton onClick={cancelEdit}>
              {t('APP.CANCEL')}
            </SecondaryButton>
          </Col>
          <Col xs="auto">
            <PrimaryButton>{t('APP.SAVE')}</PrimaryButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default ModalEditProfile;
