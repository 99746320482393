import omit from 'lodash.omit';
import http from '../../../../utils/http';
import { WeblinkData } from '../models/types';

const BASE_URL = `/weblinks`;

export const getWeblink = (idScene: string): Promise<WeblinkData> =>
  http.get(`${BASE_URL}/${idScene}/weblink`);

export const postWeblink = (
  idScene: string,
  weblink: WeblinkData
): Promise<{ _id: string }> => {
  return http.post(`${BASE_URL}/${idScene}/weblink`, weblink);
};

export const putWeblink = (
  idScene: string,
  idWeblink: string,
  weblink: WeblinkData
): Promise<WeblinkData> => {
  const newWeblink = omit(weblink, ['_id', 'weblinkId']);
  return http.put(`${BASE_URL}/${idScene}/weblink/${idWeblink}`, newWeblink);
};

export const deleteWeblink = (
  idWeblink: string,
  idScene: string
): Promise<void> => http.delete(`${BASE_URL}/${idScene}/weblink/${idWeblink}`);
