import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import CardShadow from '../../../../../../components/shared/design/card/CardShadow';
import { localdateString } from '../../../../../../utils/dateUtils';
import { IBadges } from '../models/serviceResponse';
import {
  getMicrobadgesList,
  selectClickedAreaId,
  selectMicrobadgesList,
  setClickedAreaId,
} from '../myProgressSlice';
import MicroBadge from './MicroBadge';

interface ISingleBadge {
  badgeInfo: IBadges;
}
const SingleBadge = (props: ISingleBadge) => {
  const { badgeInfo } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const microbadgeList = useSelector(selectMicrobadgesList);
  const clickedAreaId = useSelector(selectClickedAreaId);
  const acquisitionDate = `${t('MYPROGRESS.BADGE.ACQUIRED')}: ${localdateString(
    badgeInfo.obtainedBadge.obtaindeDate
  )}`;
  const expirationDate = `${t('MYPROGRESS.BADGE.EXPIRED')}: ${
    badgeInfo.obtainedBadge.expiredDate
      ? localdateString(badgeInfo.obtainedBadge.expiredDate)
      : '-'
  }`;
  //const bgBadge = '#ff9a62';

  const imgUrl = badgeInfo.badge.obtained
    ? badgeInfo.badge.badgeImg
    : badgeInfo.badge.inactiveBadgeImg;
  const expandCard = (areaId: string) => {
    if (clickedAreaId === areaId) {
      dispatch(setClickedAreaId(''));
    } else {
      badgeInfo.obtainedBadge.obtainedMicroBadges &&
        dispatch(getMicrobadgesList(badgeInfo.badge.areaId));
    }
  };
  return (
    <>
      <CardShadow
        className="m-3"
        clickAction={() => expandCard(badgeInfo.badge.areaId)}
        style={{
          width: clickedAreaId === badgeInfo.badge.areaId ? '100%' : 'auto',
        }}
      >
        <div
          className={`badges-info ${
            badgeInfo.obtainedBadge.obtainedMicroBadges ? 'pointer' : ''
          }`}
        >
          <div className="badge-first-row"></div>
          <div
            className="badge-image" /*  style={{ backgroundColor: bgBadge }} */
          >
            <img src={imgUrl} alt={''} />
          </div>
          <div className="badge-last-row">
            <div>{acquisitionDate}</div>
            <div>{expirationDate}</div>
          </div>
        </div>
        {microbadgeList && badgeInfo.badge.areaId === clickedAreaId && (
          <MicroBadge microbadgeList={microbadgeList} />
        )}
      </CardShadow>
    </>
  );
};

export default SingleBadge;
