import { IBadgeConfig, IMicrobadgeConfig } from './models/badge';
import {
  addSuccessMessage,
  addErrorMessage,
} from './../../../../app/errors/errorSlice';
import {
  resetModal,
  setModal,
} from './../../../../components/shared/redux/modal/modalSlice';
import { IQuestion } from './models/gameQuestionConfiguration';
import { AppThunk } from './../../../../app/store';
import {
  IGameConfig,
  IMicroGameConfig,
  IParamGame,
  IParamQuestion,
} from './models/gameConfiguration';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../../app/store';
import {
  deleteGameConfig,
  deleteGameResult,
  deleteMicrogameConfig,
  deleteQuestion,
} from './services/gameConfiguration.service';
import { deleteBadge } from './services/badgeConfiguration.service';
import i18n from '../../../../config/i18n';
import { deleteMicrobadge } from './services/microbadgeConfiguration.service';

export interface MapSliceModel {
  paramGameConfig?: IParamGame;
  paramQuestionConfig?: IParamQuestion;
  badgesConfig?: IBadgeConfig[];
  microbadgesConfig?: IMicrobadgeConfig[];
}
const initialState: MapSliceModel = {
  paramGameConfig: undefined,
  paramQuestionConfig: undefined,
  badgesConfig: [],
  microbadgesConfig: [],
};

export const gameConfigurationSlice = createSlice({
  name: 'gameConfiguration',
  initialState,
  reducers: {
    setParamGameConfig: (state, action: PayloadAction<IParamGame>) => {
      state.paramGameConfig = action.payload;
    },
    setParamQuestionConfig: (state, action: PayloadAction<IParamQuestion>) => {
      state.paramQuestionConfig = action.payload;
    },
    setBadgesConfig: (state, action: PayloadAction<IBadgeConfig[]>) => {
      state.badgesConfig = action.payload;
    },
    setMicrobadgesConfig: (
      state,
      action: PayloadAction<IMicrobadgeConfig[]>
    ) => {
      state.microbadgesConfig = action.payload;
    },
  },
});

export const {
  setParamGameConfig,
  setParamQuestionConfig,
  setBadgesConfig,
  setMicrobadgesConfig,
} = gameConfigurationSlice.actions;

const buttonList = [
  {
    label: 'MODAL.CANCELL',
    action: 'cancelDelete',
    type: 'secondary',
    dataType: {},
  },
  {
    label: 'MODAL.CONFIRM',
    action: 'confirmDelete',
    type: 'primary',
    dataType: {},
  },
];

export const deleteGameConfiguration =
  (data: IGameConfig, callback?: () => void): AppThunk =>
  (dispatch, getState) => {
    const confirmDelete = () => {
      deleteGameConfig(data).then(
        (res: any) => {
          dispatch(resetModal());
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETEGAMECONFIG'),
            })
          );
          callback && callback();
        },
        (err: any) => {
          dispatch(resetModal());
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETEGAMECONFIG'),
            })
          );
        }
      );
    };
    const cancelDelete = () => {
      dispatch(resetModal());
    };
    dispatch(
      setModal(
        buttonList,
        {
          title: i18n.t('MESSAGES.DELETEGAMECONFIG'),
          body: i18n.t('MESSAGES.CONFIRMDELETEGAMECONFIG'),
        },
        {
          cancelDelete,
          confirmDelete,
        }
      )
    );
  };
export const deleteMicroGameConfiguration =
  (data: IMicroGameConfig, callback?: () => void): AppThunk =>
  (dispatch, getState) => {
    const confirmDelete = () => {
      deleteMicrogameConfig(data).then(
        (res: any) => {
          dispatch(resetModal());
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETEMICROGAMECONFIG'),
            })
          );
          callback && callback();
        },
        (err: any) => {
          dispatch(resetModal());
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETEMICROGAMECONFIG'),
            })
          );
        }
      );
    };
    const cancelDelete = () => {
      dispatch(resetModal());
    };
    dispatch(
      setModal(
        buttonList,
        {
          title: i18n.t('MESSAGES.DELETEMICROGAMECONFIG'),
          body: i18n.t('MESSAGES.CONFIRMDELETEMICROGAMECONFIG'),
        },
        {
          cancelDelete,
          confirmDelete,
        }
      )
    );
  };
export const deleteResult =
  (catId: number, callback?: () => void): AppThunk =>
  (dispatch, getState) => {
    const confirmDelete = () => {
      deleteGameResult(catId).then(
        (res: any) => {
          dispatch(resetModal());
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETEGAMERESULT'),
            })
          );
          callback && callback();
        },
        (err: any) => {
          dispatch(resetModal());
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETEGAMERESULT'),
            })
          );
        }
      );
    };
    const cancelDelete = () => {
      dispatch(resetModal());
    };
    dispatch(
      setModal(
        buttonList,
        {
          title: i18n.t('MESSAGES.DELETEGAMERESULT'),
          body: i18n.t('MESSAGES.CONFIRMDELETEGAMERESULT'),
        },
        {
          cancelDelete,
          confirmDelete,
        }
      )
    );
  };

export const deleteSingleQuestion =
  (questionItem: IQuestion, callback?: () => void): AppThunk =>
  (dispatch, getState) => {
    const confirmDelete = () => {
      deleteQuestion(questionItem).then(
        (res: any) => {
          dispatch(resetModal());
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETEQUESTION'),
            })
          );
          callback && callback();
        },
        (err: any) => {
          dispatch(resetModal());
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETEQUESTION'),
            })
          );
        }
      );
    };
    const cancelDelete = () => {
      dispatch(resetModal());
    };
    dispatch(
      setModal(
        buttonList,
        {
          title: i18n.t('MESSAGES.DELETEQUESTION'),
          body: i18n.t('MESSAGES.CONFIRMDELETEQUESTION'),
        },
        {
          cancelDelete,
          confirmDelete,
        }
      )
    );
  };
export const deleteSingleBadge =
  (badgeItem: IBadgeConfig, callback?: () => void): AppThunk =>
  (dispatch, getState) => {
    const confirmDelete = () => {
      deleteBadge(badgeItem).then(
        (res: any) => {
          dispatch(resetModal());
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETEBADGE'),
            })
          );
          callback && callback();
        },
        (err: any) => {
          dispatch(resetModal());
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETEBADGE'),
            })
          );
        }
      );
    };
    const cancelDelete = () => {
      dispatch(resetModal());
    };
    dispatch(
      setModal(
        buttonList,
        {
          title: i18n.t('MESSAGES.DELETEBADGE'),
          body: i18n.t('MESSAGES.CONFIRMDELETEBADGE'),
        },
        {
          cancelDelete,
          confirmDelete,
        }
      )
    );
  };
export const deleteSingleMicrobadge =
  (badgeItem: IMicrobadgeConfig, callback?: () => void): AppThunk =>
  (dispatch, getState) => {
    const confirmDelete = () => {
      deleteMicrobadge(badgeItem).then(
        (res: any) => {
          dispatch(resetModal());
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETEMICROBADGE'),
            })
          );
          callback && callback();
        },
        (err: any) => {
          dispatch(resetModal());
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETEMICROBADGE'),
            })
          );
        }
      );
    };
    const cancelDelete = () => {
      dispatch(resetModal());
    };
    dispatch(
      setModal(
        buttonList,
        {
          title: i18n.t('MESSAGES.DELETEMICROBADGE'),
          body: i18n.t('MESSAGES.CONFIRMDELETEMICROBADGE'),
        },
        {
          cancelDelete,
          confirmDelete,
        }
      )
    );
  };
export const deleteSingleMicrogame =
  (microgameItem: IMicroGameConfig, callback?: () => void): AppThunk =>
  (dispatch, getState) => {
    const confirmDelete = () => {
      deleteMicrogameConfig(microgameItem).then(
        (res: any) => {
          dispatch(resetModal());
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETEGAMECONFIG'),
            })
          );
          callback && callback();
        },
        (err: any) => {
          dispatch(resetModal());
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETEGAMECONFIG'),
            })
          );
        }
      );
    };
    const cancelDelete = () => {
      dispatch(resetModal());
    };
    dispatch(
      setModal(
        buttonList,
        {
          title: i18n.t('MESSAGES.DELETEGAMECONFIG'),
          body: i18n.t('MESSAGES.CONFIRMDELETEGAMECONFIG'),
        },
        {
          cancelDelete,
          confirmDelete,
        }
      )
    );
  };

export const selectParamGameConfig = (state: RootState) =>
  state.gameConfiguration.paramGameConfig;
export const selectParamQuestionConfig = (state: RootState) =>
  state.gameConfiguration.paramQuestionConfig;
export const selectBadgesConfig = (state: RootState) =>
  state.gameConfiguration.badgesConfig;
export const selectMicrobadgesConfig = (state: RootState) =>
  state.gameConfiguration.microbadgesConfig;

export default gameConfigurationSlice.reducer;
