import React from 'react';
import { InputGroup, FormControl } from 'react-bootstrap';
import { FormControlProps } from 'react-bootstrap/FormControl';
import { mergeClass } from '../../../functions/function';

interface FormProps extends FormControlProps {
  error?: boolean;
  value?: string;
  label?: string;
  checked?: boolean;
}

const CheckboxCustom: React.FC<FormProps & React.HTMLProps<HTMLInputElement>> =
  ({
    children,
    className,
    value = '',
    error = false,
    label,
    checked = false,
    ...rest
  }) => {
    const classMerge: string = mergeClass(
      'shared-checkbox',
      error ? ' error' : ''
    );

    const userAgent = window.navigator.userAgent.indexOf('Firefox');
    const customStyle =
      userAgent > -1
        ? {
            width: '20px',
          }
        : undefined;

    return (
      <InputGroup className={classMerge}>
        <div className="d-flex align-items-center">
          <FormControl
            type="checkbox"
            checked={checked}
            className={userAgent > -1 ? '' : 'check'}
            value={value}
            style={customStyle}
            {...rest}
          />
          {label && <span className="ml-2">{label}</span>}
        </div>
      </InputGroup>
    );
  };

export default CheckboxCustom;
