import isEmpty from 'lodash.isempty';
import React, { useEffect, useState } from 'react';
import podio from './../../../assets/img/ranking/podio.svg';
import Legend from './components/Legend';
import RankCard from './components/RankCard';
import { Rank } from './models/type';
import { getRanking } from './service/ranking.service';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../app/auth/authSlice';

function Ranking() {
  const user = useSelector(selectUser);

  const [ranking, setRanking] = useState<Rank[]>();
  const [userRank, setUserRank] = useState<Rank>();
  //const [theFirstThree, setTheFirstThree] = useState<TheFirstThreeType[]>([]);

  useEffect(() => {
    const subG = user?.userinfo.sub_google;
    if (subG) {
      getRanking(subG)
        .then(res => {
          const ranking = res.classifica;
          const current = res.user;
          setRanking(() => ranking);
          setUserRank(() => current);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }, [user]);

  return (
    <>
      {!isEmpty(ranking) && (
        <div className="p-5">
          <div className="p-3">
            <div className="d-flex justify-content-center">
              <img src={podio} alt="" className="responsive-img" />
            </div>
            <div className="d-flex justify-content-center">
              <Legend />
            </div>
          </div>
          <div className="px-3">
            {ranking && (
              <RankCard ranking={ranking} currentRank={userRank?.Ranking} />
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Ranking;
