import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getFiles } from '../features/auth/services/upload.service';
import http from '../utils/http';

export const seti18nPublic = (setLoadI18nPublic: (data: boolean) => void) => {
  const langBrowser = navigator.language.trim().split(/-|_/)[0];
  getFiles({ pathName: 'i18n' })
    .then(list => {
      const url = list.find(file => file.url.endsWith(`${langBrowser}.json`));
      return url ? http.get(url.cdn) : undefined;
    })
    .then(res => {
      const resources: any = {
        [langBrowser]: {
          translation: res,
        },
      };

      i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
          resources,
          initImmediate: false,
          //lng: "it", //  commented to make languagedetector working. enable if u want to override missing strings with the fallback lang
          fallbackLng: 'it',
          supportedLngs: ['it', 'en'],
          interpolation: {
            escapeValue: false,
          },
        });
      setLoadI18nPublic(true);
    });
};
export default i18n;
