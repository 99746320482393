import { SingleRoute } from '../utils/routes/models/routes';
import * as routeName from './routeNameAuthType';

const routeCycleMaps: SingleRoute[] = [
  {
    path: '/',
    component: 'features/auth/projectMap/ProjectMap',
    name: routeName.cycleMaps,
  },
];
export default routeCycleMaps;
