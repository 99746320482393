import { TFunction } from 'i18next';
import set from 'lodash.set';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState } from 'react';
import { createCategoriesOption } from '../../../../utils/categoryUtils';
import {
  FormElementModelNew,
  OptionSelect,
} from '../../../../utils/forms/models/FormElementModel';
import { createStructureForm } from '../../../../utils/forms/useStructureForm';
import { createSameOptionsWith18n } from '../../../../utils/select';
import { AnimateData, AnimationType } from '../../animations/models/types';
import { ICategory, ISubCatergoriesList } from '../../models/Categories';
import { SubCategoriesStructureType } from '../../projectMap/adminPinSidebar/usePinForm';

function useActionForm(
  t: TFunction,
  dataForm: any,
  goTo: any[],
  categories: ICategory[],
  animationGames: AnimateData[],
  animates: AnimationType[],
  resourceOption: OptionSelect[],
  subItemIdOption: OptionSelect[] | undefined,
  subcategoriesList: ISubCatergoriesList[]
  //fieldName?: string,
) {
  const categoryIdAndProgressiveForm: FormElementModelNew[] =
    createStructureForm([
      {
        typeElement: 'input',
        label: t('GAMEMANAGEMENT.CATEGORYID'),
        name: 'categoryId',
        dataElement: {
          placeholder: t('GAMEMANAGEMENT.CATEGORYID'),
          type: 'text',
          disabled: true,
        },
        col: { xs: 6 },
      },
      {
        typeElement: 'input',
        name: 'progressive',
        label: t('GAMEMANAGEMENT.PROGRESSIVE'),
        dataElement: {
          type: 'number',
          disabled: false,
        },
        col: { xs: 3 },
      },
    ]);

  const goToForm: FormElementModelNew[] = createStructureForm([
    {
      typeElement: 'select',
      label: t('ANIMATION.ACTION.GOTO'),
      name: 'goTo',
      dataElement: {
        type: 'text',
      },
      col: { xs: 8 },
      options: createSameOptionsWith18n(goTo, t),
    },
  ]);

  const goToAnimationForm: FormElementModelNew[] = createStructureForm([
    {
      typeElement: 'select',
      label: t('GAMEMANAGEMENT.NEXT_ANIMATION'),
      name: 'goToCategoryId',
      dataElement: {
        type: 'text',
      },
      col: { xs: 12 },
      options: animationGames
        ? [
            {
              value: -1,
              label: t('APP.FORM.CHOOSE'),
            },
            ...animationGames.map(animate => {
              return {
                value: Number.parseInt(animate.animateId || '-1'),
                label: `${animate.animateId} - ${animate.name}`,
              };
            }),
          ]
        : [],
    },
  ]);

  const goToCategoryForm: FormElementModelNew[] = createStructureForm([
    {
      typeElement: 'select',
      label: t('GAMEMANAGEMENT.CATEGORYID'),
      name: 'goToCategoryId',
      dataElement: {
        type: 'text',
      },
      col: { xs: 12 },
      options: categories
        ? [
            {
              value: -1,
              label: t('APP.FORM.CHOOSE'),
            },
            ...createCategoriesOption(categories),
          ]
        : [],
    },
  ]);

  const goToAnimationProgressiveForm: FormElementModelNew[] =
    createStructureForm([
      {
        typeElement: 'select',
        label: t('GAMEMANAGEMENT.NEXT_ANIMATION_SCENE'),
        name: 'goToId',
        dataElement: {
          type: 'text',
        },
        col: { xs: 12 },
        options: [
          {
            value: '-1',
            label: t('APP.FORM.CHOOSE'),
          },
          ...animates.map(item => {
            return {
              value: `${item.progressive}`,
              label: `${item.progressive} - ${item.name}`,
            };
          }),
        ],
      },
    ]);

  const resourcesDataForm: FormElementModelNew[] = createStructureForm([
    resourceOption.length && {
      typeElement: 'select',
      label: t('APP.FORM.REOURCES'),
      name: 'resourceId',
      dataElement: {
        placeholder: t('APP.FORM.REOURCES'),
        type: 'text',
      },
      options: [
        {
          label: t('APP.FORM.REOURCEPLACEHOLDER'),
          value: undefined,
        },
        ...resourceOption,
      ],
      col: { xs: 12 },
    },
  ]);

  // subCategory
  const subItemIdForm: FormElementModelNew[] = createStructureForm([
    {
      typeElement: 'select',
      label: t('SUBCATEGORY.LABEL'),
      name: 'subItemId',
      dataElement: {
        placeholder: t('SUBCATEGORY.PLACEHOLDER'),
        type: 'text',
      },
      options: [
        {
          label: t('APP.FORM.CATEGORYPLACEHOLDER'),
          value: -1,
        },
        ...(subItemIdOption ? subItemIdOption : []),
      ],
      col: { xs: 12 },
    },
  ]);

  const [dataSubcategoriesStructure, setDataSubcategoriesStructure] = useState<
    FormElementModelNew[]
  >([]);

  const upsertSubcategory = (data: SubCategoriesStructureType) => {
    const { name, options, parent, label, placeholder } = data;
    const index = parent
      ? dataSubcategoriesStructure.findIndex(v => v.name === parent.name)
      : -1;

    const toRemove =
      index > -1 ? dataSubcategoriesStructure.slice(index + 1) : [];
    const toKeep =
      index > -1
        ? dataSubcategoriesStructure.slice(0, index + 1)
        : dataSubcategoriesStructure;
    const cleanDataForm = {
      ...dataForm,
      ...(parent ? set({}, parent.name, parent.value) : {}),
    } as any;
    toRemove.forEach(item => delete cleanDataForm[item.name]);

    const newSubcategory =
      options.length > 1
        ? {
            category: [
              {
                typeElement: 'select',
                label: `${t('SUBCATEGORY.LABEL')}${label ? ` ${label}` : ''}`,
                name: `${name}___id`,
                dataElement: {
                  placeholder: placeholder || t('SUBCATEGORY.PLACEHOLDER'),
                  type: 'number',
                },
                options: options || [],
                col: { xs: 12 },
              },
            ],
          }
        : undefined;
    setDataSubcategoriesStructure(prev => [
      ...toKeep,
      ...(newSubcategory
        ? createStructureForm([newSubcategory], cleanDataForm)
        : []),
    ]);
    return cleanDataForm;
  };

  function createSubCategoriesStructure(data: SubCategoriesStructureType[]) {
    const newSubcategory = {
      category: data.map(item => ({
        typeElement: 'select',
        label: `${t('SUBCATEGORY.LABEL')}${
          item?.label ? ` ${item.label}` : ''
        }`,
        name: `${item.name}___id`,
        dataElement: {
          placeholder: t('SUBCATEGORY.PLACEHOLDER'),
          type: 'number',
        },
        options: item.options || [],
        col: { xs: 12 },
      })),
    };
    setDataSubcategoriesStructure(prev => [
      ...createStructureForm([newSubcategory], dataForm),
    ]);
  }

  return {
    categoryIdAndProgressiveForm,
    goToForm,
    goToAnimationForm,
    goToCategoryForm,
    goToAnimationProgressiveForm,
    subItemIdForm,
    dataSubcategoriesStructure,
    resourcesDataForm,
    onSubcategoryChange: upsertSubcategory,
    createSubCategoriesStructure,
  };
}

export default useActionForm;
