import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { getFiles } from '../features/auth/services/upload.service';
import http from '../utils/http';

/* export const dropdownSelectableLangs = [
  { label: "Italiano", langCode: "it" },
  { label: "English", langCode: "en" },
]; */

function promise(url: string) {
  const i18nName = url.split('/').pop()?.split('.')[0] || 'err';
  return http.get(url).then(data => {
    return {
      i18n: i18nName,
      data,
    };
  });
}
export const seti18n = (setLoadI18n: (data: boolean) => void) => {
  getFiles({ pathName: 'i18n' }).then(res => {
    const calls: Promise<any>[] = [];
    res.forEach(({ cdn }) => {
      calls.push(promise(cdn));
    });

    Promise.all(calls).then(response => {
      const resources: any = {};
      // eslint-disable-next-line array-callback-return
      response.map(item => {
        resources[item.i18n] = {
          translation: item.data,
        };
      });
      i18n
        .use(LanguageDetector)
        .use(initReactI18next)
        .init({
          resources,
          initImmediate: false,
          //lng: "it", //  commented to make languagedetector working. enable if u want to override missing strings with the fallback lang
          fallbackLng: 'it',
          //supportedLngs: ["it", "en"],
          interpolation: {
            escapeValue: false,
          },
        });
      setLoadI18n(true);
    });
  });
};
export default i18n;
