import React from 'react';
import { rankIcon } from '../constants';

const Legend = () => {
  return (
    <>
      {rankIcon.map((r, index) => (
        <span className="p-3">
          <img
            src={r.url}
            alt={`${r.description}`}
            style={{ maxWidth: '28px' }}
          />
          <span className="pl-1">{r.description}</span>
        </span>
      ))}
    </>
  );
};

export default Legend;
