import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import omit from 'lodash.omit';
import {
  addErrorMessage,
  addSuccessMessage,
} from '../../../app/errors/errorSlice';
import {
  resetCounterHistoryItem,
  resetHistorySelectedItem,
  revertHistorySelectedItem,
  setCounterHistoryItem,
  setHistorySelectedItem,
} from '../../../app/historyItemSlice';
import {
  addPositionUser,
  setPositionUser,
} from '../../../app/positionUserSlice';
import { AppThunk, RootState } from '../../../app/store';
import i18n from '../../../config/i18n';
import { copyObject } from '../../../utils/objUtils';
import { ICounter } from '../data/itemHistory';
import { IPositionCoords } from '../models/Position';
import { deleteFile, getFiles } from '../services/upload.service';
import { IBackgroundScene } from './models/BackgroundScene';
import { ISelectedObjectId } from './models/FormScene';
import { IPinScene } from './models/PinScene';
import { IIdResponse } from './models/servicesResponse';
import {
  formatTextInit,
  listBackgroundScene,
  listPinAvailableScene,
  listTabNameScene,
} from './sceneData';
import {
  deleteBackgroundForSceneId,
  deletePinForSceneId,
  editBackgroundForSceneId,
  editPinForSceneId,
  getBackgroundForSceneId,
  getBackgroundListBySceneId,
  getPinListBySceneId,
  saveBackgroundForSceneId,
  savePinForSceneId,
} from './services/scene.service';

export interface SceneSliceModel {
  selectedBackground?: IBackgroundScene;
  selectedPinScene?: IPinScene;
  listPinScene: IPinScene[];
  listPinAvailableScene: IPinScene[];
  listBackgroundSceneAvailable: IBackgroundScene[];
  listBackgroundScene: IBackgroundScene[];
  selectedObjectId?: ISelectedObjectId;
  tabUploadKeyScene: string;
  isSumbitForm: boolean;
  isEditImgPin: boolean;
  positionPan: IPositionCoords | undefined;
  zoomLevel: number;
}
const initialState: SceneSliceModel = {
  selectedBackground: undefined,
  selectedPinScene: undefined,
  listPinScene: [],
  listPinAvailableScene: listPinAvailableScene || [],
  listBackgroundSceneAvailable: listBackgroundScene,
  listBackgroundScene: [],
  selectedObjectId: undefined,
  tabUploadKeyScene: listTabNameScene.background,
  isSumbitForm: false,
  isEditImgPin: false,
  positionPan: undefined,
  zoomLevel: 1,
};

export const sceneSlice = createSlice({
  name: 'scene',
  initialState,
  reducers: {
    setListPinScene: (state, action: PayloadAction<IPinScene[]>) => {
      state.listPinScene = action.payload;
    },
    setSelectedBackground: (state, action: PayloadAction<IBackgroundScene>) => {
      state.selectedBackground = action.payload;
    },
    setListBackgroundScene: (
      state,
      action: PayloadAction<IBackgroundScene[]>
    ) => {
      state.listBackgroundScene = action.payload;
    },
    // editListPinScene: (state, action: PayloadAction<IPinScene>) => {
    //   state.listPinScene = state.listPinScene.map((el) =>
    //     el._id === action.payload._id ? { ...el, ...action.payload } : el
    //   );
    // },
    editListPinScene: (state, action: PayloadAction<IPinScene>) => {
      state.listPinScene = [
        ...state.listPinScene.filter(el => el._id !== action.payload._id),
        action.payload,
      ];
    },
    removePinInListScene: (state, action: PayloadAction<string>) => {
      state.listPinScene = state.listPinScene.filter(
        pin => pin._id !== action.payload
      );
    },
    removeBackgroundInListScene: state => {
      state.listBackgroundScene = [];
    },
    setAvailablePinSceneList: (state, action: PayloadAction<IPinScene[]>) => {
      state.listPinAvailableScene = action.payload;
    },
    removePinInListAvailableScene: (
      state,
      action: PayloadAction<IPinScene>
    ) => {
      state.listPinAvailableScene = state.listPinAvailableScene?.filter(
        pin => pin.image.iconUrl !== action.payload.image.iconUrl
      );
    },
    setAvailableBackgroundSceneList: (
      state,
      action: PayloadAction<IBackgroundScene[]>
    ) => {
      state.listBackgroundSceneAvailable = action.payload;
    },
    removeBgInListAvailableScene: (
      state,
      action: PayloadAction<IBackgroundScene>
    ) => {
      state.listBackgroundSceneAvailable =
        state.listBackgroundSceneAvailable.filter(
          pin => pin.image.iconUrl !== action.payload.image.iconUrl
        );
    },
    addAvailablePinListScene: (state, action: PayloadAction<IPinScene>) => {
      const list =
        state.listPinAvailableScene?.filter(
          ele => ele.image.iconUrl !== action.payload.image.iconUrl
        ) || [];
      state.listPinAvailableScene = [...list, action.payload];
    },
    addAvailableBackgroundSceneList: (
      state,
      action: PayloadAction<IBackgroundScene>
    ) => {
      state.listBackgroundSceneAvailable = [
        ...state.listBackgroundSceneAvailable.filter(
          ele => ele.image.iconUrl !== action.payload.image.iconUrl
        ),
        action.payload,
      ];
    },
    editSelectedPinScene: (state, action: PayloadAction<IPinScene>) => {
      state.selectedPinScene = action.payload;
    },
    resetSelectedPinScene: state => {
      state.selectedPinScene = undefined;
    },
    resetSelectedBgScene: state => {
      state.selectedBackground = undefined;
    },
    setSelectedObjectId: (state, action: PayloadAction<ISelectedObjectId>) => {
      state.selectedObjectId = action.payload;
    },
    setTabsKeyScene: (state, action: PayloadAction<string>) => {
      state.tabUploadKeyScene = action.payload;
    },
    setIsSumbitForm: (state, action: PayloadAction<boolean>) => {
      state.isSumbitForm = action.payload;
    },
    setIsEditImgPin: (state, action: PayloadAction<boolean>) => {
      state.isEditImgPin = action.payload;
    },
    setPositionPan: (state, action: PayloadAction<IPositionCoords>) => {
      state.positionPan = action.payload;
    },
    resetPositionPan: state => {
      state.positionPan = undefined;
    },
    setZoomLevel: (state, action: PayloadAction<any>) => {
      state.zoomLevel = action.payload;
    },
    resetZoomLevel: state => {
      state.zoomLevel = 1;
    },
  },
});

export const {
  setListPinScene,
  setSelectedBackground,
  setListBackgroundScene,
  editListPinScene,
  removePinInListScene,
  setAvailablePinSceneList,
  removePinInListAvailableScene,
  setAvailableBackgroundSceneList,
  removeBgInListAvailableScene,
  editSelectedPinScene,
  resetSelectedPinScene,
  addAvailableBackgroundSceneList,
  addAvailablePinListScene,
  resetSelectedBgScene,
  removeBackgroundInListScene,
  setSelectedObjectId,
  setTabsKeyScene,
  setIsSumbitForm,
  setIsEditImgPin,
  setPositionPan,
  resetPositionPan,
  setZoomLevel,
  resetZoomLevel,
} = sceneSlice.actions;

export const setPositionUserOnScene = (): AppThunk => (dispatch, getState) => {
  const position = getState().positionUser.positionUser;

  if (position) {
    dispatch(
      setPositionPan({
        x: position.x,
        y: position.y,
      })
    );
    dispatch(setZoomLevel(position.z));
  }
};

export const deletePinAvailable =
  (pin: IPinScene): AppThunk =>
  dispatch => {
    const documentName = 'pins/' + pin.image.iconUrl.split('/').pop();
    documentName &&
      deleteFile(documentName).then(
        data => {
          dispatch(removePinInListAvailableScene(pin));
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETEPINAVAILABLE'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETEPINAVAILABLE'),
            })
          )
      );
  };
export const deleteBgAvailable =
  (bg: IBackgroundScene): AppThunk =>
  dispatch => {
    const documentName = 'backgrounds/' + bg.image.iconUrl.split('/').pop();
    documentName &&
      deleteFile(documentName).then(
        data => {
          dispatch(removeBgInListAvailableScene(bg));
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETEBGAVAILABLE'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETEBGAVAILABLE'),
            })
          )
      );
  };

export const getListPin =
  (idScene: string): AppThunk =>
  dispatch => {
    getPinListBySceneId(idScene).then(
      res => {
        dispatch(setListPinScene(res));
      },
      err =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.LOADINGPINSSCENE'),
          })
        )
    );
  };

export const getListPinAvailableScene = (): AppThunk => dispatch => {
  getFiles({ pathName: 'pins' }).then(
    data => {
      // eslint-disable-next-line array-callback-return
      data.map(pin => {
        const img = {
          _id: '',
          name: pin.originalfilename,
          position: { x: 0, y: 0 },
          image: {
            iconUrl: pin.url,
            iconSize: {
              width: 84,
              height: 84,
            },
          },
          type: pin.mimetype,
          action: '',
          subItemId: undefined,
          formatText: formatTextInit,
        };
        dispatch(addAvailablePinListScene(img));
      });
    },
    err =>
      dispatch(
        addErrorMessage({
          title: i18n.t('ERRORSMSG.LOADINGPINSSAVAILABLE'),
        })
      )
  );
};

export const getListBgAvailableScene = (): AppThunk => dispatch => {
  getFiles({ pathName: 'backgrounds' }).then(
    data => {
      data.map(bg =>
        dispatch(
          addAvailableBackgroundSceneList({
            _id: '',
            image: {
              iconUrl: bg.url,
              iconSize: {
                width: 1920,
                height: 1080,
              },
            },
            name: bg.originalfilename,
            position: {
              x: 0,
              y: 0,
            },
            type: bg.mimetype,
          })
        )
      );
    },
    err =>
      dispatch(
        addErrorMessage({
          title: i18n.t('ERRORSMSG.LOADINGBGSSAVAILABLE'),
        })
      )
  );
};

export const getBgScene =
  (idScene: string): AppThunk =>
  dispatch => {
    getBackgroundListBySceneId(idScene).then(
      res => {
        if (res && res.length > 0) {
          dispatch(setListBackgroundScene(res));
        }
      },
      err =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.LOADINGBGSSCENE'),
          })
        )
    );
  };

export const getHistoryPinScene =
  (azione: ICounter): AppThunk =>
  (dispatch, getState) => {
    const historyPinScene = getState().historyItem
      .historySelectedItem as IPinScene[];
    const counter = getState().historyItem.counterHistoryItem;
    dispatch(setCounterHistoryItem(azione));
    dispatch(
      editSelectedPinScene(
        historyPinScene[azione === 'next' ? counter + 1 : counter - 1]
      )
    );
  };

export const getHistoryBgScene =
  (azione: ICounter): AppThunk =>
  (dispatch, getState) => {
    const historyBgScene = getState().historyItem
      .historySelectedItem as IBackgroundScene[];
    const counter = getState().historyItem.counterHistoryItem;
    dispatch(setCounterHistoryItem(azione));
    dispatch(
      setSelectedBackground(
        historyBgScene[azione === 'next' ? counter + 1 : counter - 1]
      )
    );
  };

export const revertHistoryFirstSelectedPinScene =
  (): AppThunk => (dispatch, getState) => {
    dispatch(revertHistorySelectedItem(1));
    dispatch(resetCounterHistoryItem(1));
    dispatch(
      editSelectedPinScene(
        getState().historyItem.historySelectedItem[0] as IPinScene
      )
    );
  };

export const revertHistoryFirstSelectedBgScene =
  (): AppThunk => (dispatch, getState) => {
    dispatch(revertHistorySelectedItem(1));
    dispatch(resetCounterHistoryItem(1));
    dispatch(
      setSelectedBackground(
        getState().historyItem.historySelectedItem[0] as IBackgroundScene
      )
    );
  };

export const editSelectedPin =
  (pin: IPinScene): AppThunk =>
  (dispatch, getState) => {
    const historyPinScene = getState().historyItem
      .historySelectedItem as IPinScene[];
    const counter = getState().historyItem.counterHistoryItem;
    if (historyPinScene.length - 1 > counter) {
      dispatch(revertHistorySelectedItem(counter + 1));
    }
    dispatch(editSelectedPinScene(pin));
    dispatch(changeHistoryAndCounterPinScene(pin, ICounter.NEXT));
    dispatch(setIsEditImgPin(false));
  };

export const editSelectedBgScene =
  (bg: IBackgroundScene): AppThunk =>
  (dispatch, getState) => {
    const historyPinScene = getState().historyItem
      .historySelectedItem as IPinScene[];
    const counter = getState().historyItem.counterHistoryItem;
    if (historyPinScene.length - 1 > counter) {
      dispatch(revertHistorySelectedItem(counter + 1));
    }
    dispatch(setSelectedBackground(bg));
    dispatch(changeHistoryAndCounterBgScene(bg, ICounter.NEXT));
  };

export const changeHistoryAndCounterPinScene =
  (pin?: IPinScene, azione?: ICounter): AppThunk =>
  dispatch => {
    pin
      ? dispatch(setHistorySelectedItem(pin))
      : dispatch(resetHistorySelectedItem());
    azione
      ? dispatch(setCounterHistoryItem(azione))
      : dispatch(resetCounterHistoryItem());
  };

export const changeHistoryAndCounterBgScene =
  (bg?: IBackgroundScene, azione?: ICounter): AppThunk =>
  dispatch => {
    bg
      ? dispatch(setHistorySelectedItem(bg))
      : dispatch(resetHistorySelectedItem());
    azione
      ? dispatch(setCounterHistoryItem(azione))
      : dispatch(resetCounterHistoryItem());
  };

export const addPinInScene =
  (pin: IPinScene): AppThunk =>
  dispatch => {
    const pinConId = { ...pin, id_temp: (Math.random() * 10).toString() };
    dispatch(editSelectedPinScene(pinConId));
    dispatch(changeHistoryAndCounterPinScene(pinConId, ICounter.NEXT));
  };

export const addBgInScene =
  (background: IBackgroundScene): AppThunk =>
  dispatch => {
    const backgroundConId = {
      ...background,
      id_temp: (Math.random() * 10).toString(),
    };
    dispatch(selectBackgroundSceneWithId(backgroundConId));
  };

export const changeImageBgInScene =
  (background: IBackgroundScene): AppThunk =>
  dispatch => {
    dispatch(selectBackgroundSceneWithId(background));
  };

export const selectBackgroundSceneWithId =
  (background: IBackgroundScene): AppThunk =>
  dispatch => {
    dispatch(setSelectedBackground(background));
    dispatch(changeHistoryAndCounterBgScene(background, ICounter.NEXT));
  };

export const selectedPinSceneById =
  (id: string): AppThunk =>
  (dispatch, getState) => {
    const listPin = getState().scene.listPinScene;
    const selectedPin = getState().scene.selectedPinScene;
    const trovaPin =
      selectedPin?._id !== id
        ? listPin.find(ele => ele._id === id)
        : selectedPin;
    trovaPin && dispatch(editSelectedPin(trovaPin));
  };

export const savePinScene =
  (sceneId: string, pin: IPinScene): AppThunk =>
  (dispatch, getState) => {
    const list = getState().scene.listPinScene;
    const copyPin: IPinScene = copyObject(pin);
    let newId: ISelectedObjectId = {
      id_temp: copyPin.id_temp!,
      _id: '',
    };
    if (copyPin.id_temp) {
      const newPin = omit(copyPin, ['_id', 'id_temp', 'subCategory']);
      savePinForSceneId(sceneId, newPin).then(
        (res: IIdResponse) => {
          delete copyPin.id_temp;
          copyPin._id = res._id;
          newId._id = res._id!;
          newId.id_temp = '';
          dispatch(resetSelectedPinScene());
          dispatch(changeHistoryAndCounterPinScene());
          dispatch(setSelectedObjectId(newId));
          dispatch(addAvailablePinListScene(copyPin));
          dispatch(setListPinScene([...list, copyPin]));
          dispatch(setIsEditImgPin(false));
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.SAVEPIN'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.SAVEPIN'),
            })
          )
      );

      return;
    }
    newId._id = copyPin._id;
    newId.id_temp = '';
    const newPin = omit(copyPin, ['subCategory']);
    editPinForSceneId(sceneId, newPin).then(
      () => {
        dispatch(editListPinScene(newPin));
        setTimeout(() => {
          dispatch(resetSelectedPinScene());
        }, 1000);
        dispatch(changeHistoryAndCounterPinScene());
        dispatch(setSelectedObjectId(newId));
        dispatch(setIsEditImgPin(false));
        dispatch(
          addSuccessMessage({
            title: i18n.t('SUCCESSMSG.EDITPIN'),
          })
        );
      },
      err =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.EDITPIN'),
          })
        )
    );
  };

export const saveBgScene =
  (sceneId: string, bg: IBackgroundScene): AppThunk =>
  dispatch => {
    const copyBg = copyObject(bg);
    dispatch(resetSelectedBgScene());
    dispatch(changeHistoryAndCounterBgScene());
    if (copyBg.id_temp) {
      const newBg = omit(copyBg, ['_id', 'id_temp']);
      saveBackgroundForSceneId(sceneId, newBg).then(
        (res: IIdResponse) => {
          getBackgroundForSceneId(sceneId, res._id).then(data => {
            dispatch(addAvailableBackgroundSceneList(data));
            dispatch(setListBackgroundScene([data]));
            dispatch(
              addSuccessMessage({
                title: i18n.t('SUCCESSMSG.SAVEBG'),
              })
            );
          });
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.SAVEBG'),
            })
          )
      );
      return;
    }
    editBackgroundForSceneId(sceneId, copyBg).then(
      data => {
        dispatch(setListBackgroundScene([data]));
        dispatch(
          addSuccessMessage({
            title: i18n.t('SUCCESSMSG.SAVEBG'),
          })
        );
      },
      err =>
        dispatch(
          addErrorMessage({
            title: i18n.t('ERRORSMSG.EDITBG'),
          })
        )
    );
  };

export const deletePinScene =
  (sceneId: string, pin: IPinScene): AppThunk =>
  dispatch => {
    const cleanSelected = () => {
      dispatch(changeHistoryAndCounterPinScene());
      dispatch(resetSelectedPinScene());
      dispatch(setIsEditImgPin(false));
    };
    pin._id
      ? deletePinForSceneId(sceneId, pin._id).then(
          () => {
            dispatch(removePinInListScene(pin._id));
            dispatch(changeHistoryAndCounterPinScene());
            dispatch(setIsEditImgPin(false));
            dispatch(
              addSuccessMessage({
                title: i18n.t('SUCCESSMSG.DELETEPIN'),
              })
            );
            setTimeout(() => {
              dispatch(resetSelectedPinScene());
            }, 1000);
          },
          err =>
            dispatch(
              addErrorMessage({
                title: i18n.t('ERRORSMSG.DELETEPIN'),
              })
            )
        )
      : cleanSelected();
  };

export const deleteBackgroundScene =
  (idScene: string, selectedBg: IBackgroundScene): AppThunk =>
  dispatch => {
    if (selectedBg && selectedBg.id_temp) {
      dispatch(changeHistoryAndCounterBgScene());
      dispatch(resetSelectedBgScene());
    } else {
      deleteBackgroundForSceneId(idScene, selectedBg._id).then(
        res => {
          dispatch(removeBackgroundInListScene());
          dispatch(changeHistoryAndCounterBgScene());
          dispatch(resetSelectedBgScene());
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETEBG'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETEBG'),
            })
          )
      );
    }
    dispatch(resetPositionScene(idScene));
  };

export const resetPositionScene =
  (idScene: string): AppThunk =>
  dispatch => {
    const resetPos = {
      name: `scene_${idScene}`,
      x: 0,
      y: 0,
      z: 1,
    };
    dispatch(addPositionUser(resetPos));
    dispatch(setPositionUser(resetPos));
    dispatch(resetPositionPan());
    dispatch(resetZoomLevel());
  };

export const resetSceneAll = (): AppThunk => dispatch => {
  dispatch(resetCounterHistoryItem());
  dispatch(resetHistorySelectedItem());
  dispatch(resetSelectedBgScene());
  dispatch(resetSelectedPinScene());
  dispatch(removeBackgroundInListScene());
};

export const selectListPinScene = (state: RootState) =>
  state.scene.listPinScene;

export const selectListPinAvailableScene = (state: RootState) =>
  state.scene.listPinAvailableScene;

export const selectSelectedPinScene = (state: RootState) =>
  state.scene.selectedPinScene;

export const selectListBackgroundSceneAvailable = (state: RootState) =>
  state.scene.listBackgroundSceneAvailable;
export const selectSelectedBackgroundScene = (state: RootState) =>
  state.scene.selectedBackground;
export const selectListBackgroundScene = (state: RootState) =>
  state.scene.listBackgroundScene;
export const selectSelectedObjectId = (state: RootState) =>
  state.scene.selectedObjectId;
export const selectTabUploadKeyScene = (state: RootState) =>
  state.scene.tabUploadKeyScene;
export const selectIsSumbitForm = (state: RootState) =>
  state.scene.isSumbitForm;

export const selectIsEditImgPin = (state: RootState) =>
  state.scene.isEditImgPin;
export const selectPositionPan = (state: RootState) => state.scene.positionPan;
export const selectZoomLevel = (state: RootState) => state.scene.zoomLevel;

export const selectListBgSceneNew = createSelector(
  selectListBackgroundScene,
  selectSelectedBackgroundScene,
  (listBgScene, selectedBgScene) => {
    let newListBg: IBackgroundScene[] = [];
    if (selectedBgScene && selectedBgScene.id_temp) {
      newListBg = [selectedBgScene];
      return newListBg;
    }
    return [
      listBgScene[0]?._id === selectedBgScene?._id
        ? selectedBgScene
        : listBgScene[0],
    ];
  }
);

export const selectListPinSceneNew = createSelector(
  selectListPinScene,
  selectSelectedPinScene,
  (listPin, selectedPin) => {
    let newListPin: IPinScene[] = [];
    if (selectedPin && selectedPin.id_temp) {
      newListPin = [...listPin, selectedPin];
      return newListPin;
    }
    return listPin.map((pin: IPinScene) =>
      pin._id === selectedPin?._id ? { ...pin, ...selectedPin } : pin
    );
  }
);

export default sceneSlice.reducer;
