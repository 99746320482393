import React from 'react';
import { FormGroup as FormGroupBt } from 'react-bootstrap';
import { FormGroupProps } from '../models/FormModule';
import Label from '../label/Label';
import Error from '../error/Error';

const FormGroup = ({
  display,
  label,
  error,
  children,
  ...rest
}: FormGroupProps) => {
  return (
    <FormGroupBt {...rest}>
      <div className={display ? display : ''}>
        {label ? <Label {...label} /> : null}
        {children}
      </div>
      {error ? <Error {...error} /> : null}
    </FormGroupBt>
  );
};

export default FormGroup;
