import isEqual from 'lodash.isequal';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { IBtnList } from '../../../../app/auth/model/BtnList';
import ToolbarBtn from '../../../../components/shared/custom/sidebar/toolbarBtn/ToolbarBtn';
import FormElements from '../../../../utils/forms/FormElements';
import { UseFormSubmitModel } from '../../../../utils/forms/models/UseFormModel';
import { useForm } from '../../../../utils/forms/useForm';
import {
  getObjForm,
  setObjForm,
} from '../../../../utils/forms/useStructureForm';
import { SkillsRadarConfig } from '../models/type';
import useRadarForm from './useRadarForm';
import { validations } from './ValidationData';

function RadarForm({
  categoryId,
  skillRadarConfig,
  toolbarData,
  onToolbarAction,
  onChangeDataForm,
}: {
  categoryId: string;
  skillRadarConfig?: SkillsRadarConfig;
  toolbarData: {
    left: IBtnList[];
    right: IBtnList[];
  };
  onToolbarAction: (action: string) => void;
  onChangeDataForm: (data: SkillsRadarConfig) => void;
}) {
  const { t } = useTranslation();
  const submitHandler: UseFormSubmitModel = (event, dataSubmit: any) => {
    onToolbarAction('save');
  };
  const [loading, setLoading] = useState(true);
  const validationsData = validations();
  const form = useForm(submitHandler, validationsData);
  const {
    isSubmit,
    errors,
    submitAction,
    changeValue,
    dataForm,
    setData,
    dataFormatted,
  } = form;

  const { radarStructuredForm } = useRadarForm(t, dataForm, Number(categoryId));

  useEffect(() => {
    if (skillRadarConfig) {
      const toForm = setObjForm(skillRadarConfig);
      if (!isEqual(toForm, dataForm)) {
        setData(toForm);
      }
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skillRadarConfig]);

  useEffect(() => {
    if (!loading) {
      const toObj = getObjForm(dataForm);
      if (!isEqual(dataFormatted, skillRadarConfig)) {
        onChangeDataForm(toObj as SkillsRadarConfig);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm]);

  return (
    <>
      <ToolbarBtn
        btnListLeft={toolbarData.left}
        btnListRight={toolbarData.right}
        clickAction={(action: string) => {
          switch (action) {
            case 'save':
              submitAction();
              break;
            default:
              onToolbarAction(action);
          }
        }}
      />
      <Form onSubmit={submitAction}>
        <FormElements
          data={radarStructuredForm}
          dataFormatted={dataFormatted}
          errors={errors}
          isSubmit={isSubmit}
          dataForm={dataForm}
          changeValue={changeValue}
        />
      </Form>
    </>
  );
}

export default RadarForm;
