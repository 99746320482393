import http from '../../../../../utils/http';
import {
  IAnswerMessageResponse,
  IAttemptResultResponse,
  ILoadDataGameResponse,
  INextQuestionGame,
} from '../models/servicesResponse';

const urlLoadGame = `/playgame/load`;
const urlNextQuestionGame = `/playgame/nextquestion`;
const urlQuestonAnswer = `/playgame/questionanswer`;
const urlAttemptResult = `/playgame/attemptresult`;
const urlEnhancement = `/improvementarea/pdf`;

export const getLoadDataGame = (
  gameId: string
): Promise<ILoadDataGameResponse> => http.get(`${urlLoadGame}/${gameId}`);

export const getNextQuestionGame = (
  gameId: string,
  timeStamp: string = new Date().getTime().toString()
): Promise<INextQuestionGame> =>
  http.get(`${urlNextQuestionGame}/${gameId}/${timeStamp}`);

export const getQuestionAnswer = (
  gameId: string = '',
  timeStamp: string = '',
  choices: string
): Promise<IAnswerMessageResponse> =>
  http.get(`${urlQuestonAnswer}/${gameId}/${timeStamp}/${choices}`);

export const postQuestionAnswer = (
  gameId: string = '',
  timeStamp: string = new Date().getTime().toString(),
  dragAndDropResults: any
): Promise<IAnswerMessageResponse> =>
  http.post(`${urlQuestonAnswer}/${gameId}/${timeStamp}`, dragAndDropResults);

export const getAttemptResult = (
  gameId: string = ''
): Promise<IAttemptResultResponse> => http.get(`${urlAttemptResult}/${gameId}`);

export const getEmprovement = (
  gameId: string = '',
  categoryId: string = '',
  isExtended: boolean
): Promise<IAttemptResultResponse> =>
  http.get(
    `${urlEnhancement}/${gameId}/enhancementId/${categoryId}/${isExtended}`
  );
