import { TFunction } from 'i18next';
import { FormElementModelNew } from '../../../utils/forms/models/FormElementModel';
import { createStructureForm } from '../../../utils/forms/useStructureForm';
import { HttpCodeItem } from '../menu/menuLevels/aclManagement/model/config';

export const useCheckForm = (
  t: TFunction,
  httpCodeList: HttpCodeItem[],
  namesList: any[],
  categoriesList: any[]
) => {
  const checkNameRow: FormElementModelNew[] = createStructureForm([
    {
      typeElement: 'input',
      label: t('CHECK.NAME'),
      name: 'name',
      dataElement: {
        placeholder: t('CHECK.NAME'),
        type: 'text',
        disabled: false,
      },
      col: { xs: 12 },
    },
    {
      typeElement: 'autocomplete',
      label: t('CHECK.FINDACL'),
      name: 'acl',
      dataElement: {
        placeholder: t('CHECK.FINDACL'),
        type: 'text',
        disabled: false,
      },
      options: [
        {
          value: '-1',
          label: t('APP.FORM.CHOOSE'),
        },
        ...namesList.map(name => {
          return {
            value: name._id,
            label: name.name,
          };
        }),
      ],
      col: { xs: 12 },
    },
  ]);

  const checkArgsRow: FormElementModelNew[] = createStructureForm([
    {
      typeElement: 'input',
      label: t('CHECK.PARAMETER'),
      name: 'paramenter',
      dataElement: {
        placeholder: t('CHECK.PARAMETER'),
        type: 'text',
        disabled: false,
      },
      col: { xs: 12 },
    },
  ]);

  const checkCategoryRow: FormElementModelNew[] = createStructureForm([
    {
      typeElement: 'select',
      label: t('CHECK.CATEGORIES'),
      name: 'categories',
      dataElement: {
        placeholder: t('CHECK.CATEGORIES'),
        type: 'text',
      },
      options: [
        {
          value: '-1',
          label: t('APP.FORM.CHOOSE'),
        },
        ...categoriesList.map(category => {
          return {
            value: `${category.name + '-' + category.id}`,
            label: category.name,
          };
        }),
      ],
      col: { xs: 12 },
    },
  ]);

  const checkHttpRow: FormElementModelNew[] = createStructureForm([
    {
      typeElement: 'select',
      label: t('CHECK.HTTPSTATUSRESPONSE'),
      name: 'httpStatusRespone',
      dataElement: {
        placeholder: t('CHECK.HTTPSTATUSRESPONSE'),
        type: 'text',
      },
      options: [
        {
          value: '-1',
          label: t('APP.FORM.CHOOSE'),
        },
        ...httpCodeList.map(httpCode => {
          return {
            value: httpCode.code,
            label: httpCode.code,
          };
        }),
      ],
      col: { xs: 12 },
    },
    {
      typeElement: 'input',
      label: t('CHECK.ERRORMESSAGE'),
      name: 'errorMessage',
      dataElement: {
        placeholder: t('CHECK.ERRORMESSAGE'),
        type: 'text',
        disabled: false,
      },
      col: { xs: 12 },
    },
  ]);

  return {
    checkNameRow,
    checkArgsRow,
    checkCategoryRow,
    checkHttpRow,
  };
};
