import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import useAction, { ActionType } from './useAction';

export interface Trigger {
  when: string;
  milliseconds?: number;
  repeat?: boolean;
  event?: string;
  messageName?: string;
}

export interface EvaluationType {
  trigger: Trigger;
  run: ActionType;
}

function useEvaluation(evaluations: EvaluationType[]) {
  const actions = useAction();

  function evaluation(triggerIn: Trigger, callback?: ActionType['callback']) {
    if (isEmpty(evaluations)) {
      throw new Error('Evalutations list is empty');
    }
    const evaluationList = evaluations.filter(({ trigger }) =>
      isEqual(trigger, triggerIn)
    );
    evaluationList.forEach(ev => {
      actions[ev.run.action]({
        ...ev.run,
        callback,
      });
    });
  }

  return evaluation;
}

export default useEvaluation;
