import omit from 'lodash.omit';
import { IMicrobadgeConfig } from './../models/badge';
import http from '../../../../../utils/http';

const urlMicrobadgeConfig = `/badge-configuration`;

export const getAllMicrobadge = (): Promise<IMicrobadgeConfig[]> =>
  http.get(`${urlMicrobadgeConfig}/microbadge`);
export const getAllMicrobadgefromCatId = (
  catId: string
): Promise<IMicrobadgeConfig[]> =>
  http.get(`${urlMicrobadgeConfig}/microbadge/${catId}`);

export const getAllMicrobadgefromCatIdMicroId = (
  catId: string,
  microId: string
): Promise<IMicrobadgeConfig[]> =>
  http.get(`${urlMicrobadgeConfig}/microbadge/${catId}/${microId}`);

export const saveMicrobadgeforCatId = (
  data: IMicrobadgeConfig
): Promise<IMicrobadgeConfig[]> => {
  const newData = omit(data, [
    'categoryId',
    'gameId',
    'microCategoryId',
    'microgameId',
  ]);
  return http.post(
    `${urlMicrobadgeConfig}/microbadge/${data.categoryId}/${data.microCategoryId}`,
    newData
  );
};
export const editMicrobadgeforCatId = (
  data: IMicrobadgeConfig
): Promise<IMicrobadgeConfig[]> => {
  const newData = omit(data, [
    'categoryId',
    'gameId',
    '_id',
    'id',
    'microCategoryId',
    'microgameId',
  ]);
  return http.put(
    `${urlMicrobadgeConfig}/microbadge/${data.categoryId}/${data.microCategoryId}/${data._id}`,
    newData
  );
};
export const deleteMicrobadge = (
  data: IMicrobadgeConfig
): Promise<IMicrobadgeConfig[]> => {
  return http.delete(
    `${urlMicrobadgeConfig}/microbadge/${data.categoryId}/${data.microCategoryId}/${data._id}`
  );
};
