export const useSurveyQuestions = {
  title: 'Survey G4Mob',
  questions: [
    {
      progressive: 1,
      question: 'Quale è stato l’impatto iniziale al prodotto: G4mobility?',
      accepted: ['Eccellente'],
      discarder: ['Buono', 'Normale', 'Esaustivo', 'Negativo'],
    },
    {
      progressive: 2,
      question: 'Come giudica la qualità del prodotto: G4mobility?',
      accepted: ['Eccellente'],
      discarder: ['Buono', 'Normale', 'Esaustivo', 'Negativo'],
    },
    {
      progressive: 3,
      question: 'Quanto innovativo ritiene sia il prodotto: G4mobility?',
      accepted: ['Eccellente'],
      discarder: ['Buono', 'Normale', 'Esaustivo', 'Negativo'],
    },
    {
      progressive: 4,
      question:
        'Ai fini della divulgazione/comunicazione quanto ritiene utile il prodotto: G4mobility?',
      accepted: ['Eccellente'],
      discarder: ['Buono', 'Normale', 'Esaustivo', 'Negativo'],
    },
    {
      progressive: 5,
      question: 'Introdurrebbe il prodotto nella propria organizzazione?',
      accepted: ['Estremamente probabile'],
      discarder: [
        'Molto probabile',
        'Probabile',
        'Poco Probabile',
        'Per niente Probabile',
      ],
    },
    // {
    //     "progressive": 1,
    //     "question": "Quale è stato l’impatto iniziale al prodotto: G4mobility",
    //     "responses": [
    //         "Eccellente",
    //         "Buono",
    //         "Normale",
    //         "Esaustivo",
    //         "Negativo"
    //     ],
    //     "response": 1
    // }
  ],
};
