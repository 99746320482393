import { Routes } from '../utils/routes/models/routes';
import BASE_PATHS from './basePaths';

const BASE_PATH = BASE_PATHS.PUBLIC;
export const routes: Routes = {
  list: [
    {
      path: `${BASE_PATH}/register`,
      component: 'features/public/register/Register',
      name: 'Register',
    },
  ],
  redirect: `${BASE_PATH}/register`,
  basepath: BASE_PATH,
};

export default routes;
