import { FabricJSEditor } from 'fabricjs-react';
import { TEXT_MARGIN_TOP } from './sizeUtils';

export interface IPinFabric extends fabric.Object {
  id: string;
}

export const positionText = (
  editor: FabricJSEditor | undefined,
  pin: IPinFabric
) => {
  const text = editor?.canvas.getObjects().find(ele => {
    return ele.name?.toString().split('objId_')[1] === pin.id;
  });
  const activeObject = editor?.canvas.getActiveObject();
  let scaledHeightPin = undefined;
  if (activeObject) {
    scaledHeightPin = activeObject.getScaledHeight();
  }
  text?.set({
    left: pin.left || 0,
    top: (pin.top || 0) + ((scaledHeightPin || 0) / 2 + TEXT_MARGIN_TOP),
  });
  text?.setCoords();
};
