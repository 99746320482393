import omit from 'lodash.omit';
import http from '../../../../../../utils/http';
import { ICheckItem } from '../model/config';

const acl = '/acl';

export const getCheckByName = (
  idCat?: number,
  name?: string
): Promise<ICheckItem[]> => {
  if (!idCat && name) {
    return http.get(`${acl}/definition/?name=${name}`);
  } else if (idCat && !name) {
    return http.get(`${acl}/definition/?category=${idCat}`);
  } else {
    return http.get(`${acl}/definition/?category=${idCat}&name=${name}`);
  }
};

export const getAllChecks = (): Promise<ICheckItem[]> =>
  http.get(`${acl}/definition`);

export const removeCheck = (idCheckToRemove: string): Promise<any> =>
  http.delete(`${acl}/definition/${idCheckToRemove}`);

export const updateCheck = (
  idCheckToUpdate: string,
  data: ICheckItem
): Promise<any> => {
  let newData = omit(data, ['_id', 'id']);
  return http.put(`${acl}/definition/${idCheckToUpdate}`, newData);
};

export const addCheck = (data: ICheckItem): Promise<any> =>
  http.post(`${acl}/definition`, data);
