import React from 'react';
import { Col, Container } from 'react-bootstrap';
import Row from 'react-bootstrap/esm/Row';
import { useTranslation } from 'react-i18next';
import useVersion from '../../../../hooks/useVersion';

const FooterLayout = () => {
  const { t } = useTranslation();

  const version = useVersion();

  return (
    <div className="footer">
      <Container fluid>
        <Row>
          <Col xs="12" className="text-right">
            <small>{`${t('FOOTER.RIGHT')} - v. ${version}`}</small>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FooterLayout;
