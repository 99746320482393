import { required } from '../../../../../utils/forms/utils/validationType';

export const validations = () => {
  return [
    {
      gameHeader: {
        form: [
          {
            name: 'gameFatherId',
            validations: [
              {
                name: required,
                params: { name: 'gameFatherId' },
              },
            ],
          },
          {
            name: 'Skills',
            validations: [
              {
                name: required,
                params: { name: 'Skills' },
              },
            ],
          },
        ],
      },
      animates: {
        form: [
          {
            name: 'name',
            validations: [
              {
                name: required,
                params: { name: 'name' },
              },
            ],
          },
          {
            name: 'uri',
            validations: [
              {
                name: required,
                params: { name: 'uri' },
              },
            ],
          },
        ],
      },
    },
  ];
};
