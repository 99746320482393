import http from '../../../../../../utils/http';
import {
  IMicroBadgeResponse,
  IMyprogressResponse,
} from '../models/serviceResponse';

const urlMyprogress = '/myprogress';

export const getBadges = (): Promise<IMyprogressResponse> =>
  http.get(`${urlMyprogress}/now`);

export const getMicroBadges = (areaId: string): Promise<IMicroBadgeResponse> =>
  http.get(`${urlMyprogress}/microbadgesforarea/${areaId}`);
