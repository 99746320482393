import React from 'react';
import { Form } from 'react-bootstrap';
import FormElements from '../../../../../utils/forms/FormElements';
import { UseFormSubmitModel } from '../../../../../utils/forms/models/UseFormModel';
import { UserPreference } from '../../../menu/menuLevels/userManagement/types';
import { ICategory } from '../../../models/Categories';
import useReportsForm from './useReportsForm';

export type ReportsFormType = {
  categoryId: number;
  depth?: number;
  username?: string;
};
export type ReportsFormProps = {
  onSubmit: UseFormSubmitModel<ReportsFormType>;
  categories: ICategory[];
  users: UserPreference[];
};
function ReportsForm(props: ReportsFormProps) {
  const { onSubmit, categories, users } = props;

  const { searchFormStructure, treeViewStructure, form } =
    useReportsForm<ReportsFormType>({
      submitHandler: (ev, data, dataNotFormatted) => {
        onSubmit(
          ev,
          {
            ...data,
          },
          dataNotFormatted
        );
      },
      categories,
      users,
    });

  return (
    <Form onSubmit={form.submitAction}>
      <FormElements
        data={searchFormStructure}
        dataFormatted={form.dataFormatted}
        errors={form.errors}
        isSubmit={form.isSubmit}
        dataForm={form.dataForm}
        changeValue={form.changeValue}
        className={'gamereport-searchform'}
      />
      <FormElements
        data={treeViewStructure}
        dataFormatted={form.dataFormatted}
        errors={form.errors}
        isSubmit={form.isSubmit}
        dataForm={form.dataForm}
        changeValue={form.changeValue}
      />
    </Form>
  );
}

export default ReportsForm;
