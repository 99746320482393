import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Icon from '../../../icon/Icon';

function DropzoneSmall(_props: {
  onDrop: (acceptedFiles: any) => void;
  onClose: () => void;
  accept?: string;
}) {
  const onDrop = useCallback(
    acceptedFiles => {
      _props.onDrop(acceptedFiles);
    },
    [_props]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: _props.accept,
  });

  return (
    <>
      <div className="dropzone-small" {...getRootProps()}>
        <input {...getInputProps()} />
        <Icon icon="save_alt" size="36px"></Icon>
        {<p className="mt-4"></p>}
      </div>
    </>
  );
}

export default DropzoneSmall;
