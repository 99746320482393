import { useMemo } from 'react';
import { DEFAULT_LOGO_URL } from '../constants';
import useWellKnown from './useWellKnown';

const useLogo = () => {
  const { wellKnown } = useWellKnown();
  return useMemo(
    () => {return {url: wellKnown?.metadata.logo?.url ?? DEFAULT_LOGO_URL,
      width: wellKnown?.metadata.logo?.width ?? '180px',
      height: wellKnown?.metadata.logo?.height ?? 'auto'}
    },
    [wellKnown]
  );
};

export default useLogo;
