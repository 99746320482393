import { required } from '../../../../utils/forms/utils/validationType';

export const validations = () => {
  return [
    {
      name: 'title',
      validations: [
        {
          name: required,
          params: { name: 'title' },
        },
      ],
    },
    {
      name: 'skills',
      validations: [
        {
          name: required,
          params: { name: 'skills' },
        },
      ],
    },
  ];
};
