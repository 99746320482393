import { IBtnList } from '../../../../app/auth/model/BtnList';
import { ITabs } from '../../../../components/shared/design/tabs/models/tabsModels';

export const listTabNameMicroGame = {
  background: 'background',
};

export const listTabsMicroGame: ITabs[] = [
  {
    keyTab: listTabNameMicroGame.background,
    title: 'APP.BACKGROUND',
  },
];

export const microGameToolbarBtn: IBtnList[] = [
  {
    label: 'APP.DELETE',
    icon: 'delete',
    color: '#34a381',
    action: 'delete',
  },
  {
    label: 'APP.SAVE',
    icon: 'save',
    color: '#34a381',
    action: 'save',
  },
];

export const microGameToolbarBtnCheck: IBtnList[] = [
  {
    label: 'APP.SAVE',
    icon: 'save',
    color: '#34a381',
    action: 'save',
  },
];
