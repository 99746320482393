import { IBtnList } from '../../../../app/auth/model/BtnList';
import { IBackgroundGame } from './models/backgroundGame';
import { ILayoutGame, ILayoutIcons } from './models/layoutGame';

export const initListBgGameAvailable: IBackgroundGame[] = [
  {
    url: 'https://images2.alphacoders.com/902/thumb-1920-902946.png',
    type: 'image/png',
    name: 'sfondo1',
    initialHeight: 1080,
    initialWidth: 1920,
  },
  {
    url: 'https://i.pinimg.com/originals/f3/06/89/f30689a90e172e95a277af16ad98b9c6.jpg',
    type: 'image/png',
    name: 'sfondo2',
    initialHeight: 1280,
    initialWidth: 2048,
  },
];

export const gamePlatformToolbarBtn: IBtnList[] = [
  {
    label: 'APP.DELETE',
    title: 'APP.DELETE',
    icon: 'delete',
    color: '#34a381',
    action: 'delete',
  },
  {
    label: 'APP.SAVE',
    title: 'APP.SAVE',
    icon: 'save',
    color: '#34a381',
    action: 'save',
  },
];

export const summaryGameInfoInit = {
  score: 515,
  attempt: 1,
  level: 'Coaching Skills',
  title: 'PEOPLE MANAGEMENT',
  lifes: [
    {
      status: false,
    },
    {
      status: false,
    },
    {
      status: true,
    },
    {
      status: true,
    },
    {
      status: true,
    },
  ],
};

export const listTabNameGame = {
  background: 'background',
  layout: 'layout',
  gameSettings: 'gameSettings',
  microgameSettings: 'microgameSettings',
  gameResult: 'gameResult',
  questions: 'questions',
  badge: 'badge',
  microbadge: 'microbadge',
};

export const layoutIconsInit: ILayoutIcons = {
  color: '#0000ff',
  initialHeight: 0,
  initialWidth: 0,
  type: 'image/svg+xml',
  url: '',
};

export const layoutGameInit: ILayoutGame = {
  gameId: '',
  colorGame: 'rgb(255, 255, 255, 0.9)',
  iconPlay: {
    ...layoutIconsInit,
    url: 'https://g4mobility-stage.fra1.cdn.digitaloceanspaces.com/gameLayout/play_circle_outline.svg',
  },
  iconVite: {
    lifeTrue: {
      ...layoutIconsInit,
      url: 'https://g4mobility-stage.fra1.cdn.digitaloceanspaces.com/gameLayout/lifeTrue.svg',
    },
    lifeFalse: {
      ...layoutIconsInit,
      url: 'https://g4mobility-stage.fra1.cdn.digitaloceanspaces.com/gameLayout/lifeFalse.svg',
    },
  },
  textColor: '#000000',
  countdownColor: {
    info: '#00ff00',
    warning: '#ffff00',
    alert: '#ff0000',
    bg: '#666666',
  },
  progressBarColor: {
    colorProgress: '#0000ff',
    colorBg: '#666666',
  },
  stepBarColor: {
    colorProgress: '#ff0000',
    colorBg: '#666666',
  },
};

export const gameInfoBtnList: IBtnList[] = [
  {
    icon: 'insights',
    label: 'GAME.PERFORMANCE',
    title: 'GAME.PERFORMANCE',
    color: '#fff',
    action: 'goToPerformance',
  },
  {
    icon: 'gavel',
    label: 'GAME.GAMERULES',
    title: 'GAME.GAMERULES',
    color: '#fff',
    action: 'goToGameRules',
  },
  {
    icon: 'question_answer',
    label: 'GAME.FAQ',
    title: 'GAME.FAQ',
    color: '#fff',
    action: 'goToFaq',
  },
];
