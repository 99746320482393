import React, { useEffect, useMemo, useState } from 'react';
import { Radar } from 'react-chartjs-2';
import {
  Chart,
  RadialLinearScale,
  PointElement,
  LineElement,
  Title,
  Legend,
  Tooltip,
  Filler,
} from 'chart.js';
import { RadarDataSet, RadarDataType, SkillsRadarResult } from '../models/type';
import { TFunction } from 'i18next';
import { Col, Row } from 'react-bootstrap';
import Checkbox from '../../../../components/shared/design/form/checkbox/Checkbox';
import isEmpty from 'lodash.isempty';

export type RadarChartProps = {
  t: TFunction;
  skillRadarResult?: SkillsRadarResult;
  skills: string[] | string;
  wCoefficient?: number;
  isLabeled: boolean;
};
function RadarChart(props: RadarChartProps) {
  Chart.register(
    RadialLinearScale,
    PointElement,
    LineElement,
    Title,
    Legend,
    Tooltip,
    Filler
  );
  const { t, skillRadarResult, wCoefficient, isLabeled, skills } = props;
  const [scores, setScores] = useState<{
    first: number[] | undefined;
    second: number[] | undefined;
    third: number[] | undefined;
  }>();
  const [isNormalized, setIsNormalized] = useState<boolean>(false);
  const [showValuation, setShoeValuation] = useState<boolean>(false);
  const [data, setData] = useState<RadarDataType>({ labels: [], datasets: [] });

  const options = useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        legend: {
          position: 'top' as const,
          display: isLabeled,
        },
        title: {
          display: true,
          text: skillRadarResult?.title,
        },
        tooltip: {
          enabled: true,
        },
      },
      scales: {
        r: {
          ticks: {
            backdropColor: 'transparent',
          },
        },
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );
  const fixedSkillArray = (skillData: string[] | string) => {
    if (typeof skillData === 'string') {
      return JSON.parse(skillData);
    } else {
      return skillData;
    }
  };

  const dataSet = useMemo(() => {
    const ds: RadarDataSet[] = [];
    if (!isNormalized) {
      ds.push({
        label: '',
        data: [0, 10],
        fill: false,
        backgroundColor: 'transparent',
        borderColor: 'transparent',
      });
    }
    if (!isEmpty(skillRadarResult?.skillsScoreFirstAttempt)) {
      ds.push({
        label: isLabeled ? t('RADAR.SKILLSCOREFIRSTATTEMPT') : '',
        data: scores?.first,
        fill: true,
        backgroundColor: hexToRgba(skillRadarResult?.rgbFirstAttempt),
        borderColor: skillRadarResult?.rgbFirstAttempt,
      });
    }
    if (!isEmpty(skillRadarResult?.skillsScoreSecondAttempt)) {
      ds.push({
        label: isLabeled ? t('RADAR.SKILLSCORESECONDATTEMPT') : '',
        data: scores?.second,
        fill: true,
        backgroundColor: hexToRgba(skillRadarResult?.rgbSecondAttempt),
        borderColor: skillRadarResult?.rgbSecondAttempt,
      });
    }
    if (!isEmpty(skillRadarResult?.skillsScoreValuation) && showValuation) {
      ds.push({
        label: isLabeled ? t('RADAR.SKILLSCOREVALUATION') : '',
        data: scores?.third,
        fill: true,
        backgroundColor: hexToRgba('#ffcd00'),
        borderColor: '#ffcd00',
      });
    }
    return ds;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showValuation, scores, isNormalized]);

  useEffect(() => {
    keepScores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData({
      labels: !isEmpty(skills) ? fixedSkillArray(skills) : [],
      datasets: dataSet,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSet, skills]);

  useEffect(() => {
    if (isNormalized) {
      normalizeScores();
    } else {
      keepScores();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNormalized]);

  function normalizeScores() {
    const maxScoreFirst =
      scores?.first?.reduce((prev, curr) => (prev > curr ? prev : curr), 0) ||
      0;
    const maxScoreSecond =
      scores?.second?.reduce((prev, curr) => (prev > curr ? prev : curr), 0) ||
      0;
    const maxScoreThird = showValuation
      ? scores?.third?.reduce((prev, curr) => (prev > curr ? prev : curr), 0) ||
        0
      : 0;
    const max = Math.max(maxScoreFirst, maxScoreSecond, maxScoreThird);
    max !== 0 &&
      setScores({
        first: scores?.first?.map(item => item / max),
        second: scores?.second?.map(item => item / max),
        third: scores?.third?.map(item => item / max),
      });
  }

  function keepScores() {
    setScores({
      first: skillRadarResult?.skillsScoreFirstAttempt.map(skill =>
        skill.score ? skill.score : 0
      ),
      second: skillRadarResult?.skillsScoreSecondAttempt?.map(skill =>
        skill.score ? skill.score : 0
      ),
      third: skillRadarResult?.skillsScoreValuation?.map(skill =>
        skill.score ? skill.score : 0
      ),
    });
  }

  function checkDataSetIsEmpty(data: RadarDataType) {
    if (data.datasets.length === 1 && !data.datasets[0].fill) {
      return true;
    } else {
      return false;
    }
  }

  function hexToRgba(hex?: string) {
    if (hex) {
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

      return result
        ? `rgba(${parseInt(result[1], 16)}, ${parseInt(
            result[2],
            16
          )},${parseInt(result[3], 16)}, 0.2)`
        : `rgba(rgba(255, 99, 132, 0.2))`;
    } else {
    }
  }

  return (
    <div>
      <Row className={'mt-3'}>
        <Col>
          <Radar
            data={data}
            options={options}
            style={{
              width: window.innerWidth * (wCoefficient ? wCoefficient : 0.5),
            }}
          />
        </Col>
        <Col className={'mt-4'}>
          {!checkDataSetIsEmpty(data) && (
            <Checkbox
              checked={isNormalized}
              label={t('RADAR.NORMALIZE')}
              onChange={() => setIsNormalized(!isNormalized)}
            />
          )}
          {isLabeled && (
            <Checkbox
              checked={showValuation}
              label={t('RADAR.SHOWVALUATION')}
              onChange={() => setShoeValuation(!showValuation)}
            />
          )}
        </Col>
      </Row>
    </div>
  );
}

export default RadarChart;
