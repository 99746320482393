import { ICategory } from '../models/Categories';

export function setValue(
  value: any,
  key: string,
  categories: ICategory[] | undefined
): string | number {
  const newValue =
    typeof value === 'object' && value !== null
      ? ''
      : typeof value === 'number'
      ? formatValue(value, key, categories)
      : `${value}`;
  return newValue;
}

export function formatValue(
  value: string | number,
  key: string,
  categories: ICategory[] | undefined
) {
  if (key.includes('Time')) {
    //formatto in ore minuti secondi
    return `${new Date(value).toISOString().slice(11, 19)}`;
  } else if (key.match('categoryId')) {
    return categories?.find(cat => cat.id === value)?.name || value;
  } else if (typeof value === 'number') {
    return `${Math.floor(value as number)}`;
  } else {
    return value;
  }
}

const gameType: { [key: string]: string } = {
  surveyGame: 'survey_game',
  container: 'container',
  questionGame: 'question_game',
};

const fieldsList: { [key: string]: string[] } = {
  [gameType.surveyGame]: [
    'minResTimeServer',
    'maxResTimeServer',
    'resTimeServerAvg',
    'minResTimeClient',
    'maxResTimeClient',
    'resTimeClientAvg',
  ],
  [gameType.container]: ['resTimeServerAvg', 'resTimeClientAvg'],
  [gameType.questionGame]: [
    'minResTimeServer',
    'maxResTimeServer',
    'resTimeServerAvg',
  ],
};

export function setOmitFields(type: string): string[] {
  return fieldsList[type];
}
