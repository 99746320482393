import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../../app/store';
import { IGameList } from './model/gameList';
import {
  getListGameByArea,
  getMicroCategoryByFatherId,
} from './services/listgame.service';
import { IMicroGameBackground } from './model/layout';
import { listTabNameMicroGame } from './MicroGameData';
import { IBackgroundGame } from '../gamePlatform/models/backgroundGame';
import { copyObject } from '../../../../utils/objUtils';
import {
  deleteBgForMicroGame,
  editBgForMicroGame,
  getBackgroundMicroGame,
  saveBgForMicroGame,
} from './services/microgameLayout.service';
import { addAvailableBackgroundGameList } from '../gamePlatform/gamePlatformSlice';
import {
  addErrorMessage,
  addSuccessMessage,
} from '../../../../app/errors/errorSlice';
import i18n from '../../../../config/i18n';

export interface MicroGameSlice {
  gameListId: string;
  gameListInfo: IGameList | undefined;
  microGameBg: IMicroGameBackground | undefined;
  microGameSelectedBg: IMicroGameBackground | undefined;
  tabUploadKeyMicroGame: string;
}
const initialState: MicroGameSlice = {
  gameListId: '',
  gameListInfo: undefined,
  microGameBg: undefined,
  microGameSelectedBg: undefined,
  tabUploadKeyMicroGame: listTabNameMicroGame.background,
};

export const microGameSlice = createSlice({
  name: 'microGame',
  initialState,
  reducers: {
    setGameListId: (state, action: PayloadAction<string>) => {
      state.gameListId = action.payload;
    },
    setgameListInfo: (state, action: PayloadAction<IGameList>) => {
      state.gameListInfo = action.payload;
    },
    setMicroGameBg: (state, action: PayloadAction<IMicroGameBackground>) => {
      state.microGameBg = action.payload;
    },
    setMicroGameSelectedBg: (
      state,
      action: PayloadAction<IMicroGameBackground>
    ) => {
      state.microGameSelectedBg = action.payload;
    },
    setTabUploadKeyMicroGame: (state, action: PayloadAction<string>) => {
      state.tabUploadKeyMicroGame = action.payload;
    },
    resetMicroGameSelectedBg: state => {
      state.microGameSelectedBg = undefined;
    },
    resetMicroGameBg: state => {
      state.microGameBg = undefined;
    },
    resetGameListId: state => {
      state.gameListId = '';
    },
    resetGameListInfo: state => {
      state.gameListInfo = undefined;
    },
  },
});
export const {
  setGameListId,
  setgameListInfo,
  setMicroGameBg,
  setMicroGameSelectedBg,
  setTabUploadKeyMicroGame,
  resetMicroGameSelectedBg,
  resetMicroGameBg,
  resetGameListId,
  resetGameListInfo,
} = microGameSlice.actions;

export const setListGame =
  (idGame: string, microgameId: string): AppThunk =>
  dispatch => {
    dispatch(setGameListId(idGame));
    getMicroCategoryByFatherId(idGame).then(res => {
      const areaGame = res.find(
        (ele: any) => ele.areaCategoryId === +microgameId
      )?.areaId;
      areaGame &&
        getListGameByArea(areaGame).then(
          result => {
            dispatch(setgameListInfo(result));
          },
          err =>
            dispatch(
              addErrorMessage({
                title: i18n.t('ERRORSMSG.LOADINGMICROGAMELIST'),
              })
            )
        );
    });
  };

export const getBgMicroGame =
  (idGame: string): AppThunk =>
  dispatch => {
    getBackgroundMicroGame(idGame).then(res =>
      dispatch(setMicroGameBg(res[0]))
    );
  };

export const saveMicroGameBg =
  (areaId: string, selectedBg: IMicroGameBackground): AppThunk =>
  (dispatch, getState) => {
    const bgMicroGame = getState().microGame.microGameBg;
    const copyBg = copyObject(selectedBg);
    if (selectedBg.id_temp && !bgMicroGame) {
      delete copyBg.id_temp;
      saveBgForMicroGame(selectedBg, areaId).then(
        res => {
          copyBg._id = res._id;
          dispatch(setMicroGameBg(copyBg));
          dispatch(resetMicroGameSelectedBg());
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.SAVEBG'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.SAVEBG'),
            })
          )
      );
    }
    if (selectedBg.id_temp && bgMicroGame) {
      const editBg = { ...selectedBg, _id: bgMicroGame._id };
      editBgForMicroGame(editBg, areaId).then(
        res => {
          dispatch(setMicroGameBg(editBg));
          dispatch(resetMicroGameSelectedBg());
          dispatch(addAvailableBackgroundGameList(editBg));
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.EDITBG'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.EDITBG'),
            })
          )
      );
    }
  };

export const deleteMicroGameBg =
  (areaId: string): AppThunk =>
  (dispatch, getState) => {
    const bgMicroGame = getState().microGame.microGameBg;
    bgMicroGame &&
      deleteBgForMicroGame(bgMicroGame, areaId).then(
        res => {
          dispatch(resetMicroGameBg());
          dispatch(resetMicroGameSelectedBg());
          dispatch(
            addSuccessMessage({
              title: i18n.t('SUCCESSMSG.DELETEBG'),
            })
          );
        },
        err =>
          dispatch(
            addErrorMessage({
              title: i18n.t('ERRORSMSG.DELETEBG'),
            })
          )
      );
  };

export const addBgInMicroGame =
  (background: IBackgroundGame): AppThunk =>
  dispatch => {
    const backgroundConId = {
      ...background,
      id_temp: (Math.random() * 10).toString(),
    };
    dispatch(setMicroGameSelectedBg(backgroundConId));
  };

export const resetMicroGame = (): AppThunk => dispatch => {
  dispatch(resetMicroGameBg());
  dispatch(resetMicroGameSelectedBg());
  dispatch(resetGameListInfo());
  dispatch(resetGameListId());
};
export const selectGameListId = (state: RootState) =>
  state.microGame.gameListId;
export const selectGameListInfo = (state: RootState) =>
  state.microGame.gameListInfo;
export const selectMicroGameBg = (state: RootState) =>
  state.microGame.microGameBg;
export const selectMicroGameSelectedBg = (state: RootState) =>
  state.microGame.microGameSelectedBg;
export const selectTabUploadKeyMicroGame = (state: RootState) =>
  state.microGame.tabUploadKeyMicroGame;

export const selectBackgroundNew = createSelector(
  selectMicroGameSelectedBg,
  selectMicroGameBg,
  (selectedBg, savedBg) => {
    let newBg: IBackgroundGame;
    if (selectedBg && selectedBg.id_temp) {
      newBg = copyObject(selectedBg);
      return newBg;
    }
    return savedBg;
  }
);

export default microGameSlice.reducer;
