import React, { ReactNode, useState } from 'react';
import Accordion from '../../../../../components/shared/accordion/Accordion';
import RowTemplateDelete from './RowTemplateDelete';

const MessageTemplateGame = ({
  children,
  title,
  deleteElementArray,
  onClick,
}: {
  children: ReactNode;
  title: string;
  deleteElementArray?: () => void;
  onClick?: () => void;
}) => {
  const [showForm, setShowForm] = useState(false);
  const deleteElement = () => {
    setShowForm(false);
    deleteElementArray && deleteElementArray();
  };
  return (
    <div className="message-template-accordion">
      <Accordion
        title={title}
        className="mb-1"
        clickHandler={() => {
          setShowForm(!showForm);
          onClick && onClick();
        }}
      >
        <RowTemplateDelete deleteElement={deleteElement}>
          {children}
        </RowTemplateDelete>
      </Accordion>
    </div>
  );
};

export default MessageTemplateGame;
