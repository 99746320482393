import React from 'react';

interface IToolbarLeft {
  isOpen: boolean;
  topTemplate?: React.ReactNode;
  centerTemplate?: React.ReactNode;
  bottomTemplate?: React.ReactNode;
}

const ToolbarLeft = (props: IToolbarLeft) => {
  const { isOpen, topTemplate, centerTemplate, bottomTemplate } = props;

  return (
    <div className={`toolbar-left ${isOpen && 'open'}`}>
      <div className="top-content">{topTemplate && topTemplate}</div>
      <div className="center-content">{centerTemplate && centerTemplate}</div>
      <div className="bottom-content">{bottomTemplate && bottomTemplate}</div>
    </div>
  );
};

export default ToolbarLeft;
