import React from 'react';

interface IToolbarRight {
  isOpen: boolean;
  topTemplate?: React.ReactNode;
  centerTemplate?: React.ReactNode;
  bottomTemplate?: React.ReactNode;
}

const ToolbarRight = (props: IToolbarRight) => {
  const { isOpen, topTemplate, centerTemplate, bottomTemplate } = props;

  return (
    <div className={`toolbar-right ${isOpen && 'open'}`}>
      <div className="top-content">{topTemplate && topTemplate}</div>
      <div className="center-content">{centerTemplate && centerTemplate}</div>
      <div className="bottom-content">{bottomTemplate && bottomTemplate}</div>
    </div>
  );
};

export default ToolbarRight;
