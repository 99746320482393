import { TFunction } from 'i18next';
import { useEffect, useState } from 'react';
import { getAllSkills } from '../../../features/auth/game/gamePlatform/services/gameConfiguration.service';
import { ICategory } from '../../../features/auth/models/Categories';
import { createCategoriesOption } from '../../../utils/categoryUtils';
import { FormElementModelNew } from '../../../utils/forms/models/FormElementModel';
import { createStructureForm } from '../../../utils/forms/useStructureForm';
import { createSameOptionsWithoutEmpty } from '../../../utils/select';

export type GameHeaderOptionType = {
  value: string;
  label: string;
};

function useGameHeaderForm(
  t: TFunction,
  dataForm: any,
  dataFormatted: any,
  categories: ICategory[]
) {
  const [skillsOptions, setSkillsOptions] = useState<string[]>([]);
  const [categoryId, setCategoryId] = useState<number>();
  const categoriesOptions = [
    {
      value: '-1',
      label: t('APP.FORM.CHOOSE'),
    },
    ...createCategoriesOption(categories),
  ];

  useEffect(() => {
    if (dataFormatted.gameHeader) {
      setCategoryId(dataFormatted.gameHeader[0].gameFatherId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataForm]);

  useEffect(() => {
    categoryId && loadSkills();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  function loadSkills() {
    setSkillsOptions([]);
    getAllSkills('it', undefined, categoryId).then(res =>
      setSkillsOptions(res)
    );
  }

  const gameHeaderDataStructure: FormElementModelNew[] = createStructureForm(
    [
      {
        name: 'gameHeader',
        label: 'Game Header',
        dataElement: {
          className: 'hidden',
          type: 'text',
        },

        form: [
          {
            typeElement: 'select',
            label: t('GAMEMANAGEMENT.CATEGORYID'),
            name: 'gameFatherId',
            col: { xs: 12 },
            dataElement: {
              type: 'text',
            },
            options: categoriesOptions,
          },
          {
            typeElement: 'select',
            label: t('GAMEMANAGEMENT.SKILLNAME'),
            name: 'Skills',
            multiply: true,
            dataElement: {
              type: 'text',
            },
            options: createSameOptionsWithoutEmpty(skillsOptions),

            col: 12,
          },
        ],
      },
    ],
    dataForm
  );
  return { gameHeaderDataStructure };
}

export default useGameHeaderForm;
