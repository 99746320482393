function readFileAsync(file: File) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
}

export async function processFile(file: File) {
  try {
    let contentBuffer = await readFileAsync(file);
    return contentBuffer;
  } catch (err) {
    console.log(err);
  }
}

export const getImageSize = (file: string) => {
  return new Promise(resolve => {
    let image = new Image();
    image.onload = () => {
      resolve({ width: image.width, height: image.height });
    };
    image.src = file;
  });
};
