import { TFunction } from 'i18next';
import React, { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import BoxLabel from '../../../../components/shared/design/form/box/BoxLabel';
import FormElements from '../../../../utils/forms/FormElements';
import { ValueName } from '../../../../utils/forms/models/DataForm';
import {
  deleteElementByArrayModel,
  FormElementModelNew,
  OptionSelect,
} from '../../../../utils/forms/models/FormElementModel';
import { createStructureForm } from '../../../../utils/forms/useStructureForm';
import { copyObject } from '../../../../utils/objUtils';
import { createSameOptions } from '../../../../utils/select';
import RowTemplateDelete from '../../game/gamePlatform/components/RowTemplateDelete';
import { TriggerJsonType } from '../models/type';

function useTriggerForm(
  t: TFunction,
  dataForm: any,
  triggers: string[],
  events: string[],
  dataFormatted: any,
  errors: { [key: string]: any },
  changeValue: ({ value, name }: ValueName) => void,
  isSubmit: boolean,
  deleteElementByArray: deleteElementByArrayModel
) {
  const createDisabledValue = (
    triggerValue: string,
    xs: number,
    dataForm: any,
    form: any,
    typeElement: string,
    options?: OptionSelect[]
  ) => {
    let copyForm = copyObject(form);
    const triggerNameSplit = copyForm.name.split('___');
    triggerNameSplit.pop();
    const triggerName = triggerNameSplit.join('___') + '___name';
    const repeatName = triggerNameSplit.join('___') + '___repeat';

    let isDisabled: boolean;
    if ((copyForm.name as string).includes('___timeToRepeat')) {
      isDisabled =
        dataForm[triggerName] !== triggerValue && dataForm[repeatName] === false
          ? true
          : false;
    } else {
      isDisabled = dataForm[triggerName] === triggerValue ? false : true;
    }
    dataForm[copyForm.name] = isDisabled ? undefined : dataForm[form.name];

    copyForm = {
      ...copyForm,
      typeElement,
      dataElement: {
        type: 'text',
        disabled: isDisabled,
      },
      col: { xs },
      options,
    };

    const structureForm = createStructureForm([copyForm]);
    return (
      <FormElements
        data={structureForm}
        dataFormatted={dataFormatted}
        errors={errors}
        isSubmit={isSubmit}
        dataForm={dataForm}
        changeValue={changeValue}
        deleteElementByArray={deleteElementByArray}
      />
    );
  };

  const trigger = {
    name: 'trigger',
    label: t('ANIMATION.ACTION.TRIGGER'),
    templateRow: (
      children: ReactNode,
      deleteElement?: () => void,
      values: any = {}
    ) => (
      <BoxLabel className="nested-accordion">
        <RowTemplateDelete
          deleteElement={() => deleteElement && deleteElement()}
        >
          {children}
        </RowTemplateDelete>
      </BoxLabel>
    ),
    form: [
      {
        typeElement: 'select',
        label: t('ANIMATION.ACTION.TRIGGERNAME'),
        name: 'name',
        dataElement: {
          type: 'text',
        },
        options: createSameOptions(triggers),
        col: { xs: 6 },
      },
      {
        typeElement: 'switch',
        label: t('ANIMATION.ACTION.REPEAT'),
        name: 'repeat',
        dataElement: {
          type: 'checkbox',
          noRequired: false,
        },
        col: { xs: 3 },
      },
      {
        typeElement: 'input',
        label: t('ANIMATION.ACTION.TIMETOREPEAT'),
        name: 'timeToRepeat',
        dataElement: {
          type: 'number',
        },
        col: { xs: 3 },
        template: (
          children: ReactNode,
          form: FormElementModelNew,
          dataForm: any,
          dataFormatted: any
        ) => {
          return createDisabledValue(
            'afterMilliseconds',
            12,
            dataForm,
            form,
            'input'
          );
        },
      },
      {
        typeElement: 'select',
        label: t('ANIMATION.ACTION.EVENT'),
        name: 'eventName',
        col: { xs: 6 },
        template: (
          children: ReactNode,
          form: FormElementModelNew,
          dataForm: any,
          dataFormatted: any
        ) => {
          return createDisabledValue(
            'onEvent',
            12,
            dataForm,
            form,
            'select',
            createSameOptions(events)
          );
        },
      },
      {
        typeElement: 'input',
        label: t('ANIMATION.ACTION.EVENTLISTENNAME'),
        name: 'eventListenName',
        col: { xs: 6 },
        template: (
          children: ReactNode,
          form: FormElementModelNew,
          dataForm: any,
          dataFormatted: any
        ) => {
          return createDisabledValue('onEvent', 12, dataForm, form, 'input');
        },
      },
      {
        typeElement: 'input',
        label: '',
        name: 'copy',
        dataElement: {
          type: 'primary',
        },
        col: { xs: 12 },
        template: (children: ReactNode, formElement: any, data: any) => {
          function onCopyToClipBoard() {
            const triggerJson: TriggerJsonType = {
              action: 'trigger',
              payload: {
                when: data[formElement.name.replace('copy', 'name')],
                event: data[formElement.name.replace('copy', 'eventName')],
                messageName:
                  data[formElement.name.replace('copy', 'eventListenName')],
                repeat: data[formElement.name.replace('copy', 'repeat')],
                milliseconds:
                  data[formElement.name.replace('copy', 'timeToRepeat')],
              },
            };
            const json = JSON.stringify(triggerJson, null, 2);
            navigator.clipboard.writeText(`const action = ${json}`);
          }

          return (
            <div className="d-flex flex-column">
              <Button onClick={onCopyToClipBoard}>Copy To ClipBoard</Button>
            </div>
          );
        },
      },
    ],
  };

  const triggerDataStructure: FormElementModelNew[] = createStructureForm(
    [trigger],
    dataForm
  );

  return { triggerDataStructure };
}

export default useTriggerForm;
