import React from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Report } from '../../types';
import ReportForm from '../../components/report-form/ReportForm';

type Props = {
  report: {
    value: Report;
    onSave: (data: Report) => void;
    onDelete: () => void;
  };
};

function Sidebar(props: Props) {
  const { report } = props;
  return (
    <Tabs className="shared-tabs" defaultActiveKey="mb">
      <Tab eventKey="mb" title="Report">
        <ReportForm
          actualReport={report.value}
          onDelete={report.onDelete}
          onSave={report.onSave}
        />
      </Tab>
    </Tabs>
  );
}

export default Sidebar;
