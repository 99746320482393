import isEqual from 'lodash.isequal';
import http from '../../../utils/http';
import { GameReportType } from './types';

const BASE_PATH = '/usergamestatus/gameattempt/report';
export const retriveAll = async (
  catId: number,
  depth?: number,
  username?: string
) => {
  const queryStr = setQueryString(depth, username);
  return await http.get<GameReportType>(`${BASE_PATH}/${catId}${queryStr}`);
};

const setQueryString = (depth?: number, username?: string) => {
  const depthStr = typeof depth === 'number' ? `?depth=${depth}` : '';
  const usernameStr = username
    ? !isEqual(depthStr, '')
      ? `&username=${username}`
      : `?username=${username}`
    : '';

  return `${depthStr}${usernameStr}`;
};

export const getGameHeaderSkills = async (
  categoryId: number,
  online: boolean,
  depth?: number,
  userName?: string
) => {
  const queryStr1 = setQueryString(depth, userName);
  const queryStr2 = queryStr1
    ? `${queryStr1}&online=${online}`
    : `?online=${online}`;
  return await http.get<GameReportType>(
    `${BASE_PATH}/${categoryId}${queryStr2}`
  );
};
