import React from 'react';
import { Modal, ModalProps } from 'react-bootstrap';

export interface IModalCustom {
  headerTemplate: JSX.Element;
  bodyTemplate: JSX.Element;
  footerTemplate?: JSX.Element;
  show: boolean;
  size: ModalProps['size'];
  onHide: (isModalVisible: boolean) => void;
}

const ModalCustom = (props: IModalCustom) => {
  const { headerTemplate, bodyTemplate, footerTemplate, show, onHide, size } =
    props;
  return (
    <Modal className="shared-modal" size={size} show={show} onHide={onHide}>
      <Modal.Header closeButton>{headerTemplate}</Modal.Header>
      <Modal.Body>{bodyTemplate}</Modal.Body>
      <Modal.Footer>{footerTemplate}</Modal.Footer>
    </Modal>
  );
};

export default ModalCustom;
