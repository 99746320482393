import http from '../../../utils/http';
import { IFrameMetabase, Report } from './types';

const BASE_URL = `/metabase`;

export const createReport = (
  data: Report,
  idCategory: string
): Promise<{ _id: string }> =>
  http.post(`${BASE_URL}/${idCategory}/report`, data);

export const updateReport = (
  data: Report,
  id: string,
  idCategory: string
): Promise<Report> => http.put(`${BASE_URL}/${idCategory}/report/${id}`, data);

export const deleteReport = (id: string, idCategory: string): Promise<void> =>
  http.delete(`${BASE_URL}/${idCategory}/report/${id}`);

export const getReport = (id: string, idCategory: string): Promise<Report> =>
  http.get(`${BASE_URL}/${idCategory}/report/${id}`);

export const getMetabaseReport = (
  idCategory: string
): Promise<IFrameMetabase> => http.get(`${BASE_URL}/${idCategory}/report`);
