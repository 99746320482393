import { required } from '../../../../../utils/forms/utils/validationType';
import { Report } from '../../types';

export const validations = (dataForm: Report | undefined) => {
  return [
    {
      name: 'secretKey',
      validations: [
        {
          name: required,
          params: { name: 'secretKey' },
        },
      ],
    },
    {
      name: 'siteUrl',
      validations: [
        {
          name: required,
          params: { name: 'siteUrl' },
        },
      ],
    },
    {
      payload: [
        {
          resource: [
            {
              name: 'dashboard',
              validations: [
                {
                  name: required,
                  params: { name: 'dashboard' },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'expired',
      validations: [
        {
          name: required,
          params: { name: 'expired' },
        },
      ],
    },
    {
      dimension: [
        {
          name: 'width',
          validations:
            dataForm?.dimension?.height || dataForm?.dimension?.frameborder
              ? [
                  {
                    name: required,
                    params: { name: 'width' },
                  },
                ]
              : [],
        },
        {
          name: 'height',
          validations:
            dataForm?.dimension?.width || dataForm?.dimension?.frameborder
              ? [
                  {
                    name: required,
                    params: { name: 'height' },
                  },
                ]
              : [],
        },
        {
          name: 'frameborder',
          validations:
            dataForm?.dimension?.height || dataForm?.dimension?.width
              ? [
                  {
                    name: required,
                    params: { name: 'frameborder' },
                  },
                ]
              : [],
        },
      ],
    },
  ];
};
