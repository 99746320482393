import isEmpty from 'lodash.isempty';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ToolbarButton from '../../../components/shared/custom/toolbar/toolbarButton/ToolbarButton';
import IEFrame from '../../../components/shared/ie-frame/IEFrame';
import { useRoute } from '../../../utils/routes/useRoute';
import EditModeBtn from '../common/EditModeBtn';
import ContentPageLayout from '../ContentPageLayout';
import Sidebar from './containers/sidebar/Sidebar';
import {
  createReport,
  getReport,
  getMetabaseReport,
  updateReport,
  deleteReport,
} from './metabase.service';
import { IFrameMetabase, Report } from './types';

type State = {
  isEditMode: boolean;
  report?: Report;
  iFrame?: IFrameMetabase;
};

function ReportLayout() {
  const { categoryId } = useParams<{ categoryId: string }>();
  const { goBack } = useRoute();
  const [state, setState] = useState<State>({
    isEditMode: false,
  });

  useEffect(() => {
    reloadMetabase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handlerSidebar() {
    setState(prev => ({ ...prev, isEditMode: !prev.isEditMode }));
  }

  function onSave(data: Report) {
    data.payload.params = {};
    checkDimension(data);
    if (state.iFrame?.reportId) {
      updateReport(data, state.iFrame.reportId, categoryId).then(
        reloadMetabase
      );
    } else {
      createReport(data, categoryId).then(reloadMetabase);
    }
  }

  function checkDimension(report: Report) {
    if (
      isEmpty(report.dimension) ||
      (report.dimension &&
        isEmpty(report.dimension.frameborder) &&
        isEmpty(report.dimension.width) &&
        isEmpty(report.dimension.height))
    ) {
      delete report.dimension;
    }
    return report;
  }

  function reloadMetabase() {
    getMetabaseReport(categoryId).then(
      iframeRes => {
        getReport(iframeRes.reportId, categoryId).then(res => {
          setState(prev => ({
            ...prev,
            isEditMode: false,
            report: res,
            iFrame: iframeRes,
          }));
        });
      },
      err => {
        setState(prev => ({
          ...prev,
          isEditMode: false,
          iFrame: undefined,
          report: undefined,
        }));
      }
    );
  }

  function onDelete() {
    if (state.iFrame?.reportId) {
      deleteReport(state.iFrame?.reportId, categoryId).then(reloadMetabase);
    }
  }

  function bottomToolbarRightTemplate() {
    return (
      <div>
        <ToolbarButton clickAction={goBack} icon="exit_to_app" />
      </div>
    );
  }

  return (
    <ContentPageLayout
      toolbarRightTemplates={{
        top: <EditModeBtn handlerAction={handlerSidebar} />,
        bottom: bottomToolbarRightTemplate(),
      }}
      contentLayout={
        <>
          {state.iFrame?.reportId && (
            <IEFrame
              title={`${state.iFrame.reportId}`}
              src={state.iFrame.url}
              height={
                state.iFrame?.dimension?.height
                  ? Number(state.iFrame.dimension.height)
                  : undefined
              }
              width={
                state.iFrame?.dimension?.width
                  ? Number(state.iFrame.dimension.width)
                  : undefined
              }
              frameBorder={
                state.iFrame.dimension?.frameborder
                  ? Number(state.iFrame.dimension.frameborder)
                  : undefined
              }
            />
          )}
        </>
      }
      sidebarContentTemplate={
        <Sidebar
          report={{
            onDelete,
            onSave,
            value: Object.assign({}, state.report),
          }}
        />
      }
      isEditMode={state.isEditMode}
    />
  );
}

export default ReportLayout;
