import { SingleRoute } from '../utils/routes/models/routes';
import * as routeName from './routeNameAuthType';

const routeComments: SingleRoute[] = [
  {
    path: '/categories/:id',
    component: 'features/auth/comments/CommentsLayout',
    name: routeName.comments,
  },
];
export default routeComments;
